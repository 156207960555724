/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { Period } from 'models/Period/Period';

type PeriodLabelsConfig = {
  payRateBaseSalary: string;
  billRatePermFee: string;
};

const getLabels = (period: Period | undefined): PeriodLabelsConfig => {
  const employmentType = period?.employmentType;
  if (!employmentType) {
    return {
      payRateBaseSalary: 'Pay Rate',
      billRatePermFee: 'Bill Rate',
    };
  }
  switch (employmentType.family!.id) {
    case 1:
      return {
        payRateBaseSalary: 'Base Salary',
        billRatePermFee: 'Perm Fee',
      };
    case 2:
    case 3:
    case 4:
    case 6:
    case 7:
      return {
        payRateBaseSalary: 'Pay Rate',
        billRatePermFee: 'Bill Rate',
      };
    case 5:
      return {
        payRateBaseSalary: 'Base Salary',
        billRatePermFee: 'Bill Rate',
      };
    default:
      return {
        payRateBaseSalary: 'Pay Rate',
        billRatePermFee: 'Bill Rate',
      };
  }
};

export default getLabels;
