/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowParams,
  GridSortItem,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { Sort } from 'queries/BaseFetchingQueries';
import BaseModelQueries from 'queries/BaseModelQueries';
import { BaseWrappedListingQueries } from 'queries/BaseWrappedListingQueries';

type DataTableProps = {
  gridColumns: GridColDef[];
  defaultSort?: GridSortItem;
  queriesImpl: BaseWrappedListingQueries<any> | BaseModelQueries<any>;
  localStorageItemName?: string;
  filterForQuery: any;

  checkboxSelection?: boolean;

  formatEditEntityPageUrl: (id: number) => string;
};

const DataTable = (props: DataTableProps) => {
  const getSavedTableState = () => {
    const item =
      props.localStorageItemName &&
      localStorage.getItem(props.localStorageItemName);
    return item ? JSON.parse(item) : {};
  };
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    () => ({
      pageSize: getSavedTableState().pageSize || 25,
      page: 0,
    }),
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    () =>
      getSavedTableState().sortModel ||
      (props.defaultSort
        ? [props.defaultSort]
        : [{ field: 'id', sort: 'desc' }]),
  );
  const [columnModel, setColumnModel] = useState<GridColumnVisibilityModel>(
    () => getSavedTableState().columnModel || {},
  );

  useEffect(() => {
    props.localStorageItemName &&
      localStorage.setItem(
        props.localStorageItemName,
        JSON.stringify({
          pageSize: paginationModel.pageSize,
          sortModel: sortModel,
          columnModel: columnModel,
        }),
      );
  }, [paginationModel, sortModel, columnModel]);

  const sortForQuery = useMemo(() => {
    return sortModel.map(
      ({ field, sort }: GridSortItem) =>
        ({
          property: field,
          direction: sort?.toString(),
        }) as Sort,
    );
  }, [sortModel]);

  const {
    isFetching,
    isSuccess,
    data: items,
  } = props.queriesImpl.get(
    paginationModel.pageSize,
    paginationModel.page,
    sortForQuery,
    props.filterForQuery,
  );

  const navigate = useNavigate();
  const onRowDoubleClick: GridEventListener<'rowDoubleClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    event.defaultMuiPrevented = true;
    const selectedId = params.id as number;
    const editPageUrl = props.formatEditEntityPageUrl(selectedId);
    navigate(editPageUrl);
  };

  const apiRef = useGridApiRef();
  useEffect(() => {
    return apiRef.current.subscribeEvent('cellMouseUp', onGridCellMouseUp);
  }, [apiRef]);
  const onGridCellMouseUp: GridEventListener<'cellMouseUp'> = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    // If the middle mouse button is clicked.
    if (event.button == 1) {
      event.defaultMuiPrevented = true;
      if (props.formatEditEntityPageUrl) {
        const selectedId = params.row.id as number;
        const editPageUrl = props.formatEditEntityPageUrl(selectedId);
        window.open(editPageUrl, '_blank');
      }
    }
  };

  return useMemo(
    () => (
      <DataGrid
        apiRef={apiRef}
        columns={props.gridColumns}
        columnVisibilityModel={columnModel}
        onColumnVisibilityModelChange={setColumnModel}
        loading={isFetching}
        rows={isSuccess ? items.data || [] : []}
        rowCount={isSuccess ? items.totalCount || 0 : 0}
        rowHeight={45}
        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        checkboxSelection={props.checkboxSelection}
        keepNonExistentRowsSelected
        onRowDoubleClick={onRowDoubleClick}
        density='compact'
      />
    ),
    [
      props.gridColumns,
      columnModel,
      items,
      paginationModel,
      sortModel,
      props.checkboxSelection,
    ],
  );
};

export default DataTable;
