/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import ReportTemplate from 'models/ReportTemplate';
import BaseCustomReport, {
  CustomReportType,
  WeekEndingDay,
} from 'models/reports/BaseCustomReport';
import reportTemplateQueries from 'queries/ReportTemplateQueries';
import SelectWithLabel from 'components/input/SelectWithLabel';
import BaseReportPage, { validateParameters } from './BaseReportPage';
import CustomReportParameters, {
  CustomReportParametersVisibility,
} from './CustomReportParameters';

const reports = [
  {
    id: 'CustomWeeklyIndividual',
    group: 'Individual',
    subGroup: 'Weekly',
    parametersVisibility: {
      dates: true,
      weekEndingDay: true,
      employees: true,
      combinedReport: true,
    },
  },
  {
    id: 'CustomMonthlyIndividual',
    group: 'Individual',
    subGroup: 'Monthly',
    parametersVisibility: {
      dates: true,
      weekEndingDay: false,
      employees: true,
      combinedReport: true,
    },
  },
  {
    id: 'CustomWeeklyOffice',
    group: 'Office',
    subGroup: 'Weekly',
    parametersVisibility: {
      dates: true,
      weekEndingDay: true,
      locations: true,
      combinedReport: true,
    },
  },
  {
    id: 'CustomMonthlyOffice',
    group: 'Office',
    subGroup: 'Monthly',
    parametersVisibility: {
      dates: true,
      weekEndingDay: false,
      locations: true,
      combinedReport: true,
    },
  },
] as {
  id: CustomReportType;
  group: string;
  subGroup: string;
  parametersVisibility: CustomReportParametersVisibility;
}[];

const CustomReportPage = () => {
  const [parameters, setParameters] = useState({} as BaseCustomReport);
  const [validationErrors, setValidationErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const [reportGroup, setReportGroup] = useState<string | null>(null);
  const [reportSubGroup, setReportSubGroup] = useState<string | null>(null);

  const parametersVisibility = reports.find(
    (r) => r.group === reportGroup && r.subGroup === reportSubGroup,
  )?.parametersVisibility;

  const validationHelper = new ValidationHelper<BaseCustomReport>(
    Yup.object({
      startDate: Yup.date().when([], {
        is: () => !!parametersVisibility?.dates,
        then: (schema) => schema.required('Must be present'),
      }),
      endDate: Yup.date().when([], {
        is: () => !!parametersVisibility?.dates,
        then: (schema) => schema.required('Must be present'),
      }),
      weekEndingDay: Yup.string().when([], {
        is: () => !!parametersVisibility?.weekEndingDay,
        then: (schema) => schema.required('Must be present'),
      }),
      locationIds: Yup.array().when([], {
        is: () => !!parametersVisibility?.locations,
        then: (schema) =>
          schema.required('At least one Location must be present'),
      }),
      employeeIds: Yup.array().when([], {
        is: () => !!parametersVisibility?.employees,
        then: (schema) =>
          schema.required('At least one Employee must be present'),
      }),
    }),
  );

  return (
    <BaseReportPage
      title='Custom Report'
      parameters={parameters}
      onValidateParameters={() =>
        validateParameters(parameters, validationHelper, setValidationErrors)
      }
      disabled={!parameters.type}
      onInProcess={setDisabled}
    >
      <Grid container>
        <Grid container item spacing={2} xs={12} pb={3}>
          <Grid item xs={6} md={4} xl={3}>
            <SelectWithLabel
              label='Type'
              autoFocus={true}
              tabIndex={1}
              selectedValue={reportGroup}
              optionsFetcher={() => ({
                isSuccess: true,
                data: [...new Set(reports.map((r) => r.group))],
              })}
              optionLabelGetter={(v) => v}
              optionIdGetter={(v) => v}
              onChange={(value) => {
                if (reportGroup !== value) {
                  setReportGroup(value);
                  setReportSubGroup(null);
                  setParameters({});
                }
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <SelectWithLabel
              label='Grouping Period'
              tabIndex={1}
              selectedValue={reportSubGroup}
              optionsFetcher={() => ({
                isSuccess: true,
                data: [
                  ...new Set(
                    reports
                      .filter((r) => r.group === reportGroup)
                      .map((r) => r.subGroup),
                  ),
                ],
              })}
              optionLabelGetter={(v) => v}
              optionIdGetter={(v) => v}
              onChange={(value) => {
                if (reportSubGroup !== value) {
                  setReportSubGroup(value);
                  const report = reports.find(
                    (r) => r.group === reportGroup && r.subGroup === value,
                  );
                  setParameters({
                    ...parameters,
                    type: report?.id,
                    templateId: undefined,
                    weekEndingDay:
                      parameters.weekEndingDay || WeekEndingDay.SUNDAY,
                  });
                }
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={5} xl={3}>
            {parameters.type && (
              <SelectWithLabel
                label='Template'
                tabIndex={1}
                selectedValue={
                  {
                    id: parameters.templateId ? parameters.templateId : 0,
                  } as ReportTemplate
                }
                additionalOptions={[
                  {
                    id: 0,
                    name: 'DEFAULT',
                  } as ReportTemplate,
                ]}
                optionsFetcher={() =>
                  reportTemplateQueries.getTemplates(parameters.type || '')
                }
                onChange={(value) => {
                  setParameters({
                    ...parameters,
                    templateId: value?.id ? value.id : undefined,
                  });
                }}
                disabled={disabled}
                disableClearable={true}
              />
            )}
          </Grid>
        </Grid>
        {parametersVisibility && (
          <CustomReportParameters
            visibility={parametersVisibility}
            parameters={parameters}
            onUpdateParameters={setParameters}
            validationErrors={validationErrors}
            disabled={disabled}
            tabIndexForInputs={1}
          />
        )}
      </Grid>
    </BaseReportPage>
  );
};

export default CustomReportPage;
