/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Switch from '@mui/material/Switch';
import { useReadOnlyContext } from 'context/readonly/ReadOnlyContext';
import BaseLabeledComponent from './BaseLabeledComponent';

type SwitchWithLabelProps = {
  label: string;
  error?: string;
  checked: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

const SwitchWithLabel = (props: SwitchWithLabelProps) => {
  const readOnlyMode = useReadOnlyContext() || props.readOnly;

  return (
    <BaseLabeledComponent label={props.label} error={props.error}>
      <Switch
        size='small'
        disabled={props.disabled}
        readOnly={readOnlyMode}
        checked={props.checked}
        tabIndex={props.tabIndex}
        onChange={(event, checked) => {
          // for some reason attribute readOnly={true} does not work as expected
          !readOnlyMode && props.onChange(event, checked);
        }}
      />
    </BaseLabeledComponent>
  );
};

export default memo(
  SwitchWithLabel,
  (before, after) =>
    before.label === after.label &&
    before.error === after.error &&
    before.checked === after.checked &&
    before.disabled === after.disabled &&
    before.tabIndex === after.tabIndex &&
    before.onChange === after.onChange,
) as typeof SwitchWithLabel;
