/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import poolQueries from 'queries/PoolQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const PoolsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'poolDataTableState',
    addNewEntityPageUrl: '/pools/new',
    formatEditEntityPageUrl(id) {
      return `/pools/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Pools?`;
      }
      return `Are you sure you want to remove selected Pool with id ${id} ?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all existing'
      } Pools`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 125px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.Pools,
    importType: ImportType.Pools,
    queriesImpl: poolQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 1 },
      { field: 'notes', headerName: 'Notes', flex: 2 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return <BaseListPage {...properties} />;
};

export default PoolsPage;
