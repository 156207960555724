/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import {
  HTMLAttributes,
  ReactChild,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const LISTBOX_PADDING = 8; // px

type Option = {
  id: number | string;
  getLabel: () => string;
};

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const optionData = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const optionProps = optionData[0];
  const option: Option = optionData[1];
  const selected: boolean = optionData[2];

  return (
    <Typography
      component='li'
      {...optionProps}
      noWrap
      style={inlineStyle}
      key={option.id}
    >
      <Checkbox
        key={option.id}
        color='primary'
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option.getLabel()}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = 'OuterElement';

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: ReactChild[] = [];
  (children as ReactChild[]).forEach(
    (item: ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const itemCount = itemData.length;
  const itemSize = 36;
  const maxPopperItems = 8;

  const getHeight = () => {
    if (itemCount > maxPopperItems) {
      return maxPopperItems * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});
