/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from 'react-router-dom';
import { useAuth } from 'context/security/AuthContext';
import DashboardLayout from 'layouts/DashboardLayout';
import ChangePasswordPage from 'pages/ChangePassword/ChangePasswordPage';
import SignInPage from 'pages/SignIn/SignInPage';
import { generatePageRoutes } from './index';

function RootRoutes() {
  const { isAuthed, accessLevelId } = useAuth();

  const pageRoutes = generatePageRoutes(accessLevelId);

  const routeElements = (
    <>
      <Route
        path='/'
        element={
          isAuthed ? <DashboardLayout /> : <NavigateWithReferer to='/login' />
        }
      >
        <Route index element={<Navigate to='/dashboard' />} />
        {pageRoutes}
      </Route>
      <Route path='/login' element={<SignInPage />} />
      <Route path='/change-password' element={<ChangePasswordPage />} />
      <Route path='*' element={<Navigate to='/dashboard' />} />
    </>
  );

  const browserRouter = createBrowserRouter(
    createRoutesFromElements(routeElements),
  );

  return <RouterProvider router={browserRouter} />;
}

const NavigateWithReferer = ({ to }: any) => {
  const location = useLocation();
  return <Navigate to={to} state={{ referer: location.pathname }} />;
};

export default RootRoutes;
