/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Employee, { getEmployeeFullName } from 'models/Employee';
import Location from 'models/Location';
import Pool from 'models/Pool';
import BaseCustomReport, {
  WeekEndingDay,
} from 'models/reports/BaseCustomReport';
import locationQueries from 'queries/LocationQueries';
import poolQueries from 'queries/PoolQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import DateWithLabel from 'components/input/DateWithLabel';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import { ReportParametersProps } from '../DatesForReportParameters';

export type CustomReportParametersVisibility = {
  dates?: boolean;
  weekEndingDay?: boolean;
  locations?: boolean;
  employees?: boolean;
  combinedReport?: boolean;
};

export type CustomReportParametersProps<T> = ReportParametersProps<T> & {
  visibility: CustomReportParametersVisibility;
  autoFocus?: boolean;
  tabIndexForInputs?: number;
};

const weds = [
  {
    id: 0,
    name: 'Sunday',
    value: WeekEndingDay.SUNDAY,
  },
  {
    id: 1,
    name: 'Saturday',
    value: WeekEndingDay.SATURDAY,
  },
];

const CustomReportParameters = <T extends BaseCustomReport>(
  props: CustomReportParametersProps<T>,
) => {
  const [pool, setPool] = useState<Pool | null>(null);

  return (
    <>
      {props.visibility?.dates && (
        <Grid container item spacing={2} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item xs={6} md={4} xl={3}>
              <DateWithLabel
                label='Start Date'
                autoFocus={props.autoFocus}
                tabIndex={props.tabIndexForInputs}
                value={props.parameters.startDate || null}
                onChange={(date: any) => {
                  props.onUpdateParameters((prevState) => {
                    return {
                      ...prevState,
                      startDate: date,
                    };
                  });
                }}
                disabled={props.disabled}
                error={props.validationErrors?.startDate?.join(';')}
              />
            </Grid>
            <Grid item xs={6} md={4} xl={3}>
              <DateWithLabel
                label='End Date'
                tabIndex={props.tabIndexForInputs}
                value={props.parameters.endDate || null}
                onChange={(date: any) => {
                  props.onUpdateParameters((prevState) => {
                    return {
                      ...prevState,
                      endDate: date,
                    };
                  });
                }}
                disabled={props.disabled}
                error={props.validationErrors?.endDate?.join(';')}
              />
            </Grid>
          </LocalizationProvider>
          {props.visibility?.weekEndingDay && (
            <Grid item xs={6} md={4} xl={3}>
              <SelectWithLabel
                label='Week Ending Day'
                tabIndex={props.tabIndexForInputs}
                selectedValue={
                  weds.find((v) => v.value == props.parameters.weekEndingDay) ||
                  null
                }
                optionsFetcher={() => ({
                  isSuccess: true,
                  data: weds,
                })}
                onChange={(value) => {
                  props.onUpdateParameters((prevState) => {
                    return {
                      ...prevState,
                      weekEndingDay: value?.value,
                    };
                  });
                }}
                disabled={props.disabled}
                disableClearable={true}
                error={props.validationErrors?.weekEndingDay?.join(';')}
              />
            </Grid>
          )}
        </Grid>
      )}

      {(props.visibility?.locations || props.visibility?.employees) && (
        <Grid container item spacing={2} xs={12}>
          {props.visibility?.locations && (
            <Grid item xs={6} md={4} xl={3}>
              <AutocompleteWithLabel
                label='Locations'
                tabIndex={props.tabIndexForInputs}
                selectedValues={
                  props.parameters.locationIds?.map(
                    (v) =>
                      ({
                        id: v,
                      }) as Location,
                  ) || []
                }
                optionsFetcher={locationQueries}
                optionFilter={(l) => l.isActive}
                onChange={(_, ids) => {
                  props.onUpdateParameters((prevState) => {
                    return {
                      ...prevState,
                      locationIds: ids || [],
                    };
                  });
                }}
                disabled={props.disabled}
                error={props.validationErrors?.locationIds?.join(';')}
              />
            </Grid>
          )}
          {props.visibility?.employees && (
            <>
              <Grid item xs={6} md={4} xl={3}>
                <SelectWithLabel
                  label='Pool'
                  tabIndex={props.tabIndexForInputs}
                  selectedValue={pool}
                  optionsFetcher={poolQueries}
                  onChange={(value) => {
                    props.onUpdateParameters((prevState) => {
                      return {
                        ...prevState,
                        employeeIds: undefined,
                      };
                    });
                    setPool(value);
                  }}
                  disabled={props.disabled}
                />
              </Grid>
              <Grid item xs={6} md={4} xl={3}>
                <AutocompleteWithLabel
                  label='Employees'
                  tabIndex={props.tabIndexForInputs}
                  selectedValues={
                    props.parameters.employeeIds?.map(
                      (v) =>
                        ({
                          id: v,
                        }) as Employee,
                    ) || []
                  }
                  optionsFetcher={() => ({
                    isSuccess: true,
                    data: pool?.employees || [],
                  })}
                  optionFilter={(e) => e.isActive}
                  optionLabelGetter={getEmployeeFullName}
                  onChange={(_, ids) => {
                    props.onUpdateParameters((prevState) => {
                      return {
                        ...prevState,
                        employeeIds: ids || [],
                      };
                    });
                  }}
                  disabled={props.disabled}
                  error={props.validationErrors?.employeeIds?.join(';')}
                />
              </Grid>
            </>
          )}
          {props.visibility?.combinedReport && (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={6}>
                <SwitchWithLabel
                  label='Show all in a single file'
                  tabIndex={props.tabIndexForInputs}
                  checked={props.parameters.combinedReport || false}
                  onChange={(_, checked) => {
                    props.onUpdateParameters((prevState) => {
                      return {
                        ...prevState,
                        combinedReport: checked,
                      };
                    });
                  }}
                  disabled={props.disabled}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CustomReportParameters;
