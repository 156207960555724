/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { createContext, useContext } from 'react';
import IFilterContext from './IFilterContext';

export type IPeriodFilterContext = IFilterContext;
const PeriodFilterContext = createContext<IPeriodFilterContext>(
  {} as IPeriodFilterContext,
);
export default PeriodFilterContext;

export function usePeriodFilterContext() {
  return useContext(PeriodFilterContext);
}
