/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Pool from 'models/Pool';
import BaseModelQueries from './BaseModelQueries';

class PoolQueries extends BaseModelQueries<Pool> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new PoolQueries('/pools');
