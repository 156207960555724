/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import List from '@mui/material/List';
import PropTypes from 'prop-types';

function SidenavList({ children }) {
  return (
    <List
      sx={{
        //pl: 2,  SoftUI default
        pl: 1, //CAS specific
        //ml: 3,  SoftUI default
        ml: 2, //CAS specific
      }}
    >
      {children}
    </List>
  );
}

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidenavList;
