/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { getClientNameWithUid } from 'models/Client';
import CommissionReport from 'models/reports/CommissionReport';
import clientQueries from 'queries/ClientQueries';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import locationQueries from 'queries/LocationQueries';
import statusQueries from 'queries/StatusQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BaseReportPage from './BaseReportPage';
import DatesForReportParameters from './DatesForReportParameters';

const CommissionReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'Commission',
  } as CommissionReport);
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Commission Report'
      parameters={parameters}
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pt={3}>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Clients'
              tabIndex={1}
              optionsFetcher={clientQueries}
              optionLabelGetter={getClientNameWithUid}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  clientIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Locations'
              tabIndex={1}
              optionsFetcher={locationQueries}
              optionFilter={(l) => l.isActive}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  locationIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Employment Types'
              tabIndex={1}
              optionsFetcher={employmentTypeQueries}
              optionFilter={(t) => t.isActive}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  employmentTypeIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Statuses'
              tabIndex={1}
              optionsFetcher={statusQueries}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  statusIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <SwitchWithLabel
              label='Skip Negative GP'
              tabIndex={1}
              checked={parameters.skipNegativeGp || false}
              onChange={(_, checked) => {
                setParameters({ ...parameters, skipNegativeGp: checked });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseReportPage>
  );
};

export default CommissionReportPage;
