/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Grid2 from '@mui/material/Unstable_Grid2';
import AccessLevelReadOnlyContextProvider from 'context/readonly/AccessLevelReadOnlyContextProvider';
import { Period } from 'models/Period/Period';
import { PeriodEmployee } from 'models/Period/PeriodEmployee';
import { PeriodLocation } from 'models/Period/PeriodLocation';
import getAvailability from 'models/Period/getAvailability';
import Timesheet from 'models/Timesheet/Timesheet';
import { getErrorMessage } from 'queries/ErrorResponse';
import periodQueries from 'queries/PeriodQueries';
import timesheetQueries from 'queries/TimesheetQueries';
import BasePage, { BasicPageProps } from 'pages/BasePage';
import PeriodInfoCard from './cards/PeriodInfoCard';
import PeriodMetricsCard from './cards/PeriodMetricsCard';
import PeriodNotesCard from './cards/PeriodNotesCard';
import PeriodRatesCard from './cards/PeriodRatesCard';
import PeriodTimesheetsCard from './cards/PeriodTimesheetsCard';
import getLabels from './getLabels';
import getMetrics from './getMetrics';
import validationHelper from './validationHelper';

const PeriodPage = (props: BasicPageProps) => {
  const location = useLocation();
  const copyFrom = location.state?.copyFrom;

  function createEmptyPeriod(): Period {
    return copyFrom
      ? {
          ...copyFrom,
          id: -1,
          timesheetIds: undefined,
          accountExecutives: copyFrom?.accountExecutives.map(
            (e: PeriodEmployee, index: number) => ({
              ...e,
              id: -index - 1,
            }),
          ),
          recruiters: copyFrom?.recruiters.map(
            (e: PeriodEmployee, index: number) => ({
              ...e,
              id: -index - 1,
            }),
          ),
          locations: copyFrom?.locations.map(
            (l: PeriodLocation, index: number) => ({
              ...l,
              id: -index - 1,
            }),
          ),
        }
      : {
          startDate: null,
          endDate: null,
          billRatePermFee: 0,
          payRateBaseSalary: 0,
          otBillRate: 0,
          otPayRate: 0,
          vendorManagement: 0,
          perDiem: 0,
          spreadOver: 0,
          isBillFixed: false,
        };
  }

  const [notificationErrorMessage, setNotificationErrorMessage] = useState('');
  const createTimesheetQuery = timesheetQueries.create();
  const removeCachedQueries = timesheetQueries.getRemoveCachedQueriesFunction();

  async function handleAddSpreadOverTimesheets(period: Period) {
    const permFee = period.billRatePermFee;
    const spreadOver = period.spreadOver;
    if (!permFee || !spreadOver) {
      setNotificationErrorMessage('The spread over or perm free is null');
      return;
    }
    const timesheetPermFee = permFee / spreadOver;
    const date = new Date(period.startDate);

    try {
      for (
        let j = 0, ref = spreadOver;
        0 <= ref ? j < ref : j > ref;
        0 <= ref ? ++j : --j
      ) {
        const timesheet = {
          id: -1,
          period: period,
          otBillRate: period!.otBillRate || 0,
          otPayRate: period!.otPayRate || 0,
          vendorManagement: period!.vendorManagement,
          weekEndingDate: new Date(date),
          straightTime: 0,
          overtime: 0,
          holidayTime: 0,
          vacationTime: 0,
          sickTime: 0,
          perDiem: 0,
          permFee: timesheetPermFee,
          fixedBill: period!.fixedBill,
          isBillFixed: period!.isBillFixed,
        } as Timesheet;
        await createTimesheetQuery.mutateAsync(
          timesheetQueries.toRequest(timesheet),
        );
        date.setDate(date.getDate() + 7);
      }
    } catch (error: any) {
      setNotificationErrorMessage(
        await getErrorMessage(error, 'Timesheet creation failed'),
      );
    }
    removeCachedQueries();
  }

  const ParameterizedBasePage = BasePage<Period>;
  const PeriodPage = (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyPeriod}
      queriesImpl={periodQueries}
      validationHelper={validationHelper}
      getAvailability={getAvailability}
      getLabels={getLabels}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Period?'
      confirmRemovalDialogText='Are you sure you want to delete current Period?'
      confirmSaveDialogText='Are you sure you want to save changes to current Period?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Period?'
      formatEditEntityPageUrl={(id) => `/periods/edit/${id}`}
    >
      {(sectionParams) => {
        const metrics = getMetrics(sectionParams.editedData);
        const period = sectionParams.editedData;
        const availability = sectionParams.availability;
        const labels = sectionParams.labels;
        const errors = sectionParams.validationErrors;
        const tabIndex = sectionParams.tabIndexForInputs;
        const updateInputField = sectionParams.updateInputField;
        const updateField = sectionParams.updateField;

        return (
          <>
            <Grid2 container spacing={2}>
              <Grid2
                container
                xs={12}
                lg={9}
                columnSpacing={0}
                rowSpacing={2}
                sx={{ flexDirection: 'column' }}
              >
                <Grid2 xs={12}>
                  <PeriodInfoCard
                    period={period}
                    availability={availability}
                    tabIndex={tabIndex}
                    errors={errors}
                    updateInputField={updateInputField}
                    updateField={updateField}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <PeriodNotesCard
                    period={period}
                    tabIndex={tabIndex}
                    updateInputField={updateInputField}
                  />
                </Grid2>
              </Grid2>
              <Grid2
                container
                xs={12}
                lg={3}
                columnSpacing={0}
                rowSpacing={2}
                sx={{ flexDirection: 'column' }}
              >
                <Grid2 xs={12}>
                  <PeriodRatesCard
                    period={period}
                    tabIndex={tabIndex}
                    labels={labels}
                    availability={availability}
                    errors={errors}
                    updateInputField={updateInputField}
                    updateField={updateField}
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <PeriodMetricsCard metrics={metrics} />
                </Grid2>
              </Grid2>
              <Grid2 xs={12}>
                <PeriodTimesheetsCard
                  period={period}
                  onAddSpreadOverTimesheets={() =>
                    handleAddSpreadOverTimesheets(period)
                  }
                  tabIndex={tabIndex}
                />
              </Grid2>
            </Grid2>
            <Snackbar
              open={!!notificationErrorMessage}
              autoHideDuration={6000}
              onClose={() => setNotificationErrorMessage('')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Alert severity='error' sx={{ width: '100%' }}>
                <pre style={{ fontFamily: 'inherit' }}>
                  {notificationErrorMessage}
                </pre>
              </Alert>
            </Snackbar>
          </>
        );
      }}
    </ParameterizedBasePage>
  );

  return (
    <AccessLevelReadOnlyContextProvider>
      {PeriodPage}
    </AccessLevelReadOnlyContextProvider>
  );
};

export default PeriodPage;
