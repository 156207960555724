/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import DashboardNavbar from 'components/navigation/DashboardNavbar';
import WrappedSidenav from 'components/navigation/WrappedSidenav';
import curved0 from 'softUI/assets/images/curved-images/curved0.jpg';
import SoftBox from 'softUI/components/SoftBox';
import { setLayout, useSoftUIController } from 'softUI/context';

function DashboardLayout() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <>
      {/* moved from App.tsx */}
      <WrappedSidenav />

      <SoftBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 2,
          position: 'relative',

          [breakpoints.up('xl')]: {
            //marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),  SoftUI default
            marginLeft: miniSidenav ? pxToRem(80) : pxToRem(247), //CAS specific
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <DashboardNavbar absolute light />
        <SoftBox
          borderRadius='xl'
          sx={({ functions: { pxToRem } }) => ({
            minHeight: pxToRem(40),
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6),
              )}, url(${curved0})`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            overflow: 'hidden',
            marginBottom: pxToRem(16),
          })}
        />
        <Outlet />
      </SoftBox>
    </>
  );
}

export default DashboardLayout;
