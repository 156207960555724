/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { Period } from 'models/Period/Period';

type PeriodMetrics = {
  gmBasic: number;
  gmH: number;
  gmWK: number;
  gmYR: number;
};

const getMetrics = (period: Period): PeriodMetrics => {
  const metrics = calculateMetrics(period);
  return {
    gmBasic: round(metrics.gmBasic),
    gmH: round(metrics.gmH),
    gmWK: round(metrics.gmWK),
    gmYR: round(metrics.gmYR),
  };
};

const round = (value: number): number => {
  return Math.round(value * 1e2) / 1e2;
};

const calculateMetrics = (period: Period): PeriodMetrics => {
  if (period?.employmentType) {
    const employmentType = period.employmentType;
    const burdenPercent = employmentType.burden / 100;

    const vendorManagementPercent = period.vendorManagement / 100;
    const payRate = period.payRateBaseSalary;
    const perDiem = period.perDiem;
    const billRatePermFee = period.billRatePermFee;
    const spreadOver = period.spreadOver || 0;
    const billRate = period.isBillFixed
      ? (period.fixedBill || 0) / 40
      : billRatePermFee;

    let netGM = 0;
    let gmValue = 0;
    let gmHValue = 0;
    let salary;

    if (employmentType.family!.id == 1) {
      // 1 - DH
      return {
        gmBasic: 100,
        gmH: 0,
        gmWK: spreadOver >= 1 ? billRatePermFee / spreadOver : 0,
        gmYR: billRatePermFee,
      };
    } else {
      if (employmentType.family!.id == 5) {
        // 5 - Salary
        salary = payRate / 2080;
        gmValue =
          billRate === 0
            ? 0
            : (salary * (1 + burdenPercent) - billRate) / billRate;
        gmHValue = gmValue * billRate;
      } else {
        if (employmentType.isBurdenFixed) {
          netGM =
            billRate -
            (employmentType.fixedBurden || 0) -
            payRate * (1 + vendorManagementPercent) -
            perDiem / 40;
        } else {
          netGM =
            billRate -
            payRate * (1 + burdenPercent + vendorManagementPercent) -
            perDiem / 40;
        }
        gmValue = billRate === 0 ? 0 : netGM / billRate;
        gmHValue = netGM;
      }
      const gmWKValue = gmHValue * 40;
      const gmYRValue = gmWKValue * 52;
      return {
        gmBasic: gmValue * 100,
        gmH: gmHValue,
        gmWK: gmWKValue,
        gmYR: gmYRValue,
      };
    }
  } else {
    return {
      gmBasic: 0,
      gmH: 0,
      gmWK: 0,
      gmYR: 0,
    };
  }
};

export default getMetrics;
