/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert/Alert';
import Snackbar from '@mui/material/Snackbar';
import { saveAs } from 'file-saver';
import { getErrorMessage } from 'queries/ErrorResponse';
import importQueries, { ImportType } from 'queries/ImportQueries';
import SoftProgress from 'softUI/components/SoftProgress';
import ResponsiveButton from './ResponsiveButton';

type EntityImporterProps = {
  importType: ImportType;
  onProgressBackdropChange: (isOpen: boolean) => void;
  tabIndex?: number;
};

function buildResultFileName(importType: ImportType) {
  const entityName = importType.charAt(0).toUpperCase() + importType.slice(1);
  return `Import${entityName}Results.xlsx`;
}

const EntityImporter = (props: EntityImporterProps) => {
  const [inProcess, setInProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] =
    useState<AlertColor>('info');

  function showNotification(severity: AlertColor, message: string) {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
  }

  function setProgressToFalse() {
    setInProgress(false);
    props.onProgressBackdropChange(false);
  }

  const importEntities = async (fileList: File[]) => {
    try {
      props.onProgressBackdropChange(true);
      setInProgress(true);
      setPercentage(0);
      showNotification('info', 'Uploading...');

      const importProgress = await importQueries.import(
        props.importType,
        fileList,
      );
      pollAndThenDownloadImportResult(importProgress.id);
    } catch (error: any) {
      showNotification('error', await getErrorMessage(error, 'Upload failed'));
      setProgressToFalse();
    }
  };

  const pollAndThenDownloadImportResult = (id: string) => {
    const pollStartTime = Date.now();
    const generateReportMaxTimeout = 600000;
    setNotificationMessage('Importing...');

    const timerId = setInterval(async () => {
      if (Date.now() - pollStartTime > generateReportMaxTimeout) {
        clearInterval(timerId);
        showNotification(
          'error',
          `Timeout is reached, the import result is still not ready.`,
        );
        setProgressToFalse();
        return;
      }

      try {
        const importProgress = await importQueries.getImportProgress(
          props.importType,
          id,
        );
        if (!importProgress.completed) {
          setPercentage(importProgress.progressPercent || 0);
        } else if (importProgress.hasResults) {
          clearInterval(timerId);
          downloadImportResult(id);
        } else {
          clearInterval(timerId);
          showNotification(
            'error',
            `Import is completed with a failure / no result. Details: ${importProgress.failureDetails}`,
          );
          setProgressToFalse();
        }
      } catch (error: any) {
        clearInterval(timerId);
        showNotification(
          'error',
          await getErrorMessage(error, 'Failed to obtain the import result'),
        );
        setProgressToFalse();
      }
    }, 2000);
  };

  const downloadImportResult = async (id: string) => {
    setPercentage(100);
    setNotificationMessage('Downloading the import result ...');

    try {
      const reportData = await importQueries.getImportResult(
        props.importType,
        id,
      );
      saveAs(reportData, buildResultFileName(props.importType));
    } catch (error: any) {
      showNotification(
        'error',
        await getErrorMessage(error, 'Failed to download the import result'),
      );
    }

    setProgressToFalse();
    setNotificationMessage('');
  };

  const importInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <ResponsiveButton
        label='Import'
        color='info'
        icon='upload'
        tabIndex={props.tabIndex}
        onClick={() => importInputRef.current && importInputRef.current.click()}
      />
      <input
        ref={importInputRef}
        type='file'
        accept='.xls,.xlsx'
        hidden
        multiple
        onChange={(e) => {
          const files = e.target.files;
          importEntities(files ? Array.from(files) : []);
          e.target.value = '';
        }}
        tabIndex={-1}
      />
      <Snackbar
        open={!!notificationMessage || inProcess}
        autoHideDuration={inProcess ? null : 6000}
        onClose={() => setNotificationMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity={notificationSeverity} sx={{ width: '100%' }}>
          <pre style={{ fontFamily: 'inherit' }}>{notificationMessage}</pre>
          {inProcess && <SoftProgress value={percentage} label />}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EntityImporter;
