/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Consultant from 'models/Consultant';
import BaseModelQueries from './BaseModelQueries';

class ConsultantQueries extends BaseModelQueries<Consultant> {
  constructor(url: string) {
    super(url, [
      { property: 'firstName', direction: 'asc' },
      { property: 'lastName', direction: 'asc' },
    ]);
  }
}

export default new ConsultantQueries('/consultants');
