/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo, useEffect, useState } from 'react';
import { SxProps, Theme } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { useReadOnlyContext } from 'context/readonly/ReadOnlyContext';
import isEqual from 'lodash/isEqual';
import BaseModel from 'models/BaseModel';
import { BaseFetchingQueries } from 'queries/BaseFetchingQueries';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';
import OptionsFetcher from './OptionsFetcher';
import SelectAndPercent, { SelectAndPercentEntry } from './SelectAndPercent';

type MultiselectAndPercentWithLabelProps<TModel extends BaseModel> = {
  label: string;
  error?: string;
  values?: SelectAndPercentEntry<TModel>[];
  limitSelection?: number;
  tabIndex?: number;
  onChange: (entries: SelectAndPercentEntry<TModel>[]) => void;
  selectOptionsFetcher: OptionsFetcher<TModel> | BaseFetchingQueries<TModel>;
  selectItemNameGetter?: (item: TModel) => string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

const MultiselectAndPercentWithLabel = <TModel extends BaseModel>({
  selectItemNameGetter = (item: any) => item.name,
  ...props
}: MultiselectAndPercentWithLabelProps<TModel>) => {
  const readOnlyMode = useReadOnlyContext();
  const [data, setData] = useState([] as SelectAndPercentEntry<TModel>[]);

  useEffect(() => {
    setData(props.values || []);
  }, [props.values]);

  const handleAdd = () => {
    setData((prevState) => {
      let newId = 0;
      if (prevState.length > 0) {
        newId = prevState.reduce((a, b) => (a.id! < b.id! ? a : b)).id || 0;
      }
      if (newId >= 0) {
        newId = -1;
      } else {
        newId -= 1;
      }
      return [
        { id: newId, percent: 100 } as SelectAndPercentEntry<TModel>,
        ...prevState,
      ];
    });
  };

  const handleRemove = (entry: SelectAndPercentEntry<TModel>) => {
    setData((prevState) => {
      const result = prevState.filter((e) => e !== entry);
      props.onChange(result);
      return result;
    });
  };

  function prefixWithLabel(label: string, subject: string) {
    return `${label.toLowerCase().replaceAll(' ', '-')}-${subject}`;
  }

  return (
    <SoftBox sx={props.sx}>
      <List dense>
        <ListItem key={prefixWithLabel(props.label, 'header')}>
          <ListItemText
            primary={
              <SoftBox ml={0.5}>
                <SoftTypography
                  fontWeight='medium'
                  variant='caption'
                  verticalAlign='text-top'
                >
                  {props.label}
                </SoftTypography>
              </SoftBox>
            }
          />
          {!readOnlyMode && (
            <ListItemSecondaryAction>
              <IconButton
                size='small'
                edge='end'
                aria-label='add'
                color='inherit'
                tabIndex={props.tabIndex}
                onClick={handleAdd}
                disabled={
                  props.disabled ||
                  (props.limitSelection &&
                    data &&
                    data.length >= props.limitSelection) ||
                  false
                }
              >
                <Icon fontSize='small'>add</Icon>
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        {data?.map((entry, index) => {
          return (
            <ListItem
              key={prefixWithLabel(props.label, index.toString())}
              sx={{
                paddingRight: 3,
                paddingTop: 0.5,
              }}
            >
              <SelectAndPercent
                disabled={props.disabled}
                tabIndex={props.tabIndex}
                value={entry}
                onChange={(newEntry) => {
                  setData((prevState) => {
                    const result = prevState.map((e) =>
                      e.id === newEntry.id ? newEntry : e,
                    );
                    props.onChange(result);
                    return result;
                  });
                }}
                optionsFetcher={props.selectOptionsFetcher}
                optionLabelGetter={selectItemNameGetter}
              />
              {!readOnlyMode && (
                <ListItemSecondaryAction>
                  <IconButton
                    size='small'
                    edge='end'
                    aria-label='remove'
                    color='inherit'
                    tabIndex={props.tabIndex}
                    onClick={() => handleRemove(entry)}
                    disabled={props.disabled}
                  >
                    <Icon fontSize='small'>clear</Icon>
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
        {!data?.length && (
          <ListItemText
            primaryTypographyProps={{
              variant: 'caption',
              color: 'secondary',
              display: 'inline',
            }}
            sx={{
              height: '32px',
              textAlign: 'center',
            }}
          >
            Nothing selected
          </ListItemText>
        )}
      </List>
      <FormHelperText error>
        <span>{props.error || ''}</span>
      </FormHelperText>
    </SoftBox>
  );
};

export default memo(
  MultiselectAndPercentWithLabel,
  (before, after) =>
    before.label === after.label &&
    before.error === after.error &&
    isEqual(before.values, after.values) &&
    before.limitSelection === after.limitSelection &&
    before.tabIndex === after.tabIndex &&
    before.onChange === after.onChange &&
    before.selectOptionsFetcher === after.selectOptionsFetcher &&
    before.selectItemNameGetter === after.selectItemNameGetter &&
    before.disabled === after.disabled &&
    isEqual(before.sx, after.sx),
) as typeof MultiselectAndPercentWithLabel;
