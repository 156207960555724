/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import brandLogo from 'assets/images/CAS_logo.png';
import miniBrandLogo from 'assets/images/C_logo.png';
import appRoutes from 'routes/appRoutes';
import { setMiniSidenav, useSoftUIController } from 'softUI/context';
import Sidenav from './Sidenav';

const WrappedSidenav = () => {
  const [controller, dispatch]: any = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <Sidenav
      color={sidenavColor}
      brand={brandLogo}
      miniBrand={miniBrandLogo}
      brandName='Catapult Accounting'
      routes={appRoutes}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    />
  );
};

export default WrappedSidenav;
