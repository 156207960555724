/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import axios from 'axios';

const serverEndpoint = (window as any)._env_.SERVER_ENDPOINT;
const axiosInstance = axios.create({
  baseURL: serverEndpoint + (serverEndpoint.endsWith('/') ? '' : '/') + 'api',
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  paramsSerializer: {
    encode: (paramsElement) => {
      return encodeURIComponent(paramsElement);
    },
  },
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent('tas_http_unauthorized', {
            detail: { response: error.response },
          }),
        );
      });
    }
    throw error;
  },
);

export default axiosInstance;
