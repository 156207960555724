/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo, useEffect, useState } from 'react';
import { useReadOnlyContext } from 'context/readonly/ReadOnlyContext';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import BaseLabeledComponent from 'components/input/BaseLabeledComponent';
import SoftInput, { Icon } from 'softUI/components/SoftInput';

export type InputWithLabelProps = {
  label: string;
  size?: 'small' | 'medium';
  type?: 'text' | 'number' | 'password';
  icon?: Icon;
  value?: any;
  defaultValue?: any;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
  errorText?: string;
  errorTextIsPreformatted?: boolean;
  multiline?: boolean;
  multilineRows?: number;
  step?: number;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
};

const InputWithLabel = ({
  size = 'small',
  type = 'text',
  ...props
}: InputWithLabelProps) => {
  const [value, setValue] = useState('');
  // Propagate the changed props.value.
  useEffect(() => {
    setValue(
      props.value !== undefined && props.value !== null ? props.value : '',
    );
  }, [props.value]);

  const readOnlyMode = useReadOnlyContext() || props.readOnly;

  return (
    <BaseLabeledComponent
      label={props.label}
      error={props.errorText}
      errorIsPreformatted={props.errorTextIsPreformatted}
    >
      <SoftInput
        readOnly={readOnlyMode}
        disabled={props.disabled}
        type={type}
        size={size}
        icon={props.icon}
        variant='outlined'
        value={value}
        onChange={(e: any) => {
          const newValue = e.target.value;
          setValue(newValue);
          if (!isEmpty(newValue)) {
            if (type === 'number') {
              props.onChange!(e, e.target.valueAsNumber);
            } else {
              props.onChange!(e, newValue);
            }
          }
        }}
        onBlur={(e: any) => {
          if (
            !readOnlyMode &&
            (isEmpty(e.target.value) ||
              (type === 'number' && !e.target.validity?.valid))
          ) {
            const defaultValue = props.defaultValue;
            setValue(
              defaultValue !== undefined && defaultValue !== null
                ? defaultValue
                : '',
            );
            props.onChange!(e, defaultValue);
          }
        }}
        onKeyPress={(e: KeyboardEvent) => {
          if (type === 'number' && !/[0-9.,-]/.test(e.key)) {
            // otherwise mozilla allows to enter any character when input is empty ignoring onChange event
            e.preventDefault();
          }
        }}
        error={!!props.errorText}
        step={props.step}
        multiline={props.multiline}
        minRows={props.multilineRows}
        autoFocus={props.autoFocus}
        slotProps={{
          input: {
            tabIndex: props.tabIndex,
          },
        }}
      />
    </BaseLabeledComponent>
  );
};

export default memo(
  InputWithLabel,
  (before, after) =>
    before.label === after.label &&
    before.size === after.size &&
    before.type === after.type &&
    before.icon === after.icon &&
    isEqual(before.value, after.value) &&
    before.defaultValue === after.defaultValue &&
    before.onChange === after.onChange &&
    before.errorText === after.errorText &&
    before.errorTextIsPreformatted === after.errorTextIsPreformatted &&
    before.multiline === after.multiline &&
    before.multilineRows === after.multilineRows &&
    before.step === after.step &&
    before.readOnly === after.readOnly &&
    before.disabled === after.disabled &&
    before.autoFocus === after.autoFocus &&
    before.tabIndex === after.tabIndex,
);
