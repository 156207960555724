/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import {
  ACCESS_ADMIN,
  ACCESS_EDITOR,
  useAuth,
} from 'context/security/AuthContext';
import ReadOnlyContext from './ReadOnlyContext';

const AccessLevelReadOnlyContextProvider = ({ children }: any) => {
  const { accessLevelId } = useAuth();
  const isAdminOrEditor =
    accessLevelId && [ACCESS_ADMIN, ACCESS_EDITOR].includes(accessLevelId);
  const readOnlyMode = !isAdminOrEditor;

  return (
    <ReadOnlyContext.Provider value={readOnlyMode}>
      {children}
    </ReadOnlyContext.Provider>
  );
};
export default AccessLevelReadOnlyContextProvider;
