/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { getEmployeeFullName } from 'models/Employee';
import Pool from 'models/Pool';
import MonthlyIndividualReport from 'models/reports/MonthlyIndividualReport';
import poolQueries from 'queries/PoolQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BaseReportPage from './BaseReportPage';
import DatesForReportParameters from './DatesForReportParameters';

const MonthlyIndividualReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'MonthlyIndividual',
  } as MonthlyIndividualReport);
  const [disabled, setDisabled] = useState(false);

  const [pool, setPool] = useState<Pool | null>(null);
  let resetEmployeesFilter: any;

  return (
    <BaseReportPage
      title='Monthly Individual Report'
      parameters={parameters}
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pt={3}>
          <Grid item xs={6} md={4} xl={3}>
            <SelectWithLabel
              label='Pool'
              tabIndex={1}
              selectedValue={pool}
              optionsFetcher={poolQueries}
              onChange={(value) => {
                resetEmployeesFilter?.();
                setPool(value);
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Employees'
              tabIndex={1}
              optionsFetcher={() => ({
                isSuccess: true,
                data: pool?.employees || [],
              })}
              optionFilter={(e) => e.isActive}
              optionLabelGetter={getEmployeeFullName}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  employeeIds: ids || [],
                });
              }}
              disabled={disabled}
              passResetFunction={(f: any) => (resetEmployeesFilter = f)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <SwitchWithLabel
              label='Show all employees on a single sheet'
              tabIndex={1}
              checked={parameters.combinedReport || false}
              onChange={(_, checked) => {
                setParameters({ ...parameters, combinedReport: checked });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseReportPage>
  );
};

export default MonthlyIndividualReportPage;
