/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import http from './HttpClient';

export enum ImportType {
  Clients = 'clients',
  Consultants = 'consultants',
  Employees = 'employees',
  EmploymentTypes = 'employmenttypes',
  IndustryTypes = 'industrytypes',
  InvoicingFrequencies = 'invoicingfrequencies',
  Locations = 'locations',
  PayPeriods = 'payperiods',
  Pools = 'pools',
  Positions = 'positions',
  Periods = 'periods',
  Timesheets = 'timesheets',
}

class ImportQueries {
  async import(
    importType: ImportType,
    fileList: File[],
  ): Promise<ImportProgress> {
    const formData = new FormData();
    for (const file of fileList) {
      formData.append('files', file);
    }
    const response = await http.postForm(`/import/${importType}`, formData);
    return response.data;
  }

  async getImportProgress(
    importType: ImportType,
    id: string,
  ): Promise<ImportProgress> {
    const response = await http.get(`/import/${importType}/${id}`);
    return response.data;
  }

  async getImportResult(importType: ImportType, id: string): Promise<Blob> {
    const response = await http.get(`/import/${importType}/${id}/result`, {
      timeout: 60000,
      responseType: 'blob',
    });
    return response.data;
  }
}

interface ImportProgress {
  id: string;
  progressPercent: number;
  completed: boolean;
  hasResults: boolean;
  failureDetails?: string;
}

export default new ImportQueries();
