/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */

// Soft UI Dashboard PRO React base styles
import colors from 'softUI/assets/theme/base/colors';
import typography from 'softUI/assets/theme/base/typography';

const { secondary } = colors;
const { size } = typography;

const tablePagination = {
  styleOverrides: {
    selectLabel: {
      fontSize: size.sm,
      color: secondary.main,
    },
    select: {
      fontSize: size.sm,
      color: secondary.main,
    },
    displayedRows: {
      fontSize: size.sm,
      color: secondary.main,
    },
    actions: {
      fontSize: size.sm,
      color: secondary.main,
    },
  },
};

export default tablePagination;
