/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import EmploymentType from 'models/EmploymentType';
import BaseModelQueries from './BaseModelQueries';

class EmploymentTypeQueries extends BaseModelQueries<EmploymentType> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new EmploymentTypeQueries('/employmenttypes');
