/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';

export type FormInputSectionParams<TFormModel> = {
  triggerSubmit: () => void;
  editedData: TFormModel;
  updateEditedDataField: (fieldName: keyof TFormModel, value: any) => void;
  validationErrors: ValidationErrors<TFormModel>;
};

export type FormParams<TFormModel> = {
  validationHelper: ValidationHelper<TFormModel>;
  initialData: TFormModel;
  children: (props: FormInputSectionParams<TFormModel>) => React.ReactNode;
  onSubmit: (editedData: TFormModel) => void;
};

/**
 * Form component is a wrapper upon input fields section. Its aim is to validate edited form model.
 * Form submit is only propagated in case if edited form model is valid.
 */
const Form = <TFormModel,>(params: FormParams<TFormModel>) => {
  const [editedData, setEditedData] = useState<TFormModel>(params.initialData);
  const [lastEditedFieldName, setLastEditedFieldName] =
    useState<keyof TFormModel>();
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrors<TFormModel>
  >({});

  useEffect(() => {
    if (lastEditedFieldName) {
      params.validationHelper
        .validate(editedData, lastEditedFieldName)
        .then((err) =>
          params.validationHelper.updateValidationErrors(
            err,
            setValidationErrors,
          ),
        );
    }
  }, [editedData]);

  function updateEditedDataField(fieldName: keyof TFormModel, value: any) {
    setEditedData((prev) => {
      const updated = { ...prev } as any;
      updated[fieldName] = value;
      return updated;
    });
    setLastEditedFieldName(fieldName);
  }

  async function handleSubmitButtonClick() {
    const allFieldsErrors = await params.validationHelper.validate(editedData);
    if (Object.keys(allFieldsErrors).length > 0) {
      setLastEditedFieldName(undefined);
      params.validationHelper.updateValidationErrors(
        allFieldsErrors,
        setValidationErrors,
      );
      return;
    } else {
      setValidationErrors({});
      params.onSubmit(editedData);
    }
  }

  return (
    <>
      {params.children({
        triggerSubmit: handleSubmitButtonClick,
        editedData: editedData,
        updateEditedDataField: updateEditedDataField,
        validationErrors: validationErrors,
      })}
    </>
  );
};

export default Form;
