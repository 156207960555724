/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';
import PayrollReport from 'models/reports/PayrollReport';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import payPeriodQueries from 'queries/PayPeriodQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BaseReportPage, { validateParameters } from './BaseReportPage';
import DatesForReportParameters from './DatesForReportParameters';

const sortFields = [
  {
    id: 0,
    name: 'WED',
  },
  {
    id: 1,
    name: 'Client',
  },
  {
    id: 2,
    name: 'Employment Type',
  },
  {
    id: 3,
    name: 'Last Name',
  },
  {
    id: 4,
    name: 'First Name',
  },
  {
    id: 5,
    name: 'Straight Time',
  },
  {
    id: 6,
    name: 'Overtime',
  },
  {
    id: 7,
    name: 'Pay Rate',
  },
  {
    id: 8,
    name: 'OT Pay Rate',
  },
  {
    id: 9,
    name: 'Per Diem',
  },
  {
    id: 10,
    name: 'Holiday Time',
  },
  {
    id: 11,
    name: 'Vacation Time',
  },
  {
    id: 12,
    name: 'Sick Time',
  },
];

const validationHelper = new ValidationHelper<PayrollReport>(
  Yup.object({
    payPeriodIds: Yup.array().required(
      'At least one Pay Period must be present',
    ),
    employmentTypeIds: Yup.array().required(
      'At least one Employment Type must be present',
    ),
  }),
);

const PayrollReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'Payroll',
    sortOrderId: 0,
  } as PayrollReport);
  const [validationErrors, setValidationErrors] = useState(
    {} as ValidationErrors<PayrollReport>,
  );
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Payroll Report'
      parameters={parameters}
      onValidateParameters={() =>
        validateParameters(parameters, validationHelper, setValidationErrors)
      }
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          validationErrors={validationErrors}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />

        <Grid container item spacing={2} xs={12} pb={3}>
          <Grid item xs={6} md={4} xl={3}>
            <SelectWithLabel
              label='Sorting Field'
              tabIndex={1}
              selectedValue={sortFields[parameters.sortOrderId]}
              optionsFetcher={() => ({
                isSuccess: true,
                data: sortFields,
              })}
              onChange={(value) =>
                setParameters({
                  ...parameters,
                  sortOrderId: value ? value.id : 0,
                })
              }
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Pay Periods'
              tabIndex={1}
              optionsFetcher={payPeriodQueries}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  payPeriodIds: ids || [],
                });
              }}
              disabled={disabled}
              error={validationErrors?.payPeriodIds?.join(';')}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Employment Types'
              tabIndex={1}
              optionsFetcher={employmentTypeQueries}
              optionFilter={(t) => t.isActive}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  employmentTypeIds: ids || [],
                });
              }}
              disabled={disabled}
              error={validationErrors?.employmentTypeIds?.join(';')}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <SwitchWithLabel
              label='Show all pay periods on a single sheet'
              tabIndex={1}
              checked={parameters.combinedReport || false}
              onChange={(_, checked) => {
                setParameters({ ...parameters, combinedReport: checked });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseReportPage>
  );
};

export default PayrollReportPage;
