/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import AccessLevel from 'models/AccessLevel';
import { DataWrapper } from 'queries/BaseWrappedListingQueries';
import securityQueries from 'queries/SecurityQueries';
import AuthContext, { IAuthContext } from './AuthContext';

function AuthContextProvider({ children }: any) {
  const [isAuthed, setAuthed] = useState<boolean | null>(() => {
    const storedValue = localStorage.getItem('isAuthed');
    return storedValue === 'true' ? true : null;
  });
  const [isReCaptchaRequired, setReCaptchaRequired] = useState<boolean>(false);

  const [currentAccessLevelId, setCurrentAccessLevelId] = useState<
    number | null
  >(() => {
    const storedAccessLevelId = localStorage.getItem('accessLevelId');
    if (storedAccessLevelId) {
      return Number(storedAccessLevelId);
    }
    return null;
  });

  const [currentAccessLevel, setCurrentAccessLevel] = useState<string | null>(
    () => {
      const storedAccessLevel = localStorage.getItem('accessLevel');
      if (storedAccessLevel) {
        return storedAccessLevel;
      }
      return null;
    },
  );

  const queryClient = useQueryClient();

  function resetAuthorizationState() {
    setAuthed(false);
    setCurrentAccessLevelId(null);
    setCurrentAccessLevel(null);
    localStorage.setItem('isAuthed', 'false');
    localStorage.removeItem('accessLevelId');
    localStorage.removeItem('accessLevel');
  }

  function setAuthorizationState(
    rawResponse: AxiosResponse<DataWrapper<AccessLevel>>,
  ) {
    const accessLevel = rawResponse.data.data;
    setAuthed(true);
    localStorage.setItem('isAuthed', 'true');
    setCurrentAccessLevelId(accessLevel.id!);
    localStorage.setItem('accessLevelId', String(accessLevel.id));
    setCurrentAccessLevel(accessLevel.name);
    localStorage.setItem('accessLevel', String(accessLevel.name));
  }

  useEffect(() => {
    function unauthorizedEventListener(e: any) {
      resetAuthorizationState();
      const responseHeaders = e.detail?.response?.headers;
      setReCaptchaRequired(responseHeaders?.get('Captcha-Required') || false);
      // some queries may be in status 'error' due to 401
      queryClient.removeQueries();
    }
    window.addEventListener('tas_http_unauthorized', unauthorizedEventListener);
    return () => {
      window.removeEventListener(
        'tas_http_unauthorized',
        unauthorizedEventListener,
      );
    };
  }, [queryClient]);

  const auth: IAuthContext = {
    isAuthed: isAuthed,
    accessLevelId: currentAccessLevelId,
    accessLevel: currentAccessLevel,
    async verifyLogin(): Promise<boolean> {
      try {
        const rawResponse = await securityQueries.verifyLogin();
        setAuthorizationState(rawResponse);
        return true;
      } catch (error: any) {
        console.log(error);
        resetAuthorizationState();
        return false;
      }
    },
    async login(username: string, password: string, reCaptchaToken?: string) {
      try {
        const rawResponse = await securityQueries.login(
          username,
          password,
          reCaptchaToken,
        );
        setAuthorizationState(rawResponse);
      } catch (error: any) {
        console.log(error);
        resetAuthorizationState();
        throw error;
      }
    },
    async logout() {
      await securityQueries.logout();
      resetAuthorizationState();
    },
    isReCaptchaRequired: isReCaptchaRequired,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
export default AuthContextProvider;
