/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import ReportType from '../ReportType';
import BaseReport from './BaseReport';

export enum WeekEndingDay {
  SUNDAY = 'SUNDAY',
  SATURDAY = 'SATURDAY',
}

export type CustomReportType = ReportType &
  (
    | 'CustomWeeklyIndividual'
    | 'CustomMonthlyIndividual'
    | 'CustomWeeklyOffice'
    | 'CustomMonthlyOffice'
  );

export default interface BaseCustomReport extends BaseReport {
  type?: CustomReportType;
  templateId?: number;
  weekEndingDay?: WeekEndingDay;
  employeeIds?: number[];
  locationIds?: number[];
  combinedReport?: boolean;
}
