/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { GridColDef } from '@mui/x-data-grid';
import { decimalFormatter, usDateFormatter } from 'util/GridFormatters';
import calculatePayments from './getPayments';

const dataColumns: GridColDef[] = [
  {
    headerName: '#',
    field: 'id',
    width: 70,
    disableReorder: true,
  },
  {
    headerName: 'Period #',
    field: 'period.id',
    width: 75,
    valueGetter: (_, row) => row.period.id,
  },
  {
    headerName: 'WED',
    field: 'weekEndingDate',
    width: 110,
    type: 'date',
    valueFormatter: usDateFormatter(),
  },
  {
    headerName: 'Client Name',
    field: 'period.client.name',
    valueGetter: (_, row) => row.period.client.name,
    width: 200,
  },
  {
    headerName: 'First Name',
    field: 'period.consultant.firstName',
    valueGetter: (_, row) => row.period.consultant.firstName,
  },
  {
    headerName: 'Last Name',
    field: 'period.consultant.lastName',
    valueGetter: (_, row) => row.period.consultant.lastName,
  },
  {
    headerName: 'Employment Type',
    field: 'period.employmentType.name',
    valueGetter: (_, row) => row.period.employmentType?.name,
  },
  {
    headerName: 'Payroll ID',
    field: 'period.consultant.payrollId',
    sortable: false,
    valueGetter: (_, row) => row.period.consultant.payrollId,
  },
  {
    headerName: 'Straight Time',
    field: 'straightTime',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Overtime',
    field: 'overtime',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Holiday Time',
    field: 'holidayTime',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Vacation Time',
    field: 'vacationTime',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Sick Time',
    field: 'sickTime',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Per Diem',
    field: 'perDiem',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Bill Rate',
    field: 'billRate',
    type: 'number',
    valueGetter: (_, row) => {
      switch (row.period.employmentType?.family?.id) {
        case 2:
        case 3:
        case 4:
        case 6:
        case 7:
          return row.period.billRatePermFee;
        default:
          return 0;
      }
    },
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'OT Bill Rate',
    field: 'otBillRate',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 120,
  },
  {
    headerName: 'Pay Rate',
    field: 'payRate',
    type: 'number',
    valueGetter: (_, row) => {
      switch (row.period.employmentType?.family?.id) {
        case 2:
        case 3:
        case 4:
        case 6:
        case 7:
          return row.period.payRateBaseSalary;
        default:
          return 0;
      }
    },
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'OT Pay Rate',
    field: 'otPayRate',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 120,
  },
  {
    headerName: 'Perm Fee',
    field: 'permFee',
    type: 'number',
    valueGetter: (_, row) => {
      switch (row.period.employmentType?.family?.id) {
        case 1:
          return row.period.billRatePermFee;
        default:
          return 0;
      }
    },
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'Base Salary',
    field: 'baseSalary',
    type: 'number',
    valueGetter: (_, row) => {
      switch (row.period.employmentType?.family?.id) {
        case 1:
        case 5:
          return row.period.payRateBaseSalary;
        default:
          return 0;
      }
    },
    valueFormatter: decimalFormatter(2),
    width: 95,
  },
  {
    headerName: 'Is Bill Fixed',
    type: 'boolean',
    field: 'isBillFixed',
    width: 50,
  },
  {
    headerName: 'Fixed Bill',
    field: 'fixedBill',
    type: 'number',
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'Total Bill',
    field: 'totalBill',
    sortable: false,
    type: 'number',
    valueGetter: (_, row) => calculatePayments(row).totalBill || 0,
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'Total Pay',
    field: 'totalPay',
    sortable: false,
    type: 'number',
    valueGetter: (_, row) => calculatePayments(row).totalPay || 0,
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'Burden',
    field: 'burden',
    sortable: false,
    type: 'number',
    valueGetter: (_, row) => calculatePayments(row).burden || 0,
    valueFormatter: decimalFormatter(2),
    width: 60,
  },
  {
    headerName: 'Fee',
    field: 'vendorManagement',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Discount',
    field: 'discount',
    sortable: false,
    type: 'number',
    valueGetter: (_, row) => calculatePayments(row).discount || 0,
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'GP',
    field: 'gp',
    sortable: false,
    type: 'number',
    valueGetter: (_, row) => calculatePayments(row).gm || 0,
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'GP %',
    field: 'gpPercent',
    sortable: false,
    type: 'number',
    valueGetter: (_, row) => calculatePayments(row).gmPercent || 0,
    valueFormatter: decimalFormatter(2),
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 170,
  },
  {
    headerName: 'Notes',
    field: 'notes',
    width: 170,
  },
];

export default dataColumns;
