/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import { useAuth } from 'context/security/AuthContext';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import SidenavCollapse from 'components/navigation/Sidenav/SidenavCollapse';
import SidenavItem from 'components/navigation/Sidenav/SidenavItem';
import SidenavList from 'components/navigation/Sidenav/SidenavList';
import SidenavRoot from 'components/navigation/Sidenav/SidenavRoot';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';
import { setMiniSidenav, useSoftUIController } from 'softUI/context';

function findRoute(pathname, routes, stack = []) {
  return routes.reduce((result, e) => {
    // Skip others if we found any.
    if (result) return result;
    // Match?
    if (e.route && matchPath({ path: e.route, exact: true }, pathname)) {
      stack.push(e);
      return stack;
    }
    // Not matched, check children.
    if (e.child?.length) {
      stack.push(e);
      result = findRoute(pathname, e.child, stack);
      if (!result) {
        stack.pop();
      }
    }
    return result;
  }, null);
}

// eslint-disable-next-line react/prop-types
function Sidenav({ brand = '', miniBrand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const routeSplit = findRoute(pathname, routes);
  const collapseName = routeSplit?.[0] ? routeSplit[0].key : '';
  const itemName = routeSplit?.[1] ? routeSplit[1].key : '';
  const subItemName = routeSplit?.[2] ? routeSplit[2].key : '';
  const [openCollapse, setOpenCollapse] = useState(collapseName);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(itemName);
  const { accessLevelId: authUserAccessLevelId } = useAuth();

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
     */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  function userHasRequiredAccessLevelPredicate({ accessLevels }) {
    // empty accessLevels means that route has no restrictions
    return (
      isEmpty(accessLevels) || accessLevels.includes(authUserAccessLevelId)
    );
  }

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse
      .filter(userHasRequiredAccessLevelPredicate)
      .map(({ name, route, key, href }) =>
        href ? (
          <Link
            key={key}
            href={href}
            target='_blank'
            rel='noreferrer'
            sx={{ textDecoration: 'none' }}
          >
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
            <SidenavItem name={name} active={key === subItemName} nested />
          </NavLink>
        ),
      );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses
      .filter(userHasRequiredAccessLevelPredicate)
      .map(({ type, name, child, route, href, key }) => {
        let returnValue;

        if (!type || type === 'collapse') {
          if (child && type === 'collapse') {
            returnValue = (
              <SidenavItem
                key={key}
                name={name}
                active={key === itemName}
                open={openNestedCollapse === name}
                onClick={() =>
                  openNestedCollapse === name
                    ? setOpenNestedCollapse(false)
                    : setOpenNestedCollapse(name)
                }
              >
                {renderNestedCollapse(child)}
              </SidenavItem>
            );
          } else {
            returnValue = href ? (
              <Link
                href={href}
                key={key}
                target='_blank'
                rel='noreferrer'
                sx={{ textDecoration: 'none' }}
              >
                <SidenavItem name={name} active={key === itemName} />
              </Link>
            ) : (
              <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
                <SidenavItem name={name} active={key === itemName} />
              </NavLink>
            );
          }
        } else if (type === 'divider') {
          returnValue = <Divider key={key} />;
        }

        return <SidenavList key={key}>{returnValue}</SidenavList>;
      });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes
    .filter(userHasRequiredAccessLevelPredicate)
    .map(({ type, name, icon, title, child, key, href, route }) => {
      let returnValue;

      if (!type) {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target='_blank'
              rel='noreferrer'
              sx={{ textDecoration: 'none' }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={true}
              />
            </Link>
          );
        } else if (route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={true}
              />
            </NavLink>
          );
        }
      } else if (type === 'collapse' && child) {
        returnValue = (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            onClick={() =>
              openCollapse === key
                ? setOpenCollapse(false)
                : setOpenCollapse(key)
            }
          >
            {renderCollapse(child)}
          </SidenavCollapse>
        );
      } else if (type === 'title') {
        returnValue = (
          <SoftTypography
            key={key}
            display='block'
            variant='caption'
            fontWeight='bold'
            textTransform='uppercase'
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === 'divider') {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    });

  return (
    <SidenavRoot
      {...rest}
      variant='permanent'
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <SoftBox pt={0.5} pb={0.5} px={2} textAlign='center'>
        <SoftBox
          display={{ xs: 'block', xl: 'none' }}
          position='absolute'
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <SoftTypography variant='h6' color='secondary'>
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component={NavLink}
          to='/'
          title={`CAS ${window._env_.TAS_VERSION}`}
          display='flex'
          alignItems='center'
        >
          {miniSidenav && miniBrand ? (
            <SoftBox
              component='img'
              src={miniBrand}
              alt={brandName}
              width='2rem'
              sx={{ objectFit: 'cover' }}
              pt={1}
              pb={1}
            />
          ) : (
            brand && (
              <SoftBox
                component='img'
                src={brand}
                alt={brandName}
                width='100%'
                height='3rem'
                sx={{ objectFit: 'cover' }}
              />
            )
          )}
        </SoftBox>
      </SoftBox>
      <Divider
        sx={{
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
