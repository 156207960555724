/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { asUTC } from 'util/Formatters';
import Timesheet from 'models/Timesheet/Timesheet';
import getAvailability from 'models/Timesheet/getAvailability';
import BaseModelQueries from './BaseModelQueries';

class TimesheetQueries extends BaseModelQueries<Timesheet> {
  constructor(url: string) {
    super(url);
  }

  toRequest(timesheet: Timesheet): Timesheet {
    const availability = getAvailability(timesheet);

    return {
      ...timesheet,
      weekEndingDate: asUTC(new Date(timesheet.weekEndingDate)),

      straightTime: availability.straightTime ? timesheet.straightTime : 0,
      overtime: availability.overtime ? timesheet.overtime : 0,
      holidayTime: availability.holidayTime ? timesheet.holidayTime : 0,
      vacationTime: availability.vacationTime ? timesheet.vacationTime : 0,
      sickTime: availability.sickTime ? timesheet.sickTime : 0,
      otBillRate: availability.otBillRate ? timesheet.otBillRate : 0,
      otPayRate: availability.otPayRate ? timesheet.otPayRate : 0,
      perDiem: availability.perDiem ? timesheet.perDiem : 0,
      permFee: availability.permFee ? timesheet.permFee : 0,
      isBillFixed: availability.isBillFixed
        ? !!timesheet.isBillFixed
        : undefined,
      fixedBill:
        availability.isBillFixed && timesheet.isBillFixed
          ? timesheet.fixedBill || 0
          : undefined,
      vendorManagement: availability.vendorManagement
        ? timesheet.vendorManagement
        : 0,
    };
  }
}

export default new TimesheetQueries('/timesheets');
