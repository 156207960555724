/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import EmploymentTypeFamily from 'models/EmploymentTypeFamily';
import BaseModelQueries from './BaseModelQueries';

class EmploymentTypeFamilyQueries extends BaseModelQueries<EmploymentTypeFamily> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new EmploymentTypeFamilyQueries('/employmenttypefamilies');
