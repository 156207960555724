/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'context/security/AuthContext';
import { isCredentialsExpiredError } from 'queries/SecurityQueries';
import ConfirmationDialog from 'components/ConfirmationDialog';
import CoverLayout from 'layouts/CoverLayout';
import curved9 from 'softUI/assets/images/curved-images/curved-6.jpg';
import SoftAlert from 'softUI/components/SoftAlert';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import SoftInput from 'softUI/components/SoftInput';
import SoftTypography from 'softUI/components/SoftTypography';

function SignInPage() {
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
  const { isAuthed, login, verifyLogin, isReCaptchaRequired } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [
    confirmChangePasswordDialogIsOpen,
    setConfirmChangePasswordDialogIsOpen,
  ] = useState(false);
  const [progressBackdropIsOpen, setProgressBackdropIsOpen] = useState(false);
  const reCaptchaRef = createRef<ReCAPTCHA>();

  function navigateBack() {
    if (location.state?.referer) {
      navigate(location.state?.referer, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  }

  const handleSignIn = async () => {
    if (isReCaptchaRequired && !reCaptchaToken) {
      setErrorMessage('Pass the CAPTCHA to proceed');
      return;
    }

    setProgressBackdropIsOpen(true);
    try {
      if (isReCaptchaRequired) {
        await login(username, password, reCaptchaToken!);
      } else {
        await login(username, password);
      }
    } catch (error: any) {
      console.log(error);
      if (isCredentialsExpiredError(error)) {
        setConfirmChangePasswordDialogIsOpen(true);
      } else {
        setErrorMessage('Wrong username or password');
      }
    } finally {
      if (isReCaptchaRequired) {
        try {
          // This doesn't work: reCaptchaRef.current?.reset();
          (window as any).grecaptcha?.reset();
        } catch (err: any) {
          console.log(err);
        }
        setReCaptchaToken(null);
      }
      setProgressBackdropIsOpen(false);
    }
  };

  const handleChangePasswordDialogResult = (isConfirmed: boolean) => {
    setConfirmChangePasswordDialogIsOpen(false);
    if (isConfirmed) {
      navigate('/change-password', {
        state: {
          userName: username,
          referer: location.state?.referer,
        },
      });
    }
  };

  const handleUsernameChange = (event: any) => {
    setUsername(event.target.value);
    setErrorMessage('');
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
    setErrorMessage('');
  };

  const handleEnterKeyPress = (event: any) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleSignIn();
    }
  };

  useEffect(() => {
    (async () => {
      if (isAuthed) {
        navigateBack();
      } else {
        const result = await verifyLogin();
        if (result) {
          navigateBack();
        }
      }
    })();
  }, [isAuthed || false]);

  if (isAuthed == null) {
    return <></>;
  } else {
    return (
      <CoverLayout
        title='Catapult Accounting System'
        description='Enter your username and password to sign in'
        image={curved9}
      >
        <SoftBox component='form' role='form'>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography variant='caption' fontWeight='bold'>
                Username
              </SoftTypography>
            </SoftBox>
            <SoftInput
              error={!!errorMessage}
              type='text'
              placeholder='Username'
              onChange={handleUsernameChange}
              onKeyPress={handleEnterKeyPress}
              autoFocus={true}
            >
              {username}
            </SoftInput>
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography variant='caption' fontWeight='bold'>
                Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              error={!!errorMessage}
              type='password'
              placeholder='Password'
              onChange={handlePasswordChange}
              onKeyPress={handleEnterKeyPress}
            >
              {password}
            </SoftInput>
          </SoftBox>
          {!!errorMessage && (
            <SoftAlert color='error'>
              <SoftTypography color='inherit' variant='body2'>
                {errorMessage}
              </SoftTypography>
            </SoftAlert>
          )}
          {isReCaptchaRequired && (
            <SoftBox mt={4} mb={2}>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={(window as any)._env_.GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={(token) => setReCaptchaToken(token)}
              />
            </SoftBox>
          )}
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant='gradient'
              color='info'
              fullWidth
              onClick={handleSignIn}
              disabled={!username}
            >
              sign in
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <ConfirmationDialog
          open={confirmChangePasswordDialogIsOpen}
          title='Expired Password'
          description='Your password is expired. Please change it.'
          yesButtonCaption='OK'
          disableNoButton={true}
          resultCallback={handleChangePasswordDialogResult}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={progressBackdropIsOpen}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </CoverLayout>
    );
  }
}

export default SignInPage;
