/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { createContext, useContext } from 'react';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export default AuthContext;

export interface IAuthContext {
  isAuthed: boolean | null;
  accessLevelId: number | null;
  accessLevel: string | null;
  verifyLogin: () => Promise<boolean>;
  login: (username: string, password: string, reCaptchaToken?: string) => void;
  logout: () => void;
  isReCaptchaRequired: boolean;
}

export function useAuth(): IAuthContext {
  return useContext(AuthContext);
}

export const ACCESS_ADMIN = 1;
export const ACCESS_EDITOR = 2;
export const ACCESS_REPORTER = 3;
