/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import BaseModel from 'models/BaseModel';
import Editor from './Editor';
import { BasePageProps } from './index';

const EditEntityPage = <TModel extends BaseModel>(
  props: BasePageProps<TModel>,
) => {
  const dynamicParams = useParams();
  const navigate = useNavigate();

  const [initialEntity, setInitialEntity] = useState(
    props.emptyEditedEntityFactory(),
  );

  const id = Number(dynamicParams.id);
  if (isNaN(id)) {
    navigate(props.sourcePageUrl);
    return <></>;
  }

  const getEntityQuery = props.queriesImpl.getById(id, true);
  const removeQueries = props.queriesImpl.getRemoveCachedQueriesFunction();

  useEffect(() => {
    if (getEntityQuery.isPending) {
      getEntityQuery.refetch();
    } else if (getEntityQuery.isSuccess) {
      setInitialEntity(getEntityQuery.data);
    } else if (getEntityQuery.isError) {
      navigateToSourcePage();
    }
  }, [id, getEntityQuery.status]);

  // cleanup when component is unmounted
  // fyi: in strict mode react mounts each component twice, thus query removal may lead to double GET requests
  useEffect(() => () => removeQueries(), []);

  function navigateToSourcePage() {
    navigate(props.sourcePageUrl);
  }

  return (
    <Editor
      data={initialEntity}
      queriesImpl={props.queriesImpl}
      saveMode='update'
      validationHelper={props.validationHelper}
      showSaveButton={props.showSaveButton}
      onCloseHandler={navigateToSourcePage}
      renderInputFieldsSection={props.children}
      getAvailability={props.getAvailability}
      getLabels={props.getLabels}
      confirmCloseDialogText={props.confirmCloseDialogText}
      confirmRemovalDialogText={props.confirmRemovalDialogText}
      confirmSaveDialogText={props.confirmSaveDialogText}
      confirmResetDialogText={props.confirmResetDialogText}
    />
  );
};

export default EditEntityPage;
