/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useMutation } from '@tanstack/react-query';
import Filter from 'models/Filter';
import http from './HttpClient';

export enum ExportType {
  None = 'none',
  Clients = 'clients',
  Consultants = 'consultants',
  Employees = 'employees',
  EmploymentTypes = 'employmenttypes',
  IndustryTypes = 'industrytypes',
  InvoicingFrequencies = 'invoicingfrequencies',
  Locations = 'locations',
  PayPeriods = 'payperiods',
  Pools = 'pools',
  Positions = 'positions',
  Periods = 'periods',
  Timesheets = 'timesheets',
}

class ExportQueries {
  export(type: ExportType, filter: Filter) {
    return useMutation({
      mutationFn: () => {
        return http.post(`/export/${type}`, filter, {
          responseType: 'blob',
        });
      },
    });
  }
}

export default new ExportQueries();
