/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SoftBox from 'softUI/components/SoftBox';
import { setLayout, useSoftUIController } from 'softUI/context';

function PageLayout({ background = 'default', children }) {
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [pathname]);

  return (
    <SoftBox
      width='100vw'
      height='100vh'
      bgColor={background}
      sx={{
        overflowX: 'hidden',
        display: 'table-cell',
        verticalAlign: 'middle',
      }}
    >
      {children}
    </SoftBox>
  );
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
