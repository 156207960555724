/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Icon from '@mui/material/Icon';
import { decimalFormatter, percentFormatter } from 'util/GridFormatters';
import EmploymentType from 'models/EmploymentType';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const EmploymentTypesPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'employmentTypeDataTableState',
    addNewEntityPageUrl: '/employment-types/new',
    formatEditEntityPageUrl(id) {
      return `/employment-types/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Employment Types?`;
      }
      return `Are you sure you want to remove selected Employment Type with id ${id} ?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all existing'
      } Employment Types`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 125px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.EmploymentTypes,
    importType: ImportType.EmploymentTypes,
    queriesImpl: employmentTypeQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 1 },
      {
        field: 'burden',
        headerName: 'Burden %',
        width: 100,
        valueFormatter: percentFormatter(2),
      },
      {
        field: 'isBurdenFixed',
        headerName: 'Is Burden Fixed?',
        width: 150,
        type: 'boolean',
      },
      {
        field: 'fixedBurden',
        headerName: 'Fixed Burden',
        width: 120,
        valueFormatter: decimalFormatter(2),
      },
      {
        field: 'isActive',
        headerName: 'Active',
        width: 80,
        type: 'boolean',
        renderCell(params) {
          const type = params.row as EmploymentType;
          return type.isActive ? (
            <Icon fontSize='medium' color='success'>
              check
            </Icon>
          ) : (
            <Icon fontSize='medium' color='error'>
              close
            </Icon>
          );
        },
      },
      { field: 'notes', headerName: 'Notes', flex: 2 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return <BaseListPage {...properties} />;
};

export default EmploymentTypesPage;
