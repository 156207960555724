/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { Dispatch } from 'react';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BaseReport from 'models/reports/BaseReport';
import DateWithLabel from 'components/input/DateWithLabel';

export type ReportParametersProps<T> = {
  parameters: T;
  onUpdateParameters: Dispatch<(prevState: T) => T>;
  validationErrors?: any;
  disabled: boolean;
};

const DatesForReportParameters = <T extends BaseReport>(
  props: ReportParametersProps<T> & {
    autoFocus?: boolean;
    tabIndexForInputs?: number;
  },
) => {
  return (
    <Grid container item spacing={2} xs={12}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid item xs={6} md={4} xl={3}>
          <DateWithLabel
            label='Start Date'
            autoFocus={props.autoFocus}
            tabIndex={props.tabIndexForInputs}
            value={props.parameters.startDate || null}
            onChange={(date: any) => {
              props.onUpdateParameters((prevState) => {
                return {
                  ...prevState,
                  startDate: date,
                };
              });
            }}
            disabled={props.disabled}
            error={props.validationErrors?.startDate?.join(';')}
          />
        </Grid>
        <Grid item xs={6} md={4} xl={3}>
          <DateWithLabel
            label='End Date'
            tabIndex={props.tabIndexForInputs}
            value={props.parameters.endDate || null}
            onChange={(date: any) => {
              props.onUpdateParameters((prevState) => {
                return {
                  ...prevState,
                  endDate: date,
                };
              });
            }}
            disabled={props.disabled}
            error={props.validationErrors?.endDate?.join(';')}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default DatesForReportParameters;
