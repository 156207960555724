/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import AccessLevelReadOnlyContextProvider from 'context/readonly/AccessLevelReadOnlyContextProvider';
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import timesheetQueries from 'queries/TimesheetQueries';
import CombinedFilter from 'components/CombinedFilter';
import timesheetFilterOptions from 'components/CombinedFilter/PredefinedFilter/options/timesheetsFilterOptions';
import { useTimesheetFilterContext } from 'components/CombinedFilter/context/TimesheetFilterContext';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';
import dataColumns from './dataColumns';

const TimesheetsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    useFilterContext: useTimesheetFilterContext,
    filterComponent: (
      <CombinedFilter
        predefinedFilterOptions={timesheetFilterOptions}
        filterContext={useTimesheetFilterContext}
      />
    ),
    localStorageItemName: 'timesheetDataTableState',
    addNewEntityPageUrl: '/timesheets/new',
    formatEditEntityPageUrl(id) {
      return `/timesheets/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Timesheets?`;
      }
      return `Are you sure you want to remove selected Timesheet with id ${id}?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all found'
      } Timesheets`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 171px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.Timesheets,
    importType: ImportType.Timesheets,
    queriesImpl: timesheetQueries,
    gridColumns: dataColumns,
    defaultSort: { field: 'id', sort: 'desc' },
  };

  return (
    <AccessLevelReadOnlyContextProvider>
      <BaseListPage {...properties} />
    </AccessLevelReadOnlyContextProvider>
  );
};

export default TimesheetsPage;
