/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Employee from 'models/Employee';
import BaseModelQueries from './BaseModelQueries';

class EmployeeQueries extends BaseModelQueries<Employee> {
  constructor(url: string) {
    super(url, [
      { property: 'firstName', direction: 'asc' },
      { property: 'lastName', direction: 'asc' },
    ]);
  }
}

export default new EmployeeQueries('/employees');
