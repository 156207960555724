/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Timesheet from 'models/Timesheet/Timesheet';

export type TimesheetPayments = {
  totalBill: number;
  totalPay: number;
  burden: number;
  discount: number;
  gm: number;
  gmPercent: number;
};

const getPayments = (timesheet: Timesheet): TimesheetPayments => {
  const metrics = calculatePayments(timesheet);
  return {
    totalBill: round(metrics.totalBill),
    totalPay: round(metrics.totalPay),
    burden: round(metrics.burden),
    discount: round(metrics.discount),
    gm: round(metrics.gm),
    gmPercent: round(metrics.gmPercent),
  };
};

const round = (value: number): number => {
  return Math.round(value * 1e2) / 1e2;
};

const calculatePayments = (timesheet: Timesheet) => {
  const isBillFixed = !!timesheet.isBillFixed;
  const fixedBill = timesheet.fixedBill || 0;
  const vendorManagement = timesheet.vendorManagement;
  const straightTime = timesheet.straightTime;
  const overtime = timesheet.overtime;
  const overtimeBillRate = timesheet.otBillRate;
  const overtimePayRate = timesheet.otPayRate;
  const perDiem = timesheet.perDiem;
  const holidayTime = timesheet.holidayTime;
  const vacationTime = timesheet.vacationTime;
  const sickTime = timesheet.sickTime;
  const permFee = timesheet.permFee;

  let totalBillValue = 0;
  let totalPayValue = 0;
  let burdenValue = 0;
  let discountValue = 0;
  let gmValue = 0;
  let gmPercentValue = 0;

  const period = timesheet.period;
  if (period?.employmentType) {
    const employmentType = period.employmentType;
    const burden = employmentType.burden / 100;
    const isBurdenFixed = employmentType.isBurdenFixed;
    const fixedBurden = employmentType.fixedBurden || 0;
    const periodBaseSalary = period.payRateBaseSalary;
    const periodPayRate = period.payRateBaseSalary;
    const periodBillRate = period.isBillFixed
      ? period.fixedBill! / 40
      : period.billRatePermFee;
    if (isBillFixed) {
      totalBillValue = fixedBill;
    } else {
      totalBillValue =
        straightTime * periodBillRate + overtime * overtimeBillRate;
    }
    const divider = 52 / 40;
    switch (employmentType.family!.id) {
      case 1:
        if (isBillFixed) {
          discountValue = (fixedBill * vendorManagement) / 100;
          totalBillValue = fixedBill;
        } else {
          discountValue = (permFee * vendorManagement) / 100;
          totalBillValue = permFee;
        }
        totalPayValue = 0;
        burdenValue = 0;
        gmValue = totalBillValue - discountValue;
        gmPercentValue = 1;
        break;
      case 2:
      case 3:
      case 6:
        totalPayValue =
          periodPayRate *
            (holidayTime + vacationTime + sickTime + straightTime) +
          overtimePayRate * overtime;
        if (isBurdenFixed) {
          burdenValue = (straightTime + overtime) * fixedBurden + perDiem;
        } else {
          burdenValue = totalPayValue * burden + perDiem;
        }
        discountValue = (totalBillValue * vendorManagement) / 100;
        gmValue = totalBillValue - totalPayValue - burdenValue - discountValue;
        if (totalBillValue - discountValue !== 0) {
          gmPercentValue = gmValue / (totalBillValue - discountValue);
        } else {
          gmPercentValue = 0;
        }
        break;
      case 4:
        totalPayValue =
          periodPayRate *
            (holidayTime + vacationTime + sickTime + straightTime) +
          overtimePayRate * overtime;
        if (isBurdenFixed) {
          burdenValue = (straightTime + overtime) * fixedBurden + perDiem;
        } else {
          burdenValue = totalPayValue * burden + perDiem;
        }
        discountValue = (totalBillValue * vendorManagement) / 100;
        gmValue = totalBillValue - totalPayValue - burdenValue - discountValue;
        if (totalBillValue - discountValue !== 0) {
          gmPercentValue = gmValue / (totalBillValue - discountValue);
        } else {
          gmPercentValue = 0;
        }
        break;
      case 5:
        totalPayValue =
          (periodBaseSalary / divider) * straightTime +
          holidayTime * ((holidayTime * periodBaseSalary) / divider) +
          vacationTime * ((vacationTime * periodBaseSalary) / divider) +
          sickTime * ((sickTime * periodBaseSalary) / divider);
        if (isBurdenFixed) {
          burdenValue = (straightTime + overtime) * fixedBurden + perDiem;
        } else {
          burdenValue = totalPayValue * burden + perDiem;
        }
        discountValue = (totalBillValue * vendorManagement) / 100;
        gmValue = totalBillValue - totalPayValue - burdenValue;
        if (totalBillValue !== 0) {
          gmPercentValue = gmValue / totalBillValue;
        } else {
          gmPercentValue = 0;
        }
        break;
      case 7:
        totalPayValue =
          periodPayRate *
            (holidayTime + vacationTime + sickTime + straightTime) +
          overtimePayRate * overtime;
        burdenValue = 0;
        discountValue = (totalBillValue * vendorManagement) / 100;
        gmValue = totalBillValue - totalPayValue - discountValue;
        if (totalBillValue - discountValue !== 0) {
          gmPercentValue = gmValue / (totalBillValue - discountValue);
        } else {
          gmPercentValue = 0;
        }
    }

    if (
      gmValue < 0 ||
      straightTime < 0 ||
      overtime < 0 ||
      holidayTime < 0 ||
      vacationTime < 0 ||
      sickTime < 0 ||
      perDiem < 0 ||
      overtimeBillRate < 0 ||
      overtimePayRate < 0
    ) {
      gmPercentValue = 0;
    }
  }

  return {
    totalBill: totalBillValue,
    totalPay: totalPayValue,
    burden: burdenValue,
    discount: discountValue,
    gm: gmValue,
    gmPercent: gmPercentValue * 100,
  };
};
export default getPayments;
