/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { Dispatch, SetStateAction } from 'react';
import { saveAs } from 'file-saver';
import { getErrorMessage } from 'queries/ErrorResponse';
import reportQueries from 'queries/ReportQueries';

const downloadReport = async (
  reportId: number,
  fileName: string,
  errorMessageHandler?: Dispatch<SetStateAction<string>>,
  onCompleteCallback?: () => any,
) => {
  try {
    const reportData = await reportQueries.getData(reportId);
    saveAs(reportData, fileName);
  } catch (error: any) {
    errorMessageHandler &&
      errorMessageHandler(
        await getErrorMessage(
          error,
          'Failed to download generated report data',
        ),
      );
  }
  onCompleteCallback?.();
};

export default downloadReport;
