/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import Employee from 'models/Employee';
import employeeQueries from 'queries/EmployeeQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BasePage, { BasicPageProps } from 'pages/BasePage';

const validationHelper = new ValidationHelper<Employee>(
  Yup.object({
    firstName: Yup.string()
      .required('Must be present')
      .max(30, 'Must not exceed 30 chars'),
    lastName: Yup.string()
      .required('Must be present')
      .max(30, 'Must not exceed 30 chars'),
  }),
);

const EmployeePage = (props: BasicPageProps) => {
  function createEmptyEmployee(): Employee {
    return {
      id: -1,
      firstName: '',
      lastName: '',
      isActive: true,
    };
  }

  const ParameterizedBasePage = BasePage<Employee>;

  return (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyEmployee}
      queriesImpl={employeeQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Employee?'
      confirmRemovalDialogText='Are you sure you want to delete current Employee?'
      confirmSaveDialogText='Are you sure you want to save changes to current Employee?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Employee?'
      formatEditEntityPageUrl={(id) => `/employees/edit/${id}`}
    >
      {(sectionParams) => (
        <Card sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='First Name'
                size='small'
                autoFocus={true}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.firstName}
                onChange={sectionParams.updateInputField('firstName')}
                errorText={sectionParams.validationErrors.firstName?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='Last Name'
                size='small'
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.lastName}
                onChange={sectionParams.updateInputField('lastName')}
                errorText={sectionParams.validationErrors.lastName?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SwitchWithLabel
                label='Is Active'
                tabIndex={sectionParams.tabIndexForInputs}
                checked={sectionParams.editedData.isActive}
                onChange={sectionParams.updateInputField('isActive')}
                error={sectionParams.validationErrors.isActive?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='Office'
                size='small'
                multiline={true}
                multilineRows={3}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.office}
                onChange={sectionParams.updateInputField('office')}
                errorText={sectionParams.validationErrors.office?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputWithLabel
                label='Notes'
                size='small'
                multiline={true}
                multilineRows={3}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.notes}
                onChange={sectionParams.updateInputField('notes')}
                errorText={sectionParams.validationErrors.notes?.join(';')}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </ParameterizedBasePage>
  );
};

export default EmployeePage;
