/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { ReactElement, ReactNode, Suspense, lazy } from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import {
  ACCESS_ADMIN,
  ACCESS_EDITOR,
  ACCESS_REPORTER,
} from 'context/security/AuthContext';
import PeriodPage from 'pages/Period/PeriodPage';
import PeriodsPage from 'pages/Period/PeriodsPage';
import TimesheetPage from 'pages/Timesheet/TimesheetPage';
import TimesheetsPage from 'pages/Timesheet/TimesheetsPage';
import ClientPage from 'pages/dictionaries/Client/ClientPage';
import ClientsPage from 'pages/dictionaries/Client/ClientsPage';
import ConsultantPage from 'pages/dictionaries/Consultant/ConsultantPage';
import ConsultantsPage from 'pages/dictionaries/Consultant/ConsultantsPage';
import EmployeePage from 'pages/dictionaries/Employee/EmployeePage';
import EmployeesPage from 'pages/dictionaries/Employee/EmployeesPage';
import EmploymentTypePage from 'pages/dictionaries/EmploymentType/EmploymentTypePage';
import EmploymentTypesPage from 'pages/dictionaries/EmploymentType/EmploymentTypesPage';
import IndustryTypePage from 'pages/dictionaries/IndustryType/IndustryTypePage';
import IndustryTypesPage from 'pages/dictionaries/IndustryType/IndustryTypesPage';
import InvoicingFrequenciesPage from 'pages/dictionaries/InvoicingFrequency/InvoicingFrequenciesPage';
import InvoicingFrequencyPage from 'pages/dictionaries/InvoicingFrequency/InvoicingFrequencyPage';
import LocationPage from 'pages/dictionaries/Location/LocationPage';
import LocationsPage from 'pages/dictionaries/Location/LocationsPage';
import PayPeriodPage from 'pages/dictionaries/PayPeriod/PayPeriodPage';
import PayPeriodsPage from 'pages/dictionaries/PayPeriod/PayPeriodsPage';
import PoolPage from 'pages/dictionaries/Pool/PoolPage';
import PoolsPage from 'pages/dictionaries/Pool/PoolsPage';
import PositionPage from 'pages/dictionaries/Position/PositionPage';
import PositionsPage from 'pages/dictionaries/Position/PositionsPage';
import CommissionReportPage from 'pages/reports/CommissionReportPage';
import CustomReportPage from 'pages/reports/CustomReportPage';
import FreeFormReportPage from 'pages/reports/FreeFormReportPage';
import InvoicingReportPage from 'pages/reports/InvoicingReportPage';
import LatestReportsPage from 'pages/reports/LatestReportsPage';
import MissingTimesheetsReportPage from 'pages/reports/MissingTimesheetsReportPage';
import MonthlyIndividualReportPage from 'pages/reports/MonthlyIndividualReportPage';
import PayrollReportPage from 'pages/reports/PayrollReportPage';
import StatusReportPage from 'pages/reports/StatusReportPage';
import WeeklyIndividualReportPage from 'pages/reports/WeeklyIndividualReportPage';
import WeeklyOfficeReportPage from 'pages/reports/WeeklyOfficeReportPage';

const DashboardPage = lazy(() => import('pages/Dashboard/DashboardPage'));
const UsersPage = lazy(() => import('pages/User/UsersPage'));
const UserPage = lazy(() => import('pages/User/UserPage'));
const ActivityLogListPage = lazy(
  () => import('pages/ActivityLog/ActivityLogListPage'),
);
const ActivityLogDetailsPage = lazy(
  () => import('pages/ActivityLog/ActivityLogDetails'),
);
const ReportTemplatesPage = lazy(
  () => import('pages/ReportTemplates/ReportTemplatesPage'),
);

export type RouteType = {
  type?: string;
  name?: string;
  key?: string;
  route?: string;
  title?: string;
  icon?: ReactNode;
  child?: RouteType[];
  component?: ReactElement;
  accessLevels?: number[];
};

const appRoutes: RouteType[] = [
  {
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    icon: <DashboardIcon />,
    component: (
      <>
        <Suspense>
          <DashboardPage />
        </Suspense>
      </>
    ),
  },
  {
    name: 'Periods',
    key: 'periods',
    route: '/periods',
    icon: <DateRangeIcon />,
    component: <PeriodsPage title='Periods' />,
    child: [
      {
        key: 'periods.edit',
        route: '/periods/edit/:id',
        component: (
          <PeriodPage
            title='Edit Period'
            mode='edit'
            sourcePageUrl='/periods'
          />
        ),
      },
      {
        key: 'periods.new',
        route: '/periods/new',
        component: (
          <PeriodPage title='New Period' mode='new' sourcePageUrl='/periods' />
        ),
      },
    ],
  },
  {
    name: 'Timesheets',
    key: 'timesheets',
    route: '/timesheets',
    icon: <ViewTimelineIcon />,
    component: <TimesheetsPage title='Timesheets' />,
    child: [
      {
        key: 'timesheets.edit',
        route: '/timesheets/edit/:id',
        component: (
          <TimesheetPage
            title='Edit timesheet'
            mode='edit'
            sourcePageUrl='/timesheets'
          />
        ),
      },
      {
        key: 'timesheets.new',
        route: '/timesheets/new',
        component: (
          <TimesheetPage
            title='New timesheet'
            mode='new'
            sourcePageUrl='/timesheets'
          />
        ),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Dictionaries',
    key: 'dictionaries',
    icon: <GridViewIcon />,
    accessLevels: [ACCESS_ADMIN, ACCESS_EDITOR],
    child: [
      {
        name: 'Consultants',
        key: 'consultants',
        route: '/consultants',
        component: (
          <>
            <Suspense>
              <ConsultantsPage title='Consultants' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Consultant Edit',
            key: 'consultants.edit',
            route: '/consultants/edit/:id',
            component: (
              <>
                <Suspense>
                  <ConsultantPage
                    title='Edit Consultant'
                    mode='edit'
                    sourcePageUrl='/consultants'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Consultant New',
            key: 'consultants.new',
            route: '/consultants/new',
            component: (
              <>
                <Suspense>
                  <ConsultantPage
                    title='New Consultant'
                    mode='new'
                    sourcePageUrl='/consultants'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Clients',
        key: 'clients',
        route: '/clients',
        component: (
          <>
            <Suspense>
              <ClientsPage title='Clients' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Client Edit',
            key: 'clients.edit',
            route: '/clients/edit/:id',
            component: (
              <>
                <Suspense>
                  <ClientPage
                    title='Edit Client'
                    mode='edit'
                    sourcePageUrl='/clients'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Client New',
            key: 'clients.new',
            route: '/clients/new',
            component: (
              <>
                <Suspense>
                  <ClientPage
                    title='New Client'
                    mode='new'
                    sourcePageUrl='/clients'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Positions',
        key: 'positions',
        route: '/positions',
        component: (
          <>
            <Suspense>
              <PositionsPage title='Positions' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Position Edit',
            key: 'positions.edit',
            route: '/positions/edit/:id',
            component: (
              <>
                <Suspense>
                  <PositionPage
                    title='Edit Position'
                    mode='edit'
                    sourcePageUrl='/positions'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Position New',
            key: 'positions.new',
            route: '/positions/new',
            component: (
              <>
                <Suspense>
                  <PositionPage
                    title='New Position'
                    mode='new'
                    sourcePageUrl='/positions'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Industry Types',
        key: 'industry-types',
        route: '/industry-types',
        component: (
          <>
            <Suspense>
              <IndustryTypesPage title='Industry Types' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Industry Type Edit',
            key: 'industry-types.edit',
            route: '/industry-types/edit/:id',
            component: (
              <>
                <Suspense>
                  <IndustryTypePage
                    title='Edit Industry Type'
                    mode='edit'
                    sourcePageUrl='/industry-types'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Industry Type New',
            key: 'industry-types.new',
            route: '/industry-types/new',
            component: (
              <>
                <Suspense>
                  <IndustryTypePage
                    title='New Industry Type'
                    mode='new'
                    sourcePageUrl='/industry-types'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Locations',
        key: 'locations',
        route: '/locations',
        component: (
          <>
            <Suspense>
              <LocationsPage title='Locations' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Location Edit',
            key: 'locations.edit',
            route: '/locations/edit/:id',
            component: (
              <>
                <Suspense>
                  <LocationPage
                    title='Edit Location'
                    mode='edit'
                    sourcePageUrl='/locations'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Location New',
            key: 'locations.new',
            route: '/locations/new',
            component: (
              <>
                <Suspense>
                  <LocationPage
                    title='New Location'
                    mode='new'
                    sourcePageUrl='/locations'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Employment Types',
        key: 'employment-types',
        route: '/employment-types',
        component: (
          <>
            <Suspense>
              <EmploymentTypesPage title='Employment Types' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Employment Type Edit',
            key: 'employment-types.edit',
            route: '/employment-types/edit/:id',
            component: (
              <>
                <Suspense>
                  <EmploymentTypePage
                    title='Edit Employment Type'
                    mode='edit'
                    sourcePageUrl='/employment-types'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Employment Type New',
            key: 'employment-types.new',
            route: '/employment-types/new',
            component: (
              <>
                <Suspense>
                  <EmploymentTypePage
                    title='New Employment Type'
                    mode='new'
                    sourcePageUrl='/employment-types'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Pay Periods',
        key: 'pay-periods',
        route: '/pay-periods',
        component: (
          <>
            <Suspense>
              <PayPeriodsPage title='Pay Periods' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Pay Period Edit',
            key: 'pay-periods.edit',
            route: '/pay-periods/edit/:id',
            component: (
              <>
                <Suspense>
                  <PayPeriodPage
                    title='Edit Pay Period'
                    mode='edit'
                    sourcePageUrl='/pay-periods'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Pay Period New',
            key: 'pay-periods.new',
            route: '/pay-periods/new',
            component: (
              <>
                <Suspense>
                  <PayPeriodPage
                    title='New Pay Period'
                    mode='new'
                    sourcePageUrl='/pay-periods'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Invoicing Frequencies',
        key: 'invoicing-frequencies',
        route: '/invoicing-frequencies',
        component: (
          <>
            <Suspense>
              <InvoicingFrequenciesPage title='Invoicing Frequencies' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Invoicing Frequency Edit',
            key: 'invoicing-frequencies.edit',
            route: '/invoicing-frequencies/edit/:id',
            component: (
              <>
                <Suspense>
                  <InvoicingFrequencyPage
                    mode='edit'
                    title='Edit Invoicing Frequency'
                    sourcePageUrl='/invoicing-frequencies'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Invoicing Frequency New',
            key: 'invoicing-frequencies.new',
            route: '/invoicing-frequencies/new',
            component: (
              <>
                <Suspense>
                  <InvoicingFrequencyPage
                    mode='new'
                    title='New Invoicing Frequency'
                    sourcePageUrl='/invoicing-frequencies'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Pools',
        key: 'pools',
        route: '/pools',
        component: (
          <>
            <Suspense>
              <PoolsPage title='Pools' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Pool Edit',
            key: 'pools.edit',
            route: '/pools/edit/:id',
            component: (
              <>
                <Suspense>
                  <PoolPage
                    mode='edit'
                    title='Edit Pool'
                    sourcePageUrl='/pools'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Pool New',
            key: 'pools.new',
            route: '/pools/new',
            component: (
              <>
                <Suspense>
                  <PoolPage
                    mode='new'
                    title='New Pool'
                    sourcePageUrl='/pools'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Employees',
        key: 'employees',
        route: '/employees',
        component: (
          <>
            <Suspense>
              <EmployeesPage title='Employees' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Employee Edit',
            key: 'employees.edit',
            route: '/employees/edit/:id',
            component: (
              <>
                <Suspense>
                  <EmployeePage
                    title='Edit Employee'
                    mode='edit'
                    sourcePageUrl='/employees'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'Employee New',
            key: 'employees.new',
            route: '/employees/new',
            component: (
              <>
                <Suspense>
                  <EmployeePage
                    title='New Employee'
                    mode='new'
                    sourcePageUrl='/employees'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Administration',
    key: 'administration',
    icon: <AdminPanelSettingsIcon />,
    accessLevels: [ACCESS_ADMIN],
    child: [
      {
        name: 'Users',
        key: 'users',
        route: '/users',
        component: (
          <>
            <Suspense>
              <UsersPage title='Users' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'User Edit',
            key: 'users.edit',
            route: '/users/edit/:id',
            component: (
              <>
                <Suspense>
                  <UserPage
                    title='Edit User'
                    mode='edit'
                    sourcePageUrl='/users'
                  />
                </Suspense>
              </>
            ),
          },
          {
            name: 'User New',
            key: 'users.new',
            route: '/users/new',
            component: (
              <>
                <Suspense>
                  <UserPage
                    title='New User'
                    mode='new'
                    sourcePageUrl='/users'
                  />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Activity Log',
        key: 'activity-log',
        route: '/activity-log',
        component: (
          <>
            <Suspense>
              <ActivityLogListPage title='Activity Log' />
            </Suspense>
          </>
        ),
        child: [
          {
            name: 'Activity details',
            key: 'activity-log.view',
            route: '/activity-log/view/:id',
            component: (
              <>
                <Suspense>
                  <ActivityLogDetailsPage title='Activity Details' />
                </Suspense>
              </>
            ),
          },
        ],
      },
      {
        name: 'Report Templates',
        key: 'report-templates',
        route: '/report-templates',
        component: (
          <>
            <Suspense>
              <ReportTemplatesPage title='Report Templates' />
            </Suspense>
          </>
        ),
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Reports',
    key: 'reports',
    icon: <AssessmentIcon />,
    accessLevels: [ACCESS_ADMIN, ACCESS_EDITOR, ACCESS_REPORTER],
    child: [
      {
        name: 'Latest reports',
        key: 'latest-reports',
        route: '/latest-reports',
        component: (
          <>
            <Suspense>
              <LatestReportsPage title='Latest Reports' />
            </Suspense>
          </>
        ),
      },
      { type: 'divider', key: 'divider-1' },
      {
        name: 'Status Report',
        key: 'status-report',
        route: '/status-report',
        component: (
          <>
            <Suspense>
              <StatusReportPage />
            </Suspense>
          </>
        ),
      },
      {
        name: 'Free Form Report',
        key: 'free-form-report',
        route: '/free-form-report',
        component: (
          <>
            <Suspense>
              <FreeFormReportPage />
            </Suspense>
          </>
        ),
      },
      {
        name: 'Payroll Report',
        key: 'payroll-report',
        route: '/payroll-report',
        component: (
          <>
            <Suspense>
              <PayrollReportPage />
            </Suspense>
          </>
        ),
      },
      {
        name: 'Invoicing Report',
        key: 'invoicing-report',
        route: '/invoicing-report',
        component: (
          <>
            <Suspense>
              <InvoicingReportPage />
            </Suspense>
          </>
        ),
      },
      {
        name: 'Commission Report',
        key: 'commission-report',
        route: '/commission-report',
        component: (
          <>
            <Suspense>
              <CommissionReportPage />
            </Suspense>
          </>
        ),
      },
      { type: 'divider', key: 'divider-2' },
      {
        name: 'Weekly Individual Report',
        key: 'weekly-individual-report',
        route: '/weekly-individual-report',
        component: (
          <>
            <Suspense>
              <WeeklyIndividualReportPage />
            </Suspense>
          </>
        ),
      },
      {
        name: 'Monthly Individual Report',
        key: 'monthly-individual-report',
        route: '/monthly-individual-report',
        component: (
          <>
            <Suspense>
              <MonthlyIndividualReportPage />
            </Suspense>
          </>
        ),
      },
      {
        name: 'Weekly Office Report',
        key: 'weekly-office-report',
        route: '/weekly-office-report',
        component: (
          <>
            <Suspense>
              <WeeklyOfficeReportPage />
            </Suspense>
          </>
        ),
      },
      { type: 'divider', key: 'divider-3' },
      {
        name: 'Custom Report',
        key: 'custom-report',
        route: '/custom-report',
        component: (
          <>
            <Suspense>
              <CustomReportPage />
            </Suspense>
          </>
        ),
      },
      { type: 'divider', key: 'divider-4' },
      {
        name: 'Missing Timesheets',
        key: 'missing-timesheets-report',
        route: '/missing-timesheets-report',
        component: (
          <>
            <Suspense>
              <MissingTimesheetsReportPage />
            </Suspense>
          </>
        ),
      },
    ],
  },
];

export default appRoutes;
