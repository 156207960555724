/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import payPeriodQueries from 'queries/PayPeriodQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const PayPeriodsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'payPeriodDataTableState',
    addNewEntityPageUrl: '/pay-periods/new',
    formatEditEntityPageUrl(id) {
      return `/pay-periods/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Pay Periods?`;
      }
      return `Are you sure you want to remove selected Pay Period with id ${id} ?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all existing'
      } Pay Periods`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 125px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.PayPeriods,
    importType: ImportType.PayPeriods,
    queriesImpl: payPeriodQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 1 },
      { field: 'notes', headerName: 'Notes', flex: 2 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return <BaseListPage {...properties} />;
};

export default PayPeriodsPage;
