/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Status from 'models/Status';
import BaseModelQueries from './BaseModelQueries';

class StatusQueries extends BaseModelQueries<Status> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new StatusQueries('/statuses');
