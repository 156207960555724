/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Period } from 'models/Period/Period';
import InputWithLabel from 'components/input/InputWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';

type PeriodNotesCardProps = {
  period: Period;
  tabIndex: number;
  updateInputField: (
    fieldName: keyof Period,
  ) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
};

const PeriodNotesCard = ({
  period,
  tabIndex,
  updateInputField,
}: PeriodNotesCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Notes
      </SoftTypography>
      <Grid2 container spacing={1}>
        <Grid2 xs={12} md={6}>
          <InputWithLabel
            label='Payroll Instructions'
            tabIndex={tabIndex}
            value={period.payrollInstructions}
            multiline
            multilineRows={2}
            onChange={updateInputField('payrollInstructions')}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <InputWithLabel
            label='Invoicing Instructions'
            tabIndex={tabIndex}
            value={period.invoicingInstructions}
            multiline
            multilineRows={2}
            onChange={updateInputField('invoicingInstructions')}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <InputWithLabel
            label='Addition Info'
            tabIndex={tabIndex}
            value={period.additionalInfo}
            multiline
            multilineRows={2}
            onChange={updateInputField('additionalInfo')}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <InputWithLabel
            label='Notes'
            tabIndex={tabIndex}
            value={period.notes}
            multiline
            multilineRows={2}
            onChange={updateInputField('notes')}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(
  PeriodNotesCard,
  (before, after) =>
    before.period.notes === after.period.notes &&
    before.period.additionalInfo === after.period.additionalInfo &&
    before.period.payrollInstructions === after.period.payrollInstructions &&
    before.period.invoicingInstructions === after.period.invoicingInstructions,
) as typeof PeriodNotesCard;
