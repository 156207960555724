/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2';
import isEqual from 'lodash/isEqual';
import InputWithLabel from 'components/input/InputWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';
import { TimesheetPayments } from '../getPayments';

type TimesheetPaymentsCardProps = {
  payments: TimesheetPayments;
};

const TimesheetPaymentsCard = ({ payments }: TimesheetPaymentsCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Payments
      </SoftTypography>
      <Grid2 container spacing={1}>
        <Grid2 xs={3} lg={6}>
          <InputWithLabel
            label='Total Bill'
            type='number'
            readOnly
            tabIndex={-1}
            value={payments.totalBill}
          />
        </Grid2>
        <Grid2 xs={3} lg={6}>
          <InputWithLabel
            label='Total Pay'
            type='number'
            readOnly
            tabIndex={-1}
            value={payments.totalPay}
          />
        </Grid2>
        <Grid2 xs={3} lg={6}>
          <InputWithLabel
            label='Burden'
            type='number'
            readOnly
            tabIndex={-1}
            value={payments.burden}
          />
        </Grid2>
        <Grid2 xs={3} lg={6}>
          <InputWithLabel
            label='Discount'
            type='number'
            readOnly
            tabIndex={-1}
            value={payments.discount}
          />
        </Grid2>
        <Grid2 xs={3} lg={6}>
          <InputWithLabel
            label='GP'
            type='number'
            readOnly
            tabIndex={-1}
            value={payments.gm}
          />
        </Grid2>
        <Grid2 xs={3} lg={6}>
          <InputWithLabel
            label='GP %'
            type='number'
            readOnly
            tabIndex={-1}
            value={payments.gmPercent}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(TimesheetPaymentsCard, (before, after) =>
  isEqual(before.payments, after.payments),
) as typeof TimesheetPaymentsCard;
