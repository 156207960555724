/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import Timesheet from 'models/Timesheet/Timesheet';

const validationHelper = new ValidationHelper<Timesheet>(
  Yup.object({
    weekEndingDate: Yup.date().required('Must be present'),
    straightTime: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    overtime: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    holidayTime: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    vacationTime: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    sickTime: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    otBillRate: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    otPayRate: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    fixedBill: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    vendorManagement: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(999.99, 'Must not exceed 999.99'),
    perDiem: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(-99999999.99, 'Must not be less than -99999999.99')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    permFee: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .max(99999999.99, 'Must not exceed 99999999.99')
      .when('period.employmentType.family.id', {
        is: 1, // DH
        then: (schema) =>
          schema.min(-99999999.99, 'Must not be less than -99999999.99'),
        otherwise: (schema) => schema.min(0, 'Must be positive value'),
      }),
    description: Yup.string().optional().max(50, 'Must not exceed 50 chars'),
  }),
);

export default validationHelper;
