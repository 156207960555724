/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import Icon from '@mui/material/Icon';
import { DATE_AND_TIME_FORMAT } from 'util/Formatters';
import { dateFormatter } from 'util/GridFormatters';
import downloadReport from 'util/downloadReport';
import ReportDetails from 'models/reports/ReportDetails';
import reportQueries from 'queries/ReportQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const onDownloadReport = (
  reportId: number,
  setProgressBackdropIsOpen: Dispatch<SetStateAction<boolean>>,
  setNotificationErrorMessage: Dispatch<SetStateAction<string>>,
): string => {
  setTimeout(async () => {
    setProgressBackdropIsOpen(true);
    const reportDetails = await reportQueries.getDetails(reportId);
    if (
      reportDetails &&
      (reportDetails.status === 'READY' || reportDetails.status === 'CONSUMED')
    ) {
      await downloadReport(
        reportId,
        reportDetails.filename,
        setNotificationErrorMessage,
        () => {
          setProgressBackdropIsOpen(false);
        },
      );
    } else {
      setNotificationErrorMessage(`Unable to download this report`);
      setProgressBackdropIsOpen(false);
    }
  }, 0);
  return '';
};

const LatestReportsPage = (props: BasicPageProperties) => {
  const properties: PageProperties = {
    ...props,
    filterComponent: <>&nbsp;</>,
    showEditButton: true,
    editButtonLabel: 'Download',
    editButtonIcon: 'download',
    formatEditEntityPageUrl: onDownloadReport,
    gridBoxHeight: 'calc(100vh - 158px)',
    queriesImpl: reportQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'filename', headerName: 'File Name', width: 200 },
      {
        field: 'initiatedAt',
        headerName: 'Initiated At',
        width: 200,
        valueFormatter: dateFormatter(DATE_AND_TIME_FORMAT),
      },
      {
        field: 'progressPercent',
        headerName: 'Progress percent',
        width: 80,
        valueGetter: (_, row) => {
          return row.progressPercent + '%';
        },
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell(params) {
          const details = params.row as ReportDetails;
          const result = details.status;
          return (
            <Box display='flex' alignItems='center'>
              {result &&
                {
                  READY: <Icon color='success'>done</Icon>,
                  ERROR: <Icon color='error'>error</Icon>,
                  GENERATING: <Icon color='warning'>update</Icon>,
                  CONSUMED: <Icon color='info'>download</Icon>,
                  CLEANED: <Icon color='disabled'>delete</Icon>,
                }[result]}
              &nbsp;
              {result}
            </Box>
          );
        },
        sortable: false,
      },
      {
        field: 'statusDetails',
        headerName: 'Status details',
        width: 250,
        sortable: false,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        width: 180,
        valueFormatter: dateFormatter(DATE_AND_TIME_FORMAT),
      },
    ],
    defaultSort: { field: 'id', sort: 'desc' },
  };

  return <BaseListPage {...properties} />;
};

export default LatestReportsPage;
