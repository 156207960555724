/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ReadOnlyContext from 'context/readonly/ReadOnlyContext';
import { Period } from 'models/Period/Period';
import timesheetQueries from 'queries/TimesheetQueries';
import DataTable from 'components/DataTable/DataTable';
import dataColumns from 'pages/Timesheet/dataColumns';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import SoftTypography from 'softUI/components/SoftTypography';

type PeriodTimesheetsCardProps = {
  period: Period;
  tabIndex: number;
  onAddSpreadOverTimesheets: () => void;
};

const PeriodTimesheetsCard = ({
  period,
  tabIndex,
  onAddSpreadOverTimesheets,
}: PeriodTimesheetsCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftBox display='flex' justifyContent='space-between'>
        <SoftTypography variant='h6' sx={{ pl: 1 }}>
          Timesheets
        </SoftTypography>
        <ReadOnlyContext.Consumer>
          {(readOnlyMode) => (
            <SoftBox>
              {!readOnlyMode &&
                !!period.spreadOver &&
                period.id &&
                period.id > 0 &&
                onAddSpreadOverTimesheets && (
                  <SoftButton
                    size='small'
                    color='info'
                    tabIndex={tabIndex}
                    onClick={onAddSpreadOverTimesheets}
                  >
                    Add SpreadOver Timesheets
                  </SoftButton>
                )}
              {!readOnlyMode && (
                <SoftButton
                  component={Link}
                  to={'/timesheets/new'}
                  state={{ period: period }}
                  tabIndex={tabIndex}
                  size='small'
                  color='info'
                  sx={{ ml: 1 }}
                >
                  Add Timesheet
                </SoftButton>
              )}
            </SoftBox>
          )}
        </ReadOnlyContext.Consumer>
      </SoftBox>
      <Box sx={{ pt: 1, width: '100%', height: 400 }}>
        <DataTable
          formatEditEntityPageUrl={useCallback(
            (id) => `/timesheets/edit/${id}`,
            [],
          )}
          localStorageItemName='periodTimesheetDataTableState'
          gridColumns={dataColumns}
          queriesImpl={timesheetQueries}
          filterForQuery={{ periodIds: [period.id] }}
        />
      </Box>
    </Card>
  );
};

export default PeriodTimesheetsCard;
