/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import isEqual from 'lodash/isEqual';
import { ValidationErrors } from 'util/ValidationHelper';
import { Period } from 'models/Period/Period';
import DateWithLabel from 'components/input/DateWithLabel';
import InputWithLabel from 'components/input/InputWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';

type PeriodRatesCardProps = {
  labels: any;
  availability: any;
  period: Period;
  tabIndex: number;
  errors: ValidationErrors<Period>;
  updateField: (fieldName: keyof Period) => (value: any) => void;
  updateInputField: (
    fieldName: keyof Period,
  ) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
};

function areRatesEqual(a: Period, b: Period) {
  return (
    a.payRateBaseSalary === b.payRateBaseSalary &&
    a.billRatePermFee === b.billRatePermFee &&
    a.otPayRate === b.otPayRate &&
    a.otBillRate === b.otBillRate &&
    a.isBillFixed === b.isBillFixed &&
    a.fixedBill === b.fixedBill &&
    a.vendorManagement === b.vendorManagement &&
    a.perDiem === b.perDiem &&
    a.spreadOver === b.spreadOver &&
    a.guaranteePeriod === b.guaranteePeriod &&
    isEqual(a.guaranteeEndDate, b.guaranteeEndDate)
  );
}

const PeriodRatesCard = ({
  labels,
  availability,
  period,
  tabIndex,
  errors,
  updateInputField,
  updateField,
}: PeriodRatesCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Rates
      </SoftTypography>
      <Grid2 container spacing={1}>
        <Grid2 xs={6}>
          <InputWithLabel
            label={labels?.payRateBaseSalary || 'Pay Rate'}
            tabIndex={tabIndex}
            value={period.payRateBaseSalary}
            defaultValue={0}
            type='number'
            errorText={errors.payRateBaseSalary?.join(';')}
            onChange={updateInputField('payRateBaseSalary')}
          />
        </Grid2>
        <Grid2 xs={6} sx={availability.otPayRate ? {} : { display: 'none' }}>
          <InputWithLabel
            label='OT Pay Rate'
            tabIndex={tabIndex}
            value={period.otPayRate}
            defaultValue={0}
            type='number'
            errorText={errors.otPayRate?.join(';')}
            onChange={updateInputField('otPayRate')}
          />
        </Grid2>
        <Grid2 xs={6}>
          <InputWithLabel
            label={labels.billRatePermFee || 'Bill Rate'}
            value={period.isBillFixed ? 0 : period.billRatePermFee}
            defaultValue={0}
            tabIndex={!period.isBillFixed ? tabIndex : -1}
            type='number'
            disabled={!!period.isBillFixed}
            readOnly={!!period.isBillFixed}
            errorText={errors.billRatePermFee?.join(';')}
            onChange={updateInputField('billRatePermFee')}
          />
        </Grid2>
        <Grid2 xs={6} sx={availability.otBillRate ? {} : { display: 'none' }}>
          <InputWithLabel
            label='OT Bill Rate'
            tabIndex={tabIndex}
            value={period.otBillRate}
            defaultValue={0}
            type='number'
            errorText={errors.otBillRate?.join(';')}
            onChange={updateInputField('otBillRate')}
          />
        </Grid2>
        <Grid2
          xs={6}
          sx={availability.isDirectlyHired ? { display: 'none' } : {}}
        >
          <InputWithLabel
            label='Fixed Bill'
            tabIndex={
              availability.isBillFixed && period.isBillFixed ? tabIndex : -1
            }
            value={
              availability.isBillFixed && period.isBillFixed
                ? period.fixedBill
                : null
            }
            defaultValue={0}
            type='number'
            errorText={errors.fixedBill?.join(';')}
            disabled={!(availability.isBillFixed && period.isBillFixed)}
            readOnly={!(availability.isBillFixed && period.isBillFixed)}
            onChange={updateInputField('fixedBill')}
          />
        </Grid2>
        <Grid2
          xs={6}
          sx={availability.isDirectlyHired ? { display: 'none' } : {}}
        >
          <SwitchWithLabel
            label='Is Bill Fixed?'
            tabIndex={availability.isBillFixed ? tabIndex : -1}
            checked={!!(availability.isBillFixed && period.isBillFixed)}
            disabled={!availability.isBillFixed}
            readOnly={!availability.isBillFixed}
            onChange={updateInputField('isBillFixed')}
          />
        </Grid2>
        <Grid2 xs={6}>
          <InputWithLabel
            label='Fee'
            tabIndex={tabIndex}
            value={period.vendorManagement}
            defaultValue={0}
            type='number'
            errorText={errors.vendorManagement?.join(';')}
            onChange={updateInputField('vendorManagement')}
          />
        </Grid2>
        <Grid2
          xs={6}
          sx={availability.isDirectlyHired ? { display: 'none' } : {}}
        >
          <InputWithLabel
            label='Per diem'
            tabIndex={availability.perDiem ? tabIndex : -1}
            value={availability.perDiem ? period.perDiem : 0}
            defaultValue={0}
            type='number'
            errorText={errors.perDiem?.join(';')}
            disabled={!availability.perDiem}
            readOnly={!availability.perDiem}
            onChange={updateInputField('perDiem')}
          />
        </Grid2>
        <Grid2 xs={6} sx={availability.spreadOver ? {} : { display: 'none' }}>
          <InputWithLabel
            label='Spread Over'
            tabIndex={tabIndex}
            value={period.spreadOver}
            defaultValue={0}
            type='number'
            errorText={errors.spreadOver?.join(';')}
            onChange={updateInputField('spreadOver')}
          />
        </Grid2>
        <Grid2
          xs={12}
          sx={availability.isDirectlyHired ? {} : { display: 'none' }}
        >
          <InputWithLabel
            label='Guarantee Period'
            tabIndex={tabIndex}
            value={period.guaranteePeriod}
            type='number'
            errorText={errors.guaranteePeriod?.join(';')}
            onChange={updateInputField('guaranteePeriod')}
          />
        </Grid2>
        <Grid2
          xs={12}
          sx={availability.isDirectlyHired ? {} : { display: 'none' }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateWithLabel
              label='Guarantee End Date'
              tabIndex={tabIndex}
              value={period.guaranteeEndDate}
              error={errors.guaranteeEndDate?.join(';')}
              onChange={updateField('guaranteeEndDate')}
            />
          </LocalizationProvider>
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(
  PeriodRatesCard,
  (before, after) =>
    areRatesEqual(before.period, after.period) &&
    isEqual(before.labels, after.labels) &&
    isEqual(before.availability, after.availability) &&
    isEqual(before.errors, after.errors) &&
    before.tabIndex === after.tabIndex,
) as typeof PeriodRatesCard;
