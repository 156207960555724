/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import SimpleModel from './SimpleModel';

export function getClientNameWithUid(c: Client) {
  return c.name ? `${c.name}` + (c.uid ? ` (${c.uid})` : '') : undefined;
}

export default interface Client extends SimpleModel {
  uid?: string;
}
