/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Icon from '@mui/material/Icon';
import Location from 'models/Location';
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import locationQueries from 'queries/LocationQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const LocationsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'locationDataTableState',
    addNewEntityPageUrl: '/locations/new',
    formatEditEntityPageUrl(id) {
      return `/locations/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Locations?`;
      }
      return `Are you sure you want to remove selected Location with id ${id} ?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all existing'
      } Locations`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 125px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.Locations,
    importType: ImportType.Locations,
    queriesImpl: locationQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 1 },
      { field: 'abbreviation', headerName: 'Abbreviation', width: 100 },
      {
        field: 'active',
        headerName: 'Active',
        width: 80,
        type: 'boolean',
        renderCell(params) {
          const location = params.row as Location;
          return location.isActive ? (
            <Icon fontSize='medium' color='success'>
              check
            </Icon>
          ) : (
            <Icon fontSize='medium' color='error'>
              close
            </Icon>
          );
        },
      },
      { field: 'notes', headerName: 'Notes', flex: 1 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return <BaseListPage {...properties} />;
};

export default LocationsPage;
