/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Filter from 'models/Filter';

export abstract class PredefinedFilterOptions {
  protected constructor(enumuration: any, defaultOption: any) {
    this.enumeration = enumuration;
    this.defaultOption = defaultOption;
  }

  enumeration: any;
  defaultOption: any;

  abstract buildPredefinedFilter(optionIndex: any): Filter;
}
