/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import consultantQueries from 'queries/ConsultantQueries';
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const ConsultantsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'consultantDataTableState',
    addNewEntityPageUrl: '/consultants/new',
    formatEditEntityPageUrl(id) {
      return `/consultants/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Consultants?`;
      }
      return `Are you sure you want to remove selected Consultant with id ${id} ?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all existing'
      } Consultants`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 125px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.Consultants,
    importType: ImportType.Consultants,
    queriesImpl: consultantQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'firstName', headerName: 'First Name', flex: 2 },
      { field: 'lastName', headerName: 'Last Name', flex: 2 },
      { field: 'payrollId', headerName: 'Payroll ID', flex: 2 },
      { field: 'notes', headerName: 'Notes', flex: 3 },
    ],
    defaultSort: { field: 'firstName', sort: 'asc' },
  };

  return <BaseListPage {...properties} />;
};

export default ConsultantsPage;
