/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ValidationErrors } from 'util/ValidationHelper';
import Timesheet from 'models/Timesheet/Timesheet';
import InputWithLabel from 'components/input/InputWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';

type TimesheetNotesCardProps = {
  timesheet: Timesheet;
  tabIndex: number;
  errors: ValidationErrors<Timesheet>;
  updateInputField: (
    fieldName: keyof Timesheet,
  ) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
};

const TimesheetNotesCard = ({
  timesheet,
  tabIndex,
  errors,
  updateInputField,
}: TimesheetNotesCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Notes
      </SoftTypography>
      <Grid2 container spacing={1}>
        <Grid2 xs={12} md={6}>
          <InputWithLabel
            label='Description'
            multiline
            multilineRows={2}
            tabIndex={tabIndex}
            value={timesheet.description}
            onChange={updateInputField('description')}
            errorText={errors.description?.join(';')}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <InputWithLabel
            label='Notes'
            multiline
            multilineRows={2}
            tabIndex={tabIndex}
            value={timesheet.notes}
            onChange={updateInputField('notes')}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(
  TimesheetNotesCard,
  (before, after) =>
    before.timesheet.notes === after.timesheet.notes &&
    before.timesheet.description === after.timesheet.description &&
    before.tabIndex === after.tabIndex,
) as typeof TimesheetNotesCard;
