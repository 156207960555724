/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import colors from 'softUI/assets/theme/base/colors';

// Soft UI Dashboard PRO React helper functions
//import pxToRem from 'softUI/assets/theme/functions/pxToRem';

const { transparent } = colors;

const select = {
  styleOverrides: {
    select: {
      display: 'grid',
      alignItems: 'center',
      //CAS specific
      //padding: `0 ${pxToRem(12)} !important`,  SoftUI default

      '& .Mui-selected': {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      background: 'none',
      height: 'none',
      minHeight: 'none',
      overflow: 'unset',
    },

    //CAS specific
    /* SoftUI default
     icon: {
      display: "none",
    },*/
  },
};

export default select;
