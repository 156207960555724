/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo, useCallback } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Unstable_Grid2';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import isEqual from 'lodash/isEqual';
import { ValidationErrors } from 'util/ValidationHelper';
import Client, { getClientNameWithUid } from 'models/Client';
import Consultant, {
  getConsultantFullNameWithPayroll,
} from 'models/Consultant';
import Employee, { getEmployeeFullName } from 'models/Employee';
import Location from 'models/Location';
import { Period } from 'models/Period/Period';
import { PeriodEmployee } from 'models/Period/PeriodEmployee';
import { PeriodLocation } from 'models/Period/PeriodLocation';
import clientQueries from 'queries/ClientQueries';
import consultantQueries from 'queries/ConsultantQueries';
import employeeQueries from 'queries/EmployeeQueries';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import industryTypeQueries from 'queries/IndustryTypeQueries';
import invoicingFrequencyQueries from 'queries/InvoicingFrequencyQueries';
import locationQueries from 'queries/LocationQueries';
import payPeriodQueries from 'queries/PayPeriodQueries';
import poolQueries from 'queries/PoolQueries';
import positionQueries from 'queries/PositionQueries';
import statusQueries from 'queries/StatusQueries';
import DateWithLabel from 'components/input/DateWithLabel';
import InputWithLabel from 'components/input/InputWithLabel';
import MultiselectAndPercentWithLabel from 'components/input/MultiselectAndPercentWithLabel';
import { SelectAndPercentEntry } from 'components/input/SelectAndPercent';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';

type PeriodInfoCardProps = {
  availability: any;
  period: Period;
  tabIndex: number;
  errors: ValidationErrors<Period>;
  updateField: (fieldName: keyof Period) => (value: any) => void;
  updateInputField: (
    fieldName: keyof Period,
  ) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
};

function isPeriodInfoEqual(a: Period, b: Period) {
  return (
    a.placementNumber === b.placementNumber &&
    a.poNumber === b.poNumber &&
    a.vendorName === b.vendorName &&
    a.candidateSource === b.candidateSource &&
    a.jobSite === b.jobSite &&
    isEqual(a.startDate, b.startDate) &&
    isEqual(a.endDate, b.endDate) &&
    isEqual(a.status, b.status) &&
    isEqual(a.consultant, b.consultant) &&
    isEqual(a.payPeriod, b.payPeriod) &&
    isEqual(a.employmentType, b.employmentType) &&
    isEqual(a.industryType, b.industryType) &&
    isEqual(a.client, b.client) &&
    isEqual(a.invoicingFrequency, b.invoicingFrequency) &&
    isEqual(a.position, b.position) &&
    isEqual(a.pool, b.pool) &&
    isEqual(a.accountExecutives, b.accountExecutives) &&
    isEqual(a.recruiters, b.recruiters) &&
    isEqual(a.locations, b.locations)
  );
}

const PeriodInfoCard = ({
  availability,
  period,
  tabIndex,
  errors,
  updateInputField,
  updateField,
}: PeriodInfoCardProps) => {
  const consultantNameGetter = useCallback(
    (c: Consultant) => getConsultantFullNameWithPayroll(c) || '',
    [],
  );
  const clientNameGetter = useCallback(
    (c: Client) => getClientNameWithUid(c) || '',
    [],
  );
  const employeeNameGetter = useCallback(
    (e: Employee) => getEmployeeFullName(e) || '',
    [],
  );

  const onUpdateAccountExecutives = updateField('accountExecutives');
  const onUpdateRecruiters = updateField('recruiters');
  const onUpdateLocations = updateField('locations');

  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Period information
      </SoftTypography>
      <Grid2 container spacing={1}>
        <Grid2 xs={6} md={4} lg={3}>
          <InputWithLabel
            label='Placement Number'
            autoFocus={true}
            tabIndex={tabIndex}
            value={period.placementNumber}
            errorText={errors.placementNumber?.join(';')}
            onChange={updateInputField('placementNumber')}
          />
        </Grid2>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid2 xs={6} md={4} lg={3}>
            <DateWithLabel
              label='Start Date'
              tabIndex={tabIndex}
              value={period.startDate}
              error={errors.startDate?.join(';')}
              onChange={updateField('startDate')}
            />
          </Grid2>
          <Grid2 xs={6} md={4} lg={3}>
            <DateWithLabel
              label='End Date'
              tabIndex={tabIndex}
              value={period.endDate}
              error={errors.endDate?.join(';')}
              onChange={updateField('endDate')}
            />
          </Grid2>
        </LocalizationProvider>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Status'
            tabIndex={tabIndex}
            selectedValue={period.status}
            optionsFetcher={statusQueries}
            error={errors.status?.join(';')}
            onChange={updateField('status')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Consultant'
            tabIndex={tabIndex}
            selectedValue={period.consultant}
            optionsFetcher={consultantQueries}
            optionLabelGetter={consultantNameGetter}
            error={errors.consultant?.join(';')}
            onChange={updateField('consultant')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Pay Period'
            tabIndex={availability.payPeriod ? tabIndex : -1}
            selectedValue={availability.payPeriod ? period.payPeriod : null}
            optionsFetcher={payPeriodQueries}
            error={errors.payPeriod?.join(';')}
            disabled={!availability.payPeriod}
            readOnly={!availability.payPeriod}
            onChange={updateField('payPeriod')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Employment Type'
            tabIndex={tabIndex}
            selectedValue={period.employmentType}
            optionsFetcher={employmentTypeQueries}
            error={errors.employmentType?.join(';')}
            onChange={updateField('employmentType')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Industry Type'
            tabIndex={tabIndex}
            selectedValue={period.industryType}
            optionsFetcher={industryTypeQueries}
            onChange={updateField('industryType')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Client'
            tabIndex={tabIndex}
            selectedValue={period.client}
            optionsFetcher={clientQueries}
            error={errors.client?.join(';')}
            optionLabelGetter={clientNameGetter}
            onChange={updateField('client')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Invoicing Frequency'
            tabIndex={tabIndex}
            selectedValue={period.invoicingFrequency}
            optionsFetcher={invoicingFrequencyQueries}
            error={errors.invoicingFrequency?.join(';')}
            onChange={updateField('invoicingFrequency')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <InputWithLabel
            label='PO Number'
            tabIndex={tabIndex}
            value={period.poNumber}
            errorText={errors.poNumber?.join(';')}
            onChange={updateInputField('poNumber')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Position'
            tabIndex={tabIndex}
            selectedValue={period.position}
            optionsFetcher={positionQueries}
            onChange={updateField('position')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <InputWithLabel
            label='Vendor Name'
            tabIndex={availability.vendorName ? tabIndex : -1}
            value={availability.vendorName ? period.vendorName : null}
            errorText={errors.vendorName?.join(';')}
            disabled={!availability.vendorName}
            readOnly={!availability.vendorName}
            onChange={updateInputField('vendorName')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <InputWithLabel
            label='Candidate Source'
            tabIndex={tabIndex}
            value={period.candidateSource}
            errorText={errors.candidateSource?.join(';')}
            onChange={updateInputField('candidateSource')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <InputWithLabel
            label='Job Site'
            tabIndex={tabIndex}
            value={period.jobSite}
            errorText={errors.jobSite?.join(';')}
            onChange={updateInputField('jobSite')}
          />
        </Grid2>
        <Grid2 xs={6} md={4} lg={3}>
          <SelectWithLabel
            label='Pool'
            tabIndex={tabIndex}
            selectedValue={period.pool}
            optionsFetcher={poolQueries}
            error={errors.pool?.join(';')}
            onChange={updateField('pool')}
          />
        </Grid2>
      </Grid2>
      <Grid2 container xs={12} spacing={2}>
        <Grid2 xs={6} lg={4}>
          <MultiselectAndPercentWithLabel
            label='Account Executives'
            tabIndex={tabIndex}
            values={period.accountExecutives?.map((r: PeriodEmployee) => {
              return {
                id: r.id!,
                selected: r.employee,
                percent: r.percents,
              } as SelectAndPercentEntry<Employee>;
            })}
            limitSelection={2}
            selectOptionsFetcher={employeeQueries}
            error={errors.accountExecutives?.join(';')}
            onChange={useCallback(
              (entries: SelectAndPercentEntry<PeriodEmployee>[]) =>
                onUpdateAccountExecutives(
                  entries.map((e) => {
                    return {
                      id: e.id!,
                      employee: e.selected,
                      percents: e.percent,
                    } as PeriodEmployee;
                  }),
                ),
              [onUpdateAccountExecutives],
            )}
            selectItemNameGetter={employeeNameGetter}
          />
        </Grid2>
        <Grid2 xs={6} lg={4}>
          <MultiselectAndPercentWithLabel
            label='Recruiters'
            tabIndex={tabIndex}
            values={period.recruiters?.map((r: PeriodEmployee) => {
              return {
                id: r.id!,
                selected: r.employee,
                percent: r.percents,
              } as SelectAndPercentEntry<Employee>;
            })}
            limitSelection={2}
            selectOptionsFetcher={employeeQueries}
            error={errors.recruiters?.join(';')}
            onChange={useCallback(
              (entries: SelectAndPercentEntry<PeriodEmployee>[]) =>
                onUpdateRecruiters(
                  entries.map((e) => {
                    return {
                      id: e.id!,
                      employee: e.selected,
                      percents: e.percent,
                    } as PeriodEmployee;
                  }),
                ),
              [onUpdateRecruiters],
            )}
            selectItemNameGetter={employeeNameGetter}
          />
        </Grid2>
        <Grid2 xs={6} lg={4}>
          <MultiselectAndPercentWithLabel
            label='Locations'
            tabIndex={tabIndex}
            values={period.locations?.map((r: PeriodLocation) => {
              return {
                id: r.id!,
                selected: r.location,
                percent: r.percents,
              } as SelectAndPercentEntry<Location>;
            })}
            limitSelection={2}
            selectOptionsFetcher={locationQueries}
            error={errors.locations?.join(';')}
            onChange={useCallback(
              (entries: SelectAndPercentEntry<PeriodLocation>[]) =>
                onUpdateLocations(
                  entries.map((e) => {
                    return {
                      id: e.id!,
                      location: e.selected,
                      percents: e.percent,
                    } as PeriodLocation;
                  }),
                ),
              [onUpdateLocations],
            )}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(
  PeriodInfoCard,
  (before, after) =>
    isPeriodInfoEqual(before.period, after.period) &&
    isEqual(before.availability, after.availability) &&
    isEqual(before.errors, after.errors) &&
    before.tabIndex === after.tabIndex,
) as typeof PeriodInfoCard;
