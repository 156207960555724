/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Position from 'models/Position';
import BaseModelQueries from './BaseModelQueries';

class PositionQueries extends BaseModelQueries<Position> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new PositionQueries('/positions');
