/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { Period } from './Period';

const getAvailability = (
  period: Period | undefined,
): {
  otPayRate?: boolean;
  otBillRate?: boolean;
  perDiem?: boolean;
  isBillFixed?: boolean;
  payPeriod?: boolean;
  spreadOver?: boolean;
  vendorName?: boolean;
  isDirectlyHired?: boolean;
} => {
  const employmentType = period?.employmentType;
  if (!employmentType) {
    return {};
  }
  switch (employmentType.family?.id) {
    case 1:
      return {
        otPayRate: false,
        otBillRate: false,
        perDiem: false,
        isBillFixed: false,
        payPeriod: false,
        spreadOver: true,
        vendorName: false,
        isDirectlyHired: true,
      };
    case 2:
      return {
        otPayRate: true,
        otBillRate: true,
        perDiem: false,
        isBillFixed: true,
        payPeriod: true,
        spreadOver: false,
        vendorName: true,
      };
    case 3:
    case 4:
    case 6:
      return {
        otPayRate: true,
        otBillRate: true,
        perDiem: true,
        isBillFixed: true,
        payPeriod: true,
        spreadOver: false,
        vendorName: false,
      };
    case 7:
      return {
        otPayRate: true,
        otBillRate: true,
        perDiem: false,
        isBillFixed: true,
        payPeriod: true,
        spreadOver: false,
        vendorName: false,
      };
    case 5:
      return {
        otPayRate: false,
        otBillRate: true,
        perDiem: true,
        isBillFixed: false,
        payPeriod: true,
        spreadOver: false,
        vendorName: false,
      };
    default:
      return {
        otPayRate: true,
        otBillRate: true,
        perDiem: true,
        isBillFixed: true,
        payPeriod: true,
        spreadOver: false,
        vendorName: false,
      };
  }
};

export default getAvailability;
