/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */

// Soft UI Dashboard PRO React base styles
import boxShadows from 'softUI/assets/theme/base/boxShadows';
import colors from 'softUI/assets/theme/base/colors';
import borders from 'softUI/assets/theme/base/borders';
import typography from 'softUI/assets/theme/base/typography';

const { light, secondary, text, info, white } = colors;
const { borderWidth } = borders;
const { size, fontWeightBold } = typography;
const { inputBoxShadow } = boxShadows;

const datagrid = {
  styleOverrides: {
    columnHeader: {
      borderTop: `none`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
      fontSize: size.xxs,
      fontWeight: fontWeightBold,
      textTransform: `uppercase`,
      width: `max-content`,
      color: secondary.main,
      opacity: 0.7,
    },
    cell: {
      borderTop: `none`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
      fontSize: size.sm,
      width: `max-content`,
      color: text.main,

      '&:has(>.MuiBadge-root)': {
        textOverflow: 'unset',
      }
    },
    selectedRowCount: {
      fontSize: size.sm,
      color: secondary.main,
    },
    menu: {
      minWidth: `inherit`,

      '& .MuiList-root': {
        minWidth: `inherit`,
      },
      '& .MuiTypography-root': {
        fontSize: `${size.sm} !important`,
        color: text.main,
      }
    },
    toolbarContainer: {
      gap: `inherit`
    },
    toolbarQuickFilter: {
      '& .Mui-focused': {
        borderColor: `${info.main}`,
        boxShadow: inputBoxShadow.focus,
      },
    },
    root: {
      border: 0,
      boxShadow: `none`,
      '--DataGrid-containerBackground': white.main,
      '& .MuiTablePagination-toolbar .Mui-focused': {
        borderColor: `${info.main}`,
        boxShadow: inputBoxShadow.focus,
      },
    },
  },
};

export default datagrid;
