/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useCallback } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import { getEmployeeFullName } from 'models/Employee';
import Pool from 'models/Pool';
import employeeQueries from 'queries/EmployeeQueries';
import poolQueries from 'queries/PoolQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import InputWithLabel from 'components/input/InputWithLabel';
import BaseDictionaryPage, { BasicPageProps } from 'pages/BasePage';

const validationHelper = new ValidationHelper<Pool>(
  Yup.object({
    name: Yup.string()
      .required('Must be present')
      .max(20, 'Must not exceed 20 chars'),
  }),
);

const PoolPage = (props: BasicPageProps) => {
  function createEmptyPool(): Pool {
    return { id: -1, name: '', employees: [] };
  }

  const ParameterizedBaseDictionaryPage = BaseDictionaryPage<Pool>;

  return (
    <ParameterizedBaseDictionaryPage
      {...props}
      emptyEditedEntityFactory={createEmptyPool}
      queriesImpl={poolQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Pool?'
      confirmRemovalDialogText='Are you sure you want to delete current Pool?'
      confirmSaveDialogText='Are you sure you want to save changes to current Pool?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Pool?'
      formatEditEntityPageUrl={(id) => `/pools/edit/${id}`}
    >
      {(sectionParams) => {
        const onEmployeesChange = sectionParams.updateInputField('employees');
        return (
          <Card sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item container xs={12} md={4} rowSpacing={2}>
                <Grid item xs={12}>
                  <InputWithLabel
                    label='Name'
                    size='small'
                    autoFocus={true}
                    tabIndex={sectionParams.tabIndexForInputs}
                    value={sectionParams.editedData.name}
                    onChange={sectionParams.updateInputField('name')}
                    errorText={sectionParams.validationErrors.name?.join(';')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputWithLabel
                    label='Notes'
                    size='small'
                    multiline={true}
                    multilineRows={3}
                    tabIndex={sectionParams.tabIndexForInputs}
                    value={sectionParams.editedData.notes}
                    onChange={sectionParams.updateInputField('notes')}
                    errorText={sectionParams.validationErrors.notes?.join(';')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutocompleteWithLabel
                  label='Employees'
                  selectedValues={sectionParams.editedData.employees}
                  optionsFetcher={employeeQueries}
                  onChange={(values) => onEmployeesChange({} as any, values)}
                  optionLabelGetter={useCallback(getEmployeeFullName, [])}
                  limitTags={15}
                  // custom styling to make input inside Autocomplete stretch to container size
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    '.MuiAutocomplete-root': {
                      flexGrow: 1,
                    },
                    '.MuiAutocomplete-root .MuiFormControl-root': {
                      height: '100%',
                      maxHeight: 'inherit',
                    },
                    '.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root':
                      {
                        height: '100% !important',
                        alignContent: 'flex-start',
                      },
                    '.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input':
                      {
                        height: '23px',
                        p: '2px!important',
                      },
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        );
      }}
    </ParameterizedBaseDictionaryPage>
  );
};

export default PoolPage;
