/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortItem,
  GridToolbarContainer,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useReadOnlyContext } from 'context/readonly/ReadOnlyContext';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Sort } from 'queries/BaseFetchingQueries';
import BaseModelQueries from 'queries/BaseModelQueries';
import { BaseWrappedListingQueries } from 'queries/BaseWrappedListingQueries';
import { getErrorMessage } from 'queries/ErrorResponse';
import exportQueries, { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import IFilterContext from 'components/CombinedFilter/context/IFilterContext';
import ConfirmationDialog from 'components/ConfirmationDialog';
import EntityImporter from 'components/EntityImporter';
import ResponsiveButton from 'components/ResponsiveButton';
import SoftBox from 'softUI/components/SoftBox';
import { setCurrentPageTitle, useSoftUIController } from 'softUI/context';

export type BasicPageProperties = {
  title: string;
};

export type PageProperties = BasicPageProperties & {
  gridColumns: GridColDef[];
  defaultSort?: GridSortItem;
  queriesImpl: BaseWrappedListingQueries<any> | BaseModelQueries<any>;
  localStorageItemName?: string;
  useFilterContext?: () => IFilterContext;
  filterComponent?: any;

  showCopyButton?: boolean;
  showAddButton?: boolean;
  showEditButton?: boolean;
  showRemoveButton?: boolean;
  showExportButton?: boolean;
  showImportButton?: boolean;
  checkboxSelection?: boolean;

  gridBoxHeight: string;

  addNewEntityPageUrl?: string;
  formatEditEntityPageUrl?: (
    id: number,
    setProgressBackdropIsOpen: Dispatch<SetStateAction<boolean>>,
    setNotificationErrorMessage: Dispatch<SetStateAction<string>>,
  ) => string;

  editButtonLabel?: string;
  editButtonIcon?: string;
  exportType?: ExportType;
  exportConfirmationDialogText?: string;
  formatRemoveConfirmationDialogText?: (id: number | number[]) => string;
  formatExportConfirmationDialogText?: (ids: number[]) => string;

  importType?: ImportType;
};

const BaseListPage = (props: PageProperties) => {
  const [, dispatch]: any = useSoftUIController();
  useEffect(() => {
    setCurrentPageTitle(dispatch, props.title);
  }, [props.title]);

  const getSavedTableState = () => {
    const item =
      props.localStorageItemName &&
      localStorage.getItem(props.localStorageItemName);
    return item ? JSON.parse(item) : {};
  };
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    () => ({
      pageSize: getSavedTableState().pageSize || 25,
      page: 0,
    }),
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    () =>
      getSavedTableState().sortModel ||
      (props.defaultSort
        ? [props.defaultSort]
        : [{ field: 'id', sort: 'desc' }]),
  );
  const [columnModel, setColumnModel] = useState<GridColumnVisibilityModel>(
    () => getSavedTableState().columnModel || {},
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  useEffect(() => {
    props.localStorageItemName &&
      localStorage.setItem(
        props.localStorageItemName,
        JSON.stringify({
          pageSize: paginationModel.pageSize,
          sortModel: sortModel,
          columnModel: columnModel,
        }),
      );
  }, [paginationModel, sortModel, columnModel]);

  const sortForQuery = useMemo(() => {
    return sortModel.map(
      ({ field, sort }: GridSortItem) =>
        ({
          property: field,
          direction: sort?.toString(),
        }) as Sort,
    );
  }, [sortModel]);

  const searchTermsForQuery = useMemo(() => {
    return filterModel?.quickFilterValues?.join(' ') || '';
  }, [filterModel]);
  const filterContext = props.useFilterContext?.();
  const filterForQuery = useMemo(() => {
    return filterContext ? filterContext.buildFilter() : {};
  }, [filterContext]);

  const getEntitiesQuery = props.queriesImpl.get(
    paginationModel.pageSize,
    paginationModel.page,
    sortForQuery,
    filterForQuery,
    searchTermsForQuery,
  );

  const [progressBackdropIsOpen, setProgressBackdropIsOpen] = useState(false);
  const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

  const navigate = useNavigate();
  const onRowDoubleClick: GridEventListener<'rowDoubleClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    event.defaultMuiPrevented = true;
    if (props.formatEditEntityPageUrl) {
      const selectedId = params.id as number;
      const editPageUrl = props.formatEditEntityPageUrl(
        selectedId,
        setProgressBackdropIsOpen,
        setNotificationErrorMessage,
      );
      navigate(editPageUrl);
    } else if (props.showEditButton) {
      console.error(
        'list page: showEditButton is set, but formatEditEntityPageUrl is missing - misconfiguration?',
      );
    }
  };

  const apiRef = useGridApiRef();
  useEffect(() => {
    return apiRef.current.subscribeEvent('cellMouseUp', onGridCellMouseUp);
  }, [apiRef]);
  const onGridCellMouseUp: GridEventListener<'cellMouseUp'> = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    // If the middle mouse button is clicked.
    if (event.button == 1) {
      event.defaultMuiPrevented = true;
      if (props.formatEditEntityPageUrl) {
        const selectedId = params.row.id as number;
        const editPageUrl = props.formatEditEntityPageUrl(
          selectedId,
          setProgressBackdropIsOpen,
          setNotificationErrorMessage,
        );
        window.open(editPageUrl, '_blank');
      }
    }
  };

  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([]);
  const onRowSelectionModelChange = (selectionModel: GridRowSelectionModel) => {
    setSelectedEntityIds(selectionModel as number[]);
  };
  function getSelectedEntity() {
    return getEntitiesQuery?.data?.data?.find(
      (entity) => entity.id === selectedEntityIds[0],
    );
  }

  const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
    useState(false);
  function onDeleteBtnClick() {
    setConfirmDeleteDialogIsOpen(true);
  }
  const deleteEntityQuery =
    'delete' in props.queriesImpl ? props.queriesImpl.delete(false) : undefined;
  const removeCachedQueries =
    'getRemoveCachedQueriesFunction' in props.queriesImpl
      ? props.queriesImpl.getRemoveCachedQueriesFunction()
      : undefined;
  function onConfirmDeleteDialogResult(isConfirmed: boolean) {
    setConfirmDeleteDialogIsOpen(false);
    if (isConfirmed) {
      setProgressBackdropIsOpen(true);
      setTimeout(async () => {
        try {
          await Promise.all(
            selectedEntityIds.map((id) =>
              deleteEntityQuery ? deleteEntityQuery.mutateAsync(id) : id,
            ),
          );
        } catch (error: any) {
          setNotificationErrorMessage(
            await getErrorMessage(error, 'Removal failed'),
          );
        } finally {
          apiRef.current?.setRowSelectionModel([]);
          removeCachedQueries?.();
          setProgressBackdropIsOpen(false);
        }
      });
    }
  }

  const [confirmExportDialogIsOpen, setConfirmExportDialogIsOpen] =
    useState(false);
  function onExportBtnClick() {
    setConfirmExportDialogIsOpen(true);
  }
  const entitiesToExport = props.checkboxSelection ? selectedEntityIds : [];
  const exportFilter =
    entitiesToExport.length > 0 ? { ids: entitiesToExport } : filterForQuery;
  const exportEntitiesQuery = exportQueries.export(
    props.exportType || ExportType.None,
    exportFilter,
  );
  function onConfirmExportDialogResult(isConfirmed: boolean) {
    setConfirmExportDialogIsOpen(false);
    if (isConfirmed) {
      setProgressBackdropIsOpen(true);
      setTimeout(async () => {
        try {
          await performExport();
        } catch (error: any) {
          setNotificationErrorMessage(
            await getErrorMessage(error, 'Export failed'),
          );
        } finally {
          setProgressBackdropIsOpen(false);
        }
      });
    }
  }
  async function performExport() {
    const response = await exportEntitiesQuery.mutateAsync();
    const currentDate = moment().format('YYYY-MM-DD');
    const fileName = props.exportType + '-' + currentDate + '.xlsx';
    saveAs(response.data as Blob, fileName);
  }

  function handleImportProgressBackdropChange(isOpen: boolean) {
    setProgressBackdropIsOpen(isOpen);
    if (!isOpen) {
      removeCachedQueries?.();
    }
  }

  const readOnlyMode = useReadOnlyContext();
  const customGridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          variant='outlined'
          debounceMs={500}
          autoFocus={true}
          InputProps={{
            size: 'small',
            startAdornment: null,
            endAdornment: null,
          }}
          inputProps={{
            tabIndex: 0,
          }}
        />
      </GridToolbarContainer>
    );
  }, []);

  return (
    <>
      <Card>
        <SoftBox p={3} pb={1.5}>
          {props.filterComponent}
          <SoftBox
            style={{ height: props.gridBoxHeight, width: '100%', zIndex: 1 }}
          >
            <DataGrid
              apiRef={apiRef}
              columns={props.gridColumns}
              columnVisibilityModel={columnModel}
              onColumnVisibilityModelChange={setColumnModel}
              loading={getEntitiesQuery.isFetching}
              rows={
                getEntitiesQuery.isSuccess
                  ? getEntitiesQuery.data.data || []
                  : []
              }
              rowCount={
                getEntitiesQuery.isSuccess
                  ? getEntitiesQuery.data.totalCount || 0
                  : 0
              }
              pagination
              paginationMode='server'
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[25, 50, 100]}
              sortingMode='server'
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              filterMode='server'
              filterModel={filterModel}
              onFilterModelChange={setFilterModel}
              checkboxSelection={props.checkboxSelection}
              keepNonExistentRowsSelected
              onRowSelectionModelChange={onRowSelectionModelChange}
              onRowDoubleClick={onRowDoubleClick}
              disableColumnFilter
              disableDensitySelector
              hideFooterSelectedRowCount={!props.checkboxSelection}
              density='compact'
              slots={
                props.filterComponent
                  ? {}
                  : {
                      toolbar: customGridToolbar,
                    }
              }
              style={{ borderStyle: 'none' }}
            />
          </SoftBox>

          {/* buttons section is displayed "inside grid" (using absolute positioning),
            but it must appear after the grid in dom so that buttons are being focused
            after the grid table according to tab ordering */}
          <SoftBox
            style={{
              position: 'absolute',
              right: '24px',
              top: '26px',
              zIndex: 2,
            }}
          >
            <SoftBox display='flex' justifyContent='end'>
              {!readOnlyMode && !!props.showImportButton && (
                <EntityImporter
                  importType={props.importType!}
                  onProgressBackdropChange={handleImportProgressBackdropChange}
                  tabIndex={0}
                />
              )}
              {!!props.showExportButton && (
                <ResponsiveButton
                  label='Export'
                  color='info'
                  icon='download'
                  onClick={onExportBtnClick}
                  tabIndex={0}
                />
              )}
              {!readOnlyMode && !!props.showCopyButton && (
                <ResponsiveButton
                  label='Copy'
                  color='info'
                  icon='content_copy'
                  disabled={selectedEntityIds.length !== 1}
                  linkTo={props.addNewEntityPageUrl || ''}
                  linkState={{ copyFrom: getSelectedEntity() }}
                  tabIndex={0}
                />
              )}
              {!readOnlyMode && !!props.showAddButton && (
                <ResponsiveButton
                  label='Add'
                  color='info'
                  icon='add'
                  linkTo={props.addNewEntityPageUrl || ''}
                  tabIndex={0}
                />
              )}
              {!!props.showEditButton && (
                <ResponsiveButton
                  label={
                    readOnlyMode ? 'View' : props.editButtonLabel || 'Edit'
                  }
                  color='info'
                  icon={
                    readOnlyMode ? 'visibility' : props.editButtonIcon || 'edit'
                  }
                  disabled={selectedEntityIds.length !== 1}
                  onClick={() => {
                    if (props.formatEditEntityPageUrl) {
                      navigate(
                        props.formatEditEntityPageUrl(
                          selectedEntityIds[0],
                          setProgressBackdropIsOpen,
                          setNotificationErrorMessage,
                        ),
                      );
                    }
                  }}
                  tabIndex={0}
                />
              )}
              {!readOnlyMode && !!props.showRemoveButton && (
                <ResponsiveButton
                  label='Remove'
                  color='primary'
                  icon='delete'
                  disabled={selectedEntityIds.length < 1}
                  onClick={onDeleteBtnClick}
                  tabIndex={0}
                />
              )}
            </SoftBox>
          </SoftBox>

          <ConfirmationDialog
            open={confirmDeleteDialogIsOpen}
            title='Delete'
            description={
              props.formatRemoveConfirmationDialogText
                ? props.formatRemoveConfirmationDialogText(selectedEntityIds)
                : 'Do you confirm delete?'
            }
            resultCallback={onConfirmDeleteDialogResult}
          />
          <ConfirmationDialog
            open={confirmExportDialogIsOpen}
            title='Export'
            description={
              props.formatExportConfirmationDialogText
                ? props.formatExportConfirmationDialogText(selectedEntityIds)
                : props.exportConfirmationDialogText ||
                  'Do you confirm export of all entities?'
            }
            resultCallback={onConfirmExportDialogResult}
          />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={progressBackdropIsOpen}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </SoftBox>
      </Card>
      <Snackbar
        open={!!notificationErrorMessage}
        autoHideDuration={6000}
        onClose={() => setNotificationErrorMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          {notificationErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BaseListPage;
