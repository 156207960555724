/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import * as Yup from 'yup';

export type ValidationErrors<T> = {
  [Property in keyof T]?: string[] | null | undefined;
};

export class ValidationHelper<T> {
  validationSchema: Yup.ObjectSchema<any>;

  constructor(schema: Yup.ObjectSchema<any>) {
    this.validationSchema = schema;
  }

  /**
   * Validates given editedData using configured schema and returns object - errors per each invalid field.
   *
   * @param editedData data to be validated using validationSchema
   * @param fieldName optional, the only field to validate
   * @returns object filled with errors either for single field (param fieldName used) or for all fields
   * if editedData considered invalid
   */
  async validate(
    editedData: T,
    fieldName: keyof T | undefined = undefined,
  ): Promise<ValidationErrors<T>> {
    const newErrors: ValidationErrors<T> = {};
    try {
      if (fieldName) {
        await this.validationSchema.validateAt(
          fieldName as string,
          editedData,
          {
            abortEarly: false,
          },
        );
        newErrors[fieldName] = null; // to reset previous error for this field if any
      } else {
        await this.validationSchema.validate(editedData, { abortEarly: false });
      }
    } catch (error) {
      const validationError = error as Yup.ValidationError;
      if (fieldName) {
        newErrors[fieldName] = validationError.errors;
      } else {
        validationError.inner.forEach((innerError) => {
          const key = innerError.path! as keyof ValidationErrors<T>;
          newErrors[key] = newErrors[key] || [];
          innerError.errors.forEach((er) => newErrors[key]?.push(er));
        });
      }
    }
    return newErrors;
  }

  /**
   * If deltaErrors contains any key, then performs override of corresponding keys in existing state.
   * Otherwise if deltaErrors is empty object (no keys) then resets existing errors state.
   * @param deltaErrors new errors, given keys will override existing errors
   * @param stateUpdater state update function of particular component
   */
  updateValidationErrors(
    deltaErrors: ValidationErrors<T>,
    stateUpdater: (
      mergeFunc: (prev: ValidationErrors<T>) => ValidationErrors<T>,
    ) => void,
  ): void {
    stateUpdater((prev: ValidationErrors<T>) => {
      if (Object.keys(deltaErrors).length > 0) {
        // override particular errors
        return { ...prev, ...deltaErrors };
      }
      return {};
    });
  }
}
