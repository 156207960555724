/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import InvoicingFrequency from 'models/InvoicingFrequency';
import BaseModelQueries from './BaseModelQueries';

class InvoicingFrequencyQueries extends BaseModelQueries<InvoicingFrequency> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new InvoicingFrequencyQueries('/invoicingfrequencies');
