/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { Dispatch, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
import { ValidationHelper } from 'util/ValidationHelper';
import downloadReport from 'util/downloadReport';
import BaseReport from 'models/reports/BaseReport';
import { getErrorMessage } from 'queries/ErrorResponse';
import reportQueries from 'queries/ReportQueries';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import SoftProgress from 'softUI/components/SoftProgress';
import SoftTypography from 'softUI/components/SoftTypography';
import { setCurrentPageTitle, useSoftUIController } from 'softUI/context';

export async function validateParameters<T extends BaseReport>(
  parameters: T,
  validationHelper: ValidationHelper<T>,
  onValidationErrors: Dispatch<any>,
): Promise<boolean> {
  const allFieldsErrors = await validationHelper.validate(parameters);
  if (Object.keys(allFieldsErrors).length > 0) {
    onValidationErrors(allFieldsErrors);
    return false;
  }
  onValidationErrors({});
  return true;
}

export type BaseReportPageProps<T> = {
  title: string;
  parameters: T;
  onValidateParameters?: () => Promise<boolean>;
  disabled?: boolean;
  onInProcess?: Dispatch<boolean>;
  children: any;
};

const BaseReportPage = <T extends BaseReport>(
  props: BaseReportPageProps<T>,
) => {
  const [, dispatch]: any = useSoftUIController();
  useEffect(() => {
    setCurrentPageTitle(dispatch, props.title);
  }, [props.title]);
  const [inProcess, setInProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

  useEffect(() => props.onInProcess?.(inProcess), [inProcess]);

  const handleGenerateBtnClick = async () => {
    if (props.onValidateParameters && !(await props.onValidateParameters())) {
      return;
    }

    setInProgress(true);
    setPercentage(0);
    setStatusMessage('Generating Report...');
    try {
      const reportDetails = await reportQueries.generate(props.parameters);
      pollAndThenDownloadGeneratedReport(reportDetails.id);
    } catch (error: any) {
      setNotificationErrorMessage(
        await getErrorMessage(error, 'Generate report request failed'),
      );
      setInProgress(false);
    }
  };

  const pollAndThenDownloadGeneratedReport = (id: number) => {
    const pollStartTime = Date.now();
    const generateReportMaxTimeout = 600000;

    const timerId = setInterval(async () => {
      if (Date.now() - pollStartTime > generateReportMaxTimeout) {
        clearInterval(timerId);
        setNotificationErrorMessage(
          `Generate report timeout is reached, report is still not ready.`,
        );
        setInProgress(false);
        return;
      }

      try {
        const reportDetails = await reportQueries.getDetails(id);
        if (reportDetails.status === 'GENERATING') {
          setPercentage(reportDetails.progressPercent || 0);
        } else if (
          reportDetails.status === 'READY' ||
          reportDetails.status === 'CONSUMED'
        ) {
          clearInterval(timerId);
          downloadGeneratedReport(id, reportDetails.filename);
        } else if (reportDetails.status === 'ERROR') {
          clearInterval(timerId);
          setNotificationErrorMessage(
            `Generate report task ended with error status. Details: ${reportDetails.statusDetails}`,
          );
          setInProgress(false);
        }
      } catch (error: any) {
        clearInterval(timerId);
        setNotificationErrorMessage(
          await getErrorMessage(
            error,
            'Failed to obtain generated report status',
          ),
        );
        setInProgress(false);
      }
    }, 2000);
  };

  const downloadGeneratedReport = (id: number, fileName: string) => {
    setPercentage(100);
    setStatusMessage('Downloading generated report ...');

    downloadReport(id, fileName, setNotificationErrorMessage, () => {
      setInProgress(false);
    });
  };

  return (
    <>
      <Card>
        <SoftBox p={3}>
          <SoftBox
            sx={({ breakpoints }: any) => ({
              [breakpoints.up('md')]: {
                position: 'absolute',
                right: '24px',
              },
              [breakpoints.down('md')]: {
                paddingBottom: 3,
                display: 'flex',
                justifyContent: 'end',
              },
            })}
          >
            <SoftButton
              size='small'
              variant='contained'
              color='info'
              onClick={handleGenerateBtnClick}
              disabled={props.disabled || inProcess}
              tabIndex={2}
            >
              Generate
            </SoftButton>
          </SoftBox>
          {props.children}
        </SoftBox>
      </Card>
      {inProcess && (
        <Card sx={{ mt: 1 }}>
          <SoftBox p={3}>
            <SoftTypography variant='h6' fontWeight='bold' color='text'>
              {statusMessage}
            </SoftTypography>
            <SoftBox mt={0.5}>
              <SoftProgress value={percentage} label />
            </SoftBox>
          </SoftBox>
        </Card>
      )}
      <Snackbar
        open={!!notificationErrorMessage}
        autoHideDuration={6000}
        onClose={() => setNotificationErrorMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          {notificationErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BaseReportPage;
