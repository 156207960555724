/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import { getErrorMessage } from 'queries/ErrorResponse';
import securityQueries from 'queries/SecurityQueries';
import Form from 'components/input/Form';
import InputWithLabel from 'components/input/InputWithLabel';
import CoverLayout from 'layouts/CoverLayout';
import curved9 from 'softUI/assets/images/curved-images/curved-6.jpg';
import SoftAlert from 'softUI/components/SoftAlert';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import SoftTypography from 'softUI/components/SoftTypography';

type FormFields = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const validationHelper = new ValidationHelper<any>(
  Yup.object({
    oldPassword: Yup.string().required('Must be present'),
    newPassword: Yup.string()
      .required('Must be present')
      .matches(/[0-9]/, 'Should contain at least one digit')
      .matches(/[a-z]/, 'Should contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Should contain at least one uppercase letter')
      .matches(/^\S+$/, 'Should not contain whitespaces')
      .matches(
        /[!@#$%^&*(){};:'"/|\\,.`~\-_[\]]/,
        'Should contain at least one special character: !@#$%^&*(){};:\'"/|,.`~-_[]',
      )
      .min(8, 'Must be not less than 8 chars'),
    confirmPassword: Yup.string().test(
      'matches-to-newPassword',
      'New password value and confirm password value do not match',
      (value, context) => {
        const newPasswordValue = context.parent.newPassword as string;
        return newPasswordValue === value;
      },
    ),
  }),
);

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');

  const userName = location.state?.userName || '';

  const initialData: FormFields = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  async function handleFormSubmit(validatedData: FormFields) {
    try {
      await securityQueries.changePassword({
        username: userName,
        oldPassword: validatedData.oldPassword,
        newPassword: validatedData.newPassword,
      });
      navigateBack();
    } catch (error: any) {
      setErrorMessage(await getErrorMessage(error, 'Change password failed'));
    }
  }

  function navigateBack() {
    if (location.state?.referer) {
      navigate(location.state?.referer);
    } else {
      navigate('/', { replace: true });
    }
  }

  return (
    <CoverLayout
      title='Change Password'
      description='To change your password enter your current and new passwords'
      image={curved9}
    >
      <Form
        initialData={initialData}
        validationHelper={validationHelper}
        onSubmit={handleFormSubmit}
      >
        {(sectionParams) => (
          <SoftBox>
            <SoftBox mb={2}>
              <InputWithLabel
                label='Old Password'
                type='password'
                value={sectionParams.editedData.oldPassword}
                onChange={(_, val) => {
                  sectionParams.updateEditedDataField('oldPassword', val);
                  setErrorMessage('');
                }}
                errorText={sectionParams.validationErrors.oldPassword?.join(
                  ';',
                )}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <InputWithLabel
                label='New Password'
                type='password'
                value={sectionParams.editedData.newPassword}
                onChange={(_, val) => {
                  sectionParams.updateEditedDataField('newPassword', val);
                  setErrorMessage('');
                }}
                errorText={sectionParams.validationErrors.newPassword?.join(
                  '\n',
                )}
                errorTextIsPreformatted={true}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <InputWithLabel
                label='Confirm Password'
                type='password'
                value={sectionParams.editedData.confirmPassword}
                onChange={(_, val) => {
                  sectionParams.updateEditedDataField('confirmPassword', val);
                  setErrorMessage('');
                }}
                errorText={sectionParams.validationErrors.confirmPassword?.join(
                  ';',
                )}
              />
            </SoftBox>
            {!!errorMessage && (
              <SoftAlert color='error'>
                <SoftTypography color='inherit' variant='body2'>
                  {errorMessage}
                </SoftTypography>
              </SoftAlert>
            )}
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant='gradient'
                color='info'
                fullWidth
                onClick={sectionParams.triggerSubmit}
              >
                save
              </SoftButton>
            </SoftBox>
          </SoftBox>
        )}
      </Form>
    </CoverLayout>
  );
};

export default ChangePasswordPage;
