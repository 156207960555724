/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { asUTC } from 'util/Formatters';
import Filter from 'models/Filter';
import { PredefinedFilterOptions } from './PredefinedFilterOptions';

export enum TimesheetFilterOption {
  ALL = 'All',
  PREVIOUS_MONTH = 'Previous Month',
  CURRENT_MONTH = 'Current Month',
  MONTH_LONG = 'Month Long',
  HALF_YEAR_LONG = 'Half Year Long',
  PREVIOUS_YEAR = 'Previous Year',
  CURRENT_YEAR = 'Current Year',
  YEAR_LONG = 'Year Long',
  I_QUARTER = 'I Quarter',
  II_QUARTER = 'II Quarter',
  III_QUARTER = 'III Quarter',
  IV_QUARTER = 'IV Quarter',
  DATED_BY_FUTURE = 'Dated By Future',
}

class TimesheetFilterOptions extends PredefinedFilterOptions {
  constructor() {
    super(TimesheetFilterOption, TimesheetFilterOption.ALL);
  }

  buildPredefinedFilter(
    optionIndex: TimesheetFilterOption | undefined,
  ): Filter {
    const today = new Date();
    switch (optionIndex) {
      case undefined:
      case TimesheetFilterOption.ALL:
        return {};
      case TimesheetFilterOption.PREVIOUS_MONTH:
        return {
          weekEndingDate: {
            start: asUTC(
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
            ),
            end: asUTC(new Date(today.getFullYear(), today.getMonth(), 0)),
          },
        };
      case TimesheetFilterOption.CURRENT_MONTH:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear(), today.getMonth(), 1)),
            end: asUTC(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
          },
        };
      case TimesheetFilterOption.MONTH_LONG:
        return {
          weekEndingDate: {
            start: asUTC(today),
            end: asUTC(
              new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                today.getDate(),
              ),
            ),
          },
        };
      case TimesheetFilterOption.HALF_YEAR_LONG:
        return {
          weekEndingDate: {
            start: asUTC(
              new Date(
                today.getFullYear(),
                today.getMonth() - 6,
                today.getDate(),
              ),
            ),
            end: asUTC(today),
          },
        };
      case TimesheetFilterOption.PREVIOUS_YEAR:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear() - 1, 0, 1)),
            end: asUTC(new Date(today.getFullYear() - 1, 12, 0)),
          },
        };
      case TimesheetFilterOption.CURRENT_YEAR:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear(), 0, 1)),
            end: asUTC(new Date(today.getFullYear(), 12, 0)),
          },
        };
      case TimesheetFilterOption.YEAR_LONG:
        return {
          weekEndingDate: {
            start: asUTC(
              new Date(
                today.getFullYear() - 1,
                today.getMonth(),
                today.getDate(),
              ),
            ),
            end: asUTC(today),
          },
        };
      case TimesheetFilterOption.I_QUARTER:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear(), 0, 1)),
            end: asUTC(new Date(today.getFullYear(), 3, 0)),
          },
        };
      case TimesheetFilterOption.II_QUARTER:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear(), 3, 1)),
            end: asUTC(new Date(today.getFullYear(), 6, 0)),
          },
        };
      case TimesheetFilterOption.III_QUARTER:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear(), 6, 1)),
            end: asUTC(new Date(today.getFullYear(), 9, 0)),
          },
        };
      case TimesheetFilterOption.IV_QUARTER:
        return {
          weekEndingDate: {
            start: asUTC(new Date(today.getFullYear(), 9, 1)),
            end: asUTC(new Date(today.getFullYear(), 12, 0)),
          },
        };
      case TimesheetFilterOption.DATED_BY_FUTURE:
        return { weekEndingDate: { start: asUTC(today) } };
      default:
        throw new Error('Unknown timesheet filter');
    }
  }
}

export default new TimesheetFilterOptions();
