/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { GridColDef } from '@mui/x-data-grid';
import { decimalFormatter, usDateFormatter } from 'util/GridFormatters';
import SoftBadge, { BadgeColor } from 'softUI/components/SoftBadge';

const dataColumns: GridColDef[] = [
  {
    headerName: '#',
    field: 'id',
    width: 70,
    disableReorder: true,
  },
  {
    headerName: 'Status',
    field: 'status.name',
    valueGetter: (_, row) => row.status?.name,
    renderCell: renderStatusBadge,
  },
  {
    headerName: 'Placement Number',
    field: 'placementNumber',
    width: 190,
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    type: 'dateTime',
    width: 110,
    valueFormatter: usDateFormatter(),
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    type: 'dateTime',
    width: 110,
    valueFormatter: usDateFormatter(),
  },
  {
    headerName: 'First Name',
    field: 'consultant.firstName',
    valueGetter: (_, row) => row.consultant.firstName,
  },
  {
    headerName: 'Last Name',
    field: 'consultant.lastName',
    valueGetter: (_, row) => row.consultant.lastName,
  },
  {
    headerName: 'Payroll ID',
    field: 'consultant.payrollId',
    valueGetter: (_, row) => row.consultant.payrollId,
  },
  {
    headerName: 'Client Name',
    field: 'client.name',
    valueGetter: (_, row) => row.client?.name,
    width: 200,
  },
  {
    headerName: 'Client ID',
    field: 'client.uid',
    valueGetter: (_, row) => row.client.uid,
    width: 65,
  },
  {
    headerName: 'Employment Type',
    field: 'employmentType.name',
    valueGetter: (_, row) => row.employmentType?.name,
  },
  {
    headerName: 'AE 1',
    field: 'aeName1',
    sortable: false,
    width: 150,
  },
  {
    headerName: 'AE 2',
    field: 'aeName2',
    sortable: false,
    width: 150,
  },
  {
    headerName: 'Rec 1',
    field: 'recruiterName1',
    sortable: false,
    width: 150,
  },
  {
    headerName: 'Rec 2',
    field: 'recruiterName2',
    sortable: false,
    width: 150,
  },
  {
    headerName: 'Location 1',
    field: 'locationName1',
    sortable: false,
    width: 175,
  },
  {
    headerName: 'Location 2',
    field: 'locationName2',
    sortable: false,
    width: 175,
  },
  {
    headerName: 'Job site',
    field: 'jobSite',
  },
  {
    headerName: 'Industry Type',
    field: 'industryType.name',
    valueGetter: (_, row) => row.industryType?.name,
    width: 175,
  },
  {
    headerName: 'Position',
    field: 'position.name',
    valueGetter: (_, row) => row.position?.name,
  },
  {
    headerName: 'Bill Rate',
    field: 'billRate',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    valueGetter: (_, row) => {
      switch (row.employmentType?.family?.id) {
        case 1:
          return 0;
        default:
          return row.billRatePermFee;
      }
    },
    sortable: false,
    width: 65,
  },
  {
    headerName: 'OT Bill Rate',
    field: 'otBillRate',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Pay Rate',
    field: 'payRate',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    valueGetter: (_, row) => {
      switch (row.employmentType?.family?.id) {
        case 2:
        case 3:
        case 4:
        case 6:
        case 7:
          return row.payRateBaseSalary;
        default:
          return 0;
      }
    },
    sortable: false,
    width: 65,
  },
  {
    headerName: 'OT Pay Rate',
    field: 'otPayRate',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Fee',
    field: 'vendorManagement',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 65,
  },
  {
    headerName: 'Perm Fee',
    field: 'permFee',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    valueGetter: (_, row) => {
      switch (row.employmentType?.family?.id) {
        case 1:
          return row.billRatePermFee;
        default:
          return 0;
      }
    },
    sortable: false,
    width: 95,
  },
  {
    headerName: 'Base Salary',
    field: 'baseSalary',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    valueGetter: (_, row) => {
      switch (row.employmentType?.family?.id) {
        case 1:
        case 5:
          return row.payRateBaseSalary;
        default:
          return 0;
      }
    },
    sortable: false,
    width: 95,
  },
  {
    headerName: 'Spread Over',
    field: 'spreadOver',
    type: 'number',
    width: 95,
  },
  {
    headerName: 'Is Bill Fixed',
    type: 'boolean',
    field: 'isBillFixed',
    width: 30,
  },
  {
    headerName: 'Fixed Bill',
    field: 'fixedBill',
    type: 'number',
    valueFormatter: decimalFormatter(2),
    width: 95,
  },
  {
    headerName: 'Payroll Instructions',
    field: 'payrollInstructions',
    width: 170,
  },
  {
    headerName: 'Invoicing Instructions',
    field: 'invoicingInstructions',
    width: 300,
  },
  {
    headerName: 'PO #',
    field: 'poNumber',
    width: 125,
  },
  {
    headerName: 'Additional Info',
    field: 'additionalInfo',
    width: 300,
  },
  {
    headerName: 'Notes',
    field: 'notes',
    width: 170,
  },
  {
    headerName: 'Candidate Source',
    field: 'candidateSource',
    width: 170,
  },
  {
    headerName: 'Vendor Name',
    field: 'vendorName',
    width: 170,
  },
  {
    headerName: 'Pool',
    field: 'pool.name',
    valueGetter: (_, row) => row.pool?.name,
    width: 80,
  },
];

function renderStatusBadge({ value }: any) {
  const statusToColorMapping = new Map<string, BadgeColor>([
    ['Off Billing', 'error'],
    ['On Billing', 'success'],
    ['Direct Hire', 'info'],
  ]);
  return (
    <SoftBadge
      variant='contained'
      color={statusToColorMapping.get(value) || 'secondary'}
      size='xs'
      badgeContent={value}
      container
    />
  );
}

export default dataColumns;
