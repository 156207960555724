/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Timesheet from './Timesheet';

const getAvailability = (
  timesheet: Timesheet | undefined,
): {
  holidayTime?: boolean;
  vacationTime?: boolean;
  sickTime?: boolean;
  straightTime?: boolean;
  overtime?: boolean;
  otBillRate?: boolean;
  otPayRate?: boolean;
  perDiem?: boolean;
  permFee?: boolean;
  isBillFixed?: boolean;
  vendorManagement?: boolean;
} => {
  const employmentType = timesheet?.period?.employmentType;
  if (!employmentType) {
    return {};
  }
  const basicAvailability = {
    holidayTime: true,
    vacationTime: true,
    sickTime: true,
    straightTime: true,
    overtime: true,
    otBillRate: true,
    otPayRate: true,
    perDiem: true,
    permFee: true,
    isBillFixed: true,
    vendorManagement: true,
  };
  switch (employmentType.family?.id) {
    case 1:
      return {
        ...basicAvailability,
        straightTime: false,
        holidayTime: false,
        vacationTime: false,
        sickTime: false,
        overtime: false,
        otBillRate: false,
        otPayRate: false,
        perDiem: false,
        isBillFixed: false,
        vendorManagement: true,
      };
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return {
        ...basicAvailability,
        holidayTime: true,
        vacationTime: true,
        sickTime: true,
      };
  }
  return basicAvailability;
};

export default getAvailability;
