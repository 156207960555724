/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import { useNavigate } from 'react-router';
import BaseModel from 'models/BaseModel';
import Editor from './Editor';
import { BasePageProps } from './index';

const NewEntityPage = <TModel extends BaseModel>(
  props: BasePageProps<TModel>,
) => {
  const navigate = useNavigate();

  const [initialEntity] = useState(props.emptyEditedEntityFactory());

  function navigateToSourcePage() {
    navigate(props.sourcePageUrl);
  }

  function navigateToEditPage(id: number) {
    if (props.formatEditEntityPageUrl && !isNaN(id)) {
      navigate(props.formatEditEntityPageUrl(id));
    }
  }

  return (
    <Editor
      data={initialEntity}
      queriesImpl={props.queriesImpl}
      saveMode='create'
      validationHelper={props.validationHelper}
      showRemoveButton={false}
      showResetButton={false}
      showSaveButton={props.showSaveButton}
      onCloseHandler={navigateToSourcePage}
      onAfterCreateHandler={(id) => navigateToEditPage(id)}
      renderInputFieldsSection={props.children}
      getAvailability={props.getAvailability}
      getLabels={props.getLabels}
      confirmCloseDialogText={props.confirmCloseDialogText}
      confirmRemovalDialogText={props.confirmRemovalDialogText}
      confirmSaveDialogText={props.confirmSaveDialogText}
      confirmResetDialogText={props.confirmResetDialogText}
      allowEmptySave={props.allowEmptySave}
    />
  );
};

export default NewEntityPage;
