/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import AccessLevelReadOnlyContextProvider from 'context/readonly/AccessLevelReadOnlyContextProvider';
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import periodQueries from 'queries/PeriodQueries';
import CombinedFilter from 'components/CombinedFilter';
import periodFilterOptions from 'components/CombinedFilter/PredefinedFilter/options/periodFilterOptions';
import { usePeriodFilterContext } from 'components/CombinedFilter/context/PeriodFilterContext';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';
import dataColumns from './dataColumns';

const PeriodsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    useFilterContext: usePeriodFilterContext,
    filterComponent: (
      <CombinedFilter
        predefinedFilterOptions={periodFilterOptions}
        filterContext={usePeriodFilterContext}
      />
    ),
    localStorageItemName: 'periodDataTableState',
    addNewEntityPageUrl: '/periods/new',
    formatEditEntityPageUrl(id) {
      return `/periods/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Periods?`;
      }
      return `Are you sure you want to remove selected Period with id ${id}?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all found'
      } Periods`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 171px)',
    showCopyButton: true,
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.Periods,
    importType: ImportType.Periods,
    queriesImpl: periodQueries,
    gridColumns: dataColumns,
    defaultSort: { field: 'id', sort: 'desc' },
  };

  return (
    <AccessLevelReadOnlyContextProvider>
      <BaseListPage {...properties} />
    </AccessLevelReadOnlyContextProvider>
  );
};

export default PeriodsPage;
