/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Client from 'models/Client';
import BaseModelQueries from './BaseModelQueries';

class ClientQueries extends BaseModelQueries<Client> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new ClientQueries('/clients');
