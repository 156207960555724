/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import Client from 'models/Client';
import clientQueries from 'queries/ClientQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import BasePage, { BasicPageProps } from 'pages/BasePage';

const validationHelper = new ValidationHelper<Client>(
  Yup.object({
    name: Yup.string()
      .required('Must be present')
      .max(50, 'Must not exceed 50 chars'),
    uid: Yup.string().max(5, 'Must not exceed 5 chars').nullable(),
  }),
);

const ClientPage = (props: BasicPageProps) => {
  function createEmptyClient(): Client {
    return { id: -1, name: '' };
  }

  const ParameterizedBasePage = BasePage<Client>;

  return (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyClient}
      queriesImpl={clientQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Client?'
      confirmRemovalDialogText='Are you sure you want to delete current Client?'
      confirmSaveDialogText='Are you sure you want to save changes to current Client?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Client?'
      formatEditEntityPageUrl={(id) => `/clients/edit/${id}`}
    >
      {(sectionParams) => (
        <Card sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputWithLabel
                label='Name'
                size='small'
                autoFocus={true}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.name}
                onChange={sectionParams.updateInputField('name')}
                errorText={sectionParams.validationErrors.name?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputWithLabel
                label='Unique ID'
                size='small'
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.uid}
                onChange={sectionParams.updateInputField('uid')}
                errorText={sectionParams.validationErrors.uid?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputWithLabel
                label='Notes'
                size='small'
                multiline={true}
                multilineRows={3}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.notes}
                onChange={sectionParams.updateInputField('notes')}
                errorText={sectionParams.validationErrors.notes?.join(';')}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </ParameterizedBasePage>
  );
};

export default ClientPage;
