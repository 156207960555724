/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import BaseModel from 'models/BaseModel';
import { BaseFetchingQueries, Sort } from './BaseFetchingQueries';
import {
  BaseWrappedListingQueries,
  DataWrapper,
} from './BaseWrappedListingQueries';
import http from './HttpClient';

abstract class BaseModelQueries<
    REQ extends BaseModel,
    RES extends BaseModel = REQ,
  >
  implements BaseFetchingQueries<RES>, BaseWrappedListingQueries<RES>
{
  url: string;
  queryKey: string;
  defaultSort: Sort[];

  protected constructor(url: string, defaultSort?: Sort[]) {
    this.url = url;
    this.queryKey = url.startsWith('/') ? url.slice(1) : url;
    this.defaultSort = defaultSort || [];
  }

  getAll(
    onHold = true,
    sort: Sort[] = this.defaultSort,
  ): UseQueryResult<RES[]> {
    const url = this.url;
    return useQuery({
      queryKey: [this.queryKey, { sort: sort }],
      queryFn: async () => {
        const response = await http.get<DataWrapper<RES[]>>(url, {
          params: {
            sort: JSON.stringify(sort),
          },
        });
        return response.data;
      },
      select: (data) => data.data,
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      placeholderData: keepPreviousData,
      enabled: !onHold,
    });
  }

  get(
    pageSize = -1,
    page = 0,
    sort = this.defaultSort,
    filter = {},
    searchTerms = '',
    options = {},
  ): UseQueryResult<DataWrapper<RES[]>> {
    const url = this.url;
    return useQuery({
      queryKey: [
        this.queryKey,
        {
          pageSize: pageSize,
          page: page,
          sort: sort,
          filter: filter,
          searchTerms: searchTerms,
        },
      ],
      queryFn: async () => {
        const response = await http.get<DataWrapper<RES[]>>(url, {
          params: {
            start: pageSize * page,
            limit: pageSize,
            sort: JSON.stringify(sort),
            filter: JSON.stringify(filter),
            query: searchTerms,
          },
        });
        return response.data;
      },
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      placeholderData: keepPreviousData,
      ...options,
    });
  }

  getById(id: number, onHold = false): UseQueryResult<RES> {
    const url = this.url;
    return useQuery({
      queryKey: [this.queryKey, { id: id }],
      queryFn: async () => {
        const response = await http.get<DataWrapper<RES>>(`${url}/${id}`);
        return response.data;
      },
      select: (data) => data.data,
      enabled: !onHold,
    });
  }

  update(): UseMutationResult<any, any, REQ, any> {
    const url = this.url;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (model: REQ) => {
        return http.put(`${url}/${model.id}`, model);
      },
      onSuccess: () => queryClient.removeQueries({ queryKey: [this.queryKey] }),
    });
  }

  create(): UseMutationResult<any, any, REQ, any> {
    const url = this.url;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (model: REQ) => {
        return http.post(url, model);
      },
      onSuccess: () => queryClient.removeQueries({ queryKey: [this.queryKey] }),
    });
  }

  delete(
    removeCachedQueriesOnSuccess = true,
  ): UseMutationResult<any, any, number, any> {
    const url = this.url;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (modelId: number) => {
        return http.delete(`${url}/${modelId}`);
      },

      onSuccess: () =>
        removeCachedQueriesOnSuccess &&
        queryClient.removeQueries({ queryKey: [this.queryKey] }),
    });
  }

  getRemoveCachedQueriesFunction() {
    const queryClient = useQueryClient();
    return () => {
      return queryClient.removeQueries({
        queryKey: [this.queryKey],
      });
    };
  }

  toRequest(model: RES): REQ | RES {
    return model;
  }
}

export default BaseModelQueries;
