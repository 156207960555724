/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';

type BaseLabeledComponentProps = {
  label?: string;
  error?: string;
  errorIsPreformatted?: boolean;
  children: any;
  sx?: SxProps<Theme>;
};

export function prefixWithLabel(label: string, subject: string) {
  return `${label.toLowerCase().replaceAll(' ', '-')}-${subject}`;
}

const BaseLabeledComponent = (props: BaseLabeledComponentProps) => {
  const additionalStylesForError: CSSProperties = props.errorIsPreformatted
    ? {
        whiteSpace: 'pre-wrap',
      }
    : {};
  return (
    <SoftBox sx={props.sx}>
      {props.label && (
        <SoftBox ml={0.5}>
          <SoftTypography fontWeight='medium' variant='caption' noWrap>
            {props.label}
          </SoftTypography>
        </SoftBox>
      )}
      {props.children}
      {props.error && (
        <FormHelperText error>
          <span style={additionalStylesForError}>{props.error || ''}</span>
        </FormHelperText>
      )}
    </SoftBox>
  );
};

export default BaseLabeledComponent;
