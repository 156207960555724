/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import BaseModel from './BaseModel';

export function getEmployeeFullName(e: Employee) {
  return e.firstName ? `${e.firstName} ${e.lastName}` : undefined;
}

export default interface Employee extends BaseModel {
  firstName: string;
  lastName: string;
  isActive: boolean;
  office?: string;
  notes?: string;
}
