/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { CssBaseline, ThemeProvider } from '@mui/material';
import AuthContextProvider from 'context/security/AuthContextProvider';
import RootRoutes from 'routes/RootRoutes';
import PeriodFilterProvider from 'components/CombinedFilter/context/PeriodFilterProvider';
import TimesheetFilterProvider from 'components/CombinedFilter/context/TimesheetFilterProvider';
import theme from 'softUI/assets/theme';

function App() {
  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <PeriodFilterProvider>
          <TimesheetFilterProvider>
            <CssBaseline />
            <RootRoutes />
          </TimesheetFilterProvider>
        </PeriodFilterProvider>
      </ThemeProvider>
    </AuthContextProvider>
  );
}

export default App;
