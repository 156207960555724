/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { AxiosError } from 'axios';

export async function getErrorMessage(
  error: AxiosError,
  messagePrefix: string,
) {
  let errorResponse = error.response?.data as any;
  // Handle the case when a BLOB requested, but an error (with JSON) is returned.
  if (
    error.request.responseType == 'blob' &&
    errorResponse instanceof Blob &&
    errorResponse.type &&
    errorResponse.type.toLowerCase().indexOf('json') != -1
  ) {
    errorResponse = JSON.parse(await errorResponse.text());
  }

  let errorMessage = `${messagePrefix}: ${errorResponse?.message || error.message}`;
  errorResponse?.fieldErrors?.forEach((fe: FieldError) => {
    errorMessage += '\n';
    if (fe.field) {
      errorMessage += fe.field + ': ';
    }
    errorMessage += fe.message;
  });
  return errorMessage;
}

export interface FieldError {
  field: string;
  message: string;
}

export default interface ErrorResponse {
  success: boolean;
  message: string;
  status?: number;
  fieldErrors?: FieldError[];
}
