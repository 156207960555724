/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { createContext, useContext } from 'react';

const ReadOnlyContext = createContext(false);
export default ReadOnlyContext;

export function useReadOnlyContext(): boolean {
  return useContext(ReadOnlyContext);
}
