/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { asUTC } from 'util/Formatters';
import Filter from 'models/Filter';
import { PredefinedFilterOptions } from './PredefinedFilterOptions';

export enum PeriodFilterOption {
  ALL = 'All',
  ON_BILLING = 'On Billing',
  OFF_BILLING = 'Off Billing',
  DH_ONLY = 'DH Only',
  IC_ONLY = 'IC Only',
  IC_ON_BILLING = 'IC On Billing',
  IC_OFF_BILLING = 'IC Off Billing',
  CURRENT_YEAR = 'Current Year',
  PREVIOUS_YEAR = 'Previous Year',
  LAST_3_YEARS = 'Last 3 Years',
  LAST_5_YEARS = 'Last 5 Years',
  WITHOUT_ANY_TIMESHEET = 'Without Any Timesheet',
}

class PeriodFilterOptions extends PredefinedFilterOptions {
  constructor() {
    super(PeriodFilterOption, PeriodFilterOption.ALL);
  }

  buildPredefinedFilter(optionIndex: PeriodFilterOption | undefined): Filter {
    const today = new Date();
    const yearStart = new Date(today.getFullYear(), 0, 1);
    const yearEnd = new Date(today.getFullYear(), 12, 0);
    switch (optionIndex) {
      case undefined:
        return {};
      case PeriodFilterOption.ALL:
        return {};
      case PeriodFilterOption.ON_BILLING:
        return { statusIds: [2] };
      case PeriodFilterOption.OFF_BILLING:
        return { statusIds: [1] };
      case PeriodFilterOption.DH_ONLY:
        return { statusIds: [3] };
      case PeriodFilterOption.IC_ONLY:
        return { employmentTypeFamilyIds: [2] };
      case PeriodFilterOption.IC_ON_BILLING:
        return { employmentTypeFamilyIds: [2], statusIds: [2] };
      case PeriodFilterOption.IC_OFF_BILLING:
        return { employmentTypeFamilyIds: [2], statusIds: [1] };
      case PeriodFilterOption.CURRENT_YEAR:
        return {
          startDate: { start: asUTC(yearStart), end: asUTC(yearEnd) },
        };
      case PeriodFilterOption.PREVIOUS_YEAR:
        yearStart.setFullYear(yearStart.getFullYear() - 1);
        yearEnd.setFullYear(yearEnd.getFullYear() - 1);
        return {
          startDate: { start: asUTC(yearStart), end: asUTC(yearEnd) },
        };
      case PeriodFilterOption.LAST_3_YEARS: {
        const yearsAgo = new Date();
        yearsAgo.setFullYear(today.getFullYear() - 3);
        return { startDate: { start: asUTC(yearsAgo), end: asUTC(today) } };
      }
      case PeriodFilterOption.LAST_5_YEARS: {
        const yearsAgo = new Date();
        yearsAgo.setFullYear(today.getFullYear() - 5);
        return { startDate: { start: asUTC(yearsAgo), end: asUTC(today) } };
      }
      case PeriodFilterOption.WITHOUT_ANY_TIMESHEET:
        return { withoutAnyTimesheet: true };
      default:
        throw new Error('Unknown periods filter');
    }
  }
}

export default new PeriodFilterOptions();
