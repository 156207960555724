/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { HTMLInputTypeAttribute } from 'react';
import { getClientNameWithUid } from 'models/Client';
import { getConsultantFullNameWithPayroll } from 'models/Consultant';
import { getEmployeeFullName } from 'models/Employee';
import { BaseFetchingQueries } from 'queries/BaseFetchingQueries';
import clientQueries from 'queries/ClientQueries';
import consultantQueries from 'queries/ConsultantQueries';
import employeeQueries from 'queries/EmployeeQueries';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import locationQueries from 'queries/LocationQueries';
import positionQueries from 'queries/PositionQueries';
import statusQueries from 'queries/StatusQueries';
import IFilterContext from '../context/IFilterContext';

export type FilterSelectType = {
  label: string;
  optionQueries?: BaseFetchingQueries<any>;
  optionLabelGetter?: (model: any) => string | undefined;
  setItems?: (items: any[] | undefined) => void;
  setIDs?: (ids: number[] | undefined) => void;
  items?: () => any[] | undefined;
  ids?: () => number[] | undefined;
  isReadOnly?: () => boolean;
  hasSelectAll?: boolean;
  inputType?: HTMLInputTypeAttribute;
};

export type FilterBreakType = {
  breakLine: any;
};

export const useFiltersList = (
  filterContext: IFilterContext,
): (FilterSelectType | FilterBreakType)[] => {
  const commonFilters = [
    {
      label: 'Statuses',
      optionQueries: statusQueries,
      setIDs: (ids: number[] | undefined) => filterContext.setStatuses(ids),
      ids: filterContext.statuses,
      isReadOnly: () => filterContext.isStatusReadOnly?.(),
    },
    {
      breakLine: true,
    },
    {
      label: 'Consultants',
      optionQueries: consultantQueries,
      optionLabelGetter: getConsultantFullNameWithPayroll,
      setIDs: (ids: number[] | undefined) => filterContext.setConsultants(ids),
      ids: filterContext.consultants,
    },
    {
      label: 'Clients',
      optionQueries: clientQueries,
      optionLabelGetter: getClientNameWithUid,
      setIDs: (ids: number[] | undefined) => filterContext.setClients(ids),
      ids: filterContext.clients,
    },
    {
      label: 'Account Executives',
      optionQueries: employeeQueries,
      optionLabelGetter: getEmployeeFullName,
      setIDs: (ids: number[] | undefined) =>
        filterContext.setAccountExecutives(ids),
      ids: filterContext.accountExecutives,
    },
    {
      label: 'Recruiters',
      optionQueries: employeeQueries,
      optionLabelGetter: getEmployeeFullName,
      setIDs: (ids: number[] | undefined) => filterContext.setRecruiters(ids),
      ids: filterContext.recruiters,
    },
    {
      label: 'Employment Types',
      optionQueries: employmentTypeQueries,
      setIDs: (ids: number[] | undefined) =>
        filterContext.setEmploymentTypes(ids),
      ids: filterContext.employmentTypes,
    },
    {
      label: 'Locations',
      optionQueries: locationQueries,
      setIDs: (ids: number[] | undefined) => filterContext.setLocations(ids),
      ids: filterContext.locations,
    },
    {
      label: 'Placement Numbers',
      setItems: (items: string[] | undefined) =>
        filterContext.setPlacementNumbers(items),
      items: filterContext.placementNumbers,
      hasSelectAll: false,
    },
  ] as (FilterSelectType | FilterBreakType)[];
  if (filterContext.filterSubject === 'periods') {
    commonFilters.push({
      label: 'Positions',
      optionQueries: positionQueries,
      setIDs: (ids: number[] | undefined) => filterContext.setPositions(ids),
      ids: filterContext.positions,
    });
  }
  if (filterContext.filterSubject === 'timesheets') {
    commonFilters.push({
      label: 'Period IDs',
      setItems: (items: number[] | undefined) =>
        filterContext.setPeriods(items),
      items: filterContext.periods,
      hasSelectAll: false,
      inputType: 'number',
    });
  }
  return commonFilters;
};
