/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';
import BaseModel from 'models/BaseModel';
import FreeFormReport from 'models/reports/FreeFormReport';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import BaseReportPage, { validateParameters } from './BaseReportPage';
import DatesForReportParameters from './DatesForReportParameters';
import TimesheetsFilterForReportParameters from './TimesheetsFilterForReportParameters';

interface ColumnDef extends BaseModel {
  name: string;
  fieldName: string;
}

const columns = (
  [
    {
      name: 'Week Ending Date',
      fieldName: 'weekEndingDate',
    },
    {
      name: 'ClientID',
      fieldName: 'clientId',
    },
    {
      name: 'Client Name',
      fieldName: 'clientName',
    },
    {
      name: 'Employment Type',
      fieldName: 'employmentType',
    },
    {
      name: 'Payroll ID',
      fieldName: 'payrollId',
    },
    {
      name: 'Pay Period',
      fieldName: 'payPeriod',
    },
    {
      name: 'Invoicing Frequency',
      fieldName: 'invoicingFrequency',
    },
    {
      name: 'Consultant First Name',
      fieldName: 'consultantFirstName',
    },
    {
      name: 'Consultant Last Name',
      fieldName: 'consultantLastName',
    },
    {
      name: 'Straight Time',
      fieldName: 'straightTime',
    },
    {
      name: 'Overtime',
      fieldName: 'overtime',
    },
    {
      name: 'Holiday Time',
      fieldName: 'holidayTime',
    },
    {
      name: 'Vacation Time',
      fieldName: 'vacationTime',
    },
    {
      name: 'Sick Time',
      fieldName: 'sickTime',
    },
    {
      name: 'Pay Rate',
      fieldName: 'payRate',
    },
    {
      name: 'OT Pay Rate',
      fieldName: 'otPayRate',
    },
    {
      name: 'Bill Rate',
      fieldName: 'billRate',
    },
    {
      name: 'OT BillRate',
      fieldName: 'otBillRate',
    },
    {
      name: 'Br %',
      fieldName: 'burdenPercent',
    },
    {
      name: 'Vendor Management',
      fieldName: 'vendorManagement',
    },
    {
      name: 'Discount',
      fieldName: 'discount',
    },
    {
      name: 'Per Diem',
      fieldName: 'perDiem',
    },
    {
      name: 'Total Bill',
      fieldName: 'totalBill',
    },
    {
      name: 'Total Pay',
      fieldName: 'totalPay',
    },
    {
      name: 'Burden',
      fieldName: 'burden',
    },
    {
      name: 'GC',
      fieldName: 'gc',
    },
    {
      name: 'GM Percent',
      fieldName: 'gmPercent',
    },
    {
      name: 'Account Executive Name',
      fieldName: 'period.accountExecutive.fullName',
    },
    {
      name: 'Recruiter Name',
      fieldName: 'period.recruiter.fullName',
    },
    {
      name: 'Location',
      fieldName: 'period.location.name',
    },
    {
      name: 'Job Site',
      fieldName: 'jobSite',
    },
    {
      name: 'Industry Type',
      fieldName: 'industryType',
    },
    {
      name: 'Position',
      fieldName: 'position',
    },
    {
      name: 'Vendor Name',
      fieldName: 'vendorName',
    },
    {
      name: 'Candidate Source',
      fieldName: 'candidateSource',
    },
    {
      name: 'Description',
      fieldName: 'description',
    },
    {
      name: 'Adjustments',
      fieldName: 'adjustments',
    },
    {
      name: 'Placement Number',
      fieldName: 'placementNumber',
    },
    {
      name: 'Placement Start Date',
      fieldName: 'placementStartDate',
    },
    {
      name: 'Placement End Date',
      fieldName: 'placementEndDate',
    },
    {
      name: 'Start Date',
      fieldName: 'startDate',
    },
    {
      name: 'End Date',
      fieldName: 'endDate',
    },
    {
      name: 'Status',
      fieldName: 'status',
    },
    {
      name: 'Guarantee Period',
      fieldName: 'guaranteePeriod',
    },
    {
      name: 'Guarantee End Date',
      fieldName: 'guaranteeEndDate',
    },
    {
      name: 'Payroll Instructions',
      fieldName: 'payrollInstructions',
    },
    {
      name: 'Invoicing Instructions',
      fieldName: 'invoicingInstructions',
    },
    {
      name: 'Additional Info',
      fieldName: 'additionalInfo',
    },
    {
      name: 'Notes',
      fieldName: 'notes',
    },
  ] as ColumnDef[]
).map((value, index) => {
  value.id = index;
  return value;
});

const validationHelper = new ValidationHelper<FreeFormReport>(
  Yup.object({
    columnNames: Yup.array().required('At least one Column must be present'),
  }),
);

const FreeFormReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'FreeForm',
  } as FreeFormReport);
  const [validationErrors, setValidationErrors] = useState(
    {} as ValidationErrors<FreeFormReport>,
  );
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Free Form Report'
      parameters={parameters}
      onValidateParameters={() =>
        validateParameters(parameters, validationHelper, setValidationErrors)
      }
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          validationErrors={validationErrors}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pb={3}>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Columns'
              tabIndex={1}
              optionsFetcher={() => ({
                isSuccess: true,
                data: columns,
              })}
              optionLabelGetter={(columnDef) => columnDef.name}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  columnNames: ids?.map((id) => columns[id].fieldName) || [],
                });
              }}
              disabled={disabled}
              error={validationErrors?.columnNames?.join(';')}
            />
          </Grid>
        </Grid>
        <TimesheetsFilterForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          validationErrors={validationErrors}
          disabled={disabled}
          tabIndexForInputs={1}
        />
      </Grid>
    </BaseReportPage>
  );
};

export default FreeFormReportPage;
