/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { ExportType } from 'queries/ExportQueries';
import { ImportType } from 'queries/ImportQueries';
import industryTypeQueries from 'queries/IndustryTypeQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage/BaseListPage';

const IndustryTypesPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'industryTypeDataTableState',
    addNewEntityPageUrl: '/industry-types/new',
    formatEditEntityPageUrl(id) {
      return `/industry-types/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Industry Types?`;
      }
      return `Are you sure you want to remove selected Industry Type with id ${id} ?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all existing'
      } Industry Types`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 125px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportType: ExportType.IndustryTypes,
    importType: ImportType.IndustryTypes,
    queriesImpl: industryTypeQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 2 },
      { field: 'notes', headerName: 'Notes', flex: 1 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return <BaseListPage {...properties} />;
};

export default IndustryTypesPage;
