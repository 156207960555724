/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import IndustryType from 'models/IndustryType';
import BaseModelQueries from './BaseModelQueries';

class IndustryTypeQueries extends BaseModelQueries<IndustryType> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new IndustryTypeQueries('/industrytypes');
