/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import moment from 'moment';

export const DATE_AND_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';

export function formatDate(date: Date, format: string) {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
}

export function prettifyJson(value: string) {
  if (!value) {
    return '';
  }
  return JSON.stringify(JSON.parse(value), null, 2);
}

export function asUTC(date: Date) {
  if (date && date instanceof Date && !isNaN(date.getTime())) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )
      .toISOString()
      .split('T')[0];
  } else {
    return date;
  }
}
