/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import EmploymentType from 'models/EmploymentType';
import employmentTypeFamilyQueries from 'queries/EmploymentTypeFamilyQueries';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BasePage, { BasicPageProps } from 'pages/BasePage';

const validationHelper = new ValidationHelper<EmploymentType>(
  Yup.object({
    name: Yup.string()
      .required('Must be present')
      .max(50, 'Must not exceed 50 chars'),
    burden: Yup.number()
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, (params) => `Minimum for this field is ${params.min}`)
      .max(100, (params) => `Maximum value for this field is ${params.max}`),
    fixedBurden: Yup.number()
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, (params) => `Minimum for this field is ${params.min}`)
      .max(
        99999999.99,
        (params) => `Maximum value for this field is ${params.max}`,
      ),
    family: Yup.mixed().required('Must be present'),
  }),
);

const EmploymentTypePage = (props: BasicPageProps) => {
  function createEmptyEmplType(): EmploymentType {
    return {
      id: -1,
      name: '',
      burden: 0,
      isBurdenFixed: false,
      fixedBurden: 0,
      isActive: true,
    };
  }

  function onChangeIsBurdenFixed(
    sectionParams: any,
  ): (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void {
    const updateInputField = sectionParams.updateInputField('isBurdenFixed');
    return (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      value: any,
    ): void => {
      if (
        sectionParams.editedData.isBurdenFixed &&
        sectionParams.editedData.fixedBurden != undefined
      ) {
        sectionParams.editedData.fixedBurden = undefined;
      }
      updateInputField(e, value);
    };
  }

  const ParameterizedBasePage = BasePage<EmploymentType>;

  return (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyEmplType}
      queriesImpl={employmentTypeQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Employment Type?'
      confirmRemovalDialogText='Are you sure you want to delete current Employment Type?'
      confirmSaveDialogText='Are you sure you want to save changes to current Employment Type?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Employement Type?'
      formatEditEntityPageUrl={(id) => `/employment-types/edit/${id}`}
    >
      {(sectionParams) => (
        <Card sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2}>
              <InputWithLabel
                label='Name'
                size='small'
                autoFocus={true}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.name}
                onChange={sectionParams.updateInputField('name')}
                errorText={sectionParams.validationErrors.name?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectWithLabel
                label='Family'
                tabIndex={sectionParams.tabIndexForInputs}
                selectedValue={sectionParams.editedData.family}
                optionsFetcher={employmentTypeFamilyQueries}
                onChange={sectionParams.updateField('family')}
                error={sectionParams.validationErrors.family?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SwitchWithLabel
                label='Is Active'
                tabIndex={sectionParams.tabIndexForInputs}
                checked={sectionParams.editedData.isActive}
                onChange={sectionParams.updateInputField('isActive')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputWithLabel
                label='Burden %'
                size='small'
                type='number'
                step={1}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.burden}
                defaultValue={0}
                onChange={sectionParams.updateInputField('burden')}
                disabled={!!sectionParams.editedData.isBurdenFixed}
                readOnly={!!sectionParams.editedData.isBurdenFixed}
                errorText={sectionParams.validationErrors.burden?.join(';')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SwitchWithLabel
                label='Is Burden Fixed'
                tabIndex={sectionParams.tabIndexForInputs}
                checked={sectionParams.editedData.isBurdenFixed || false}
                onChange={onChangeIsBurdenFixed(sectionParams)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputWithLabel
                label='Fixed Burden'
                size='small'
                type='number'
                step={1}
                tabIndex={
                  sectionParams.editedData.isBurdenFixed
                    ? sectionParams.tabIndexForInputs
                    : -1
                }
                value={
                  sectionParams.editedData.isBurdenFixed
                    ? sectionParams.editedData.fixedBurden
                    : null
                }
                defaultValue={0}
                onChange={sectionParams.updateInputField('fixedBurden')}
                disabled={!sectionParams.editedData.isBurdenFixed}
                readOnly={!sectionParams.editedData.isBurdenFixed}
                errorText={sectionParams.validationErrors.fixedBurden?.join(
                  ';',
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputWithLabel
                label='Notes'
                size='small'
                multiline={true}
                multilineRows={3}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.notes}
                onChange={sectionParams.updateInputField('notes')}
                errorText={sectionParams.validationErrors.notes?.join(';')}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </ParameterizedBasePage>
  );
};

export default EmploymentTypePage;
