/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Icon from '@mui/material/Icon';
import { useReadOnlyContext } from 'context/readonly/ReadOnlyContext';
import isEqual from 'lodash/isEqual';
import { formatDate } from 'util/Formatters';
import SoftDatePicker from 'softUI/components/SoftDatePicker';
import BaseLabeledComponent from './BaseLabeledComponent';

type DateWithLabelProps = {
  label: string;
  value: any;
  error?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  onChange: (date?: Date) => void;
  disabled?: boolean;
};

const DateWithLabel = (props: DateWithLabelProps) => {
  const readOnlyMode = useReadOnlyContext();
  let dirty = false;

  return (
    <BaseLabeledComponent label={props.label} error={props.error}>
      <SoftDatePicker
        input={{
          size: 'small',
          autoFocus: props.autoFocus,
          disabled: props.disabled,
          readOnly: readOnlyMode,
          placeholder: 'mm/dd/yyyy',
          icon: !readOnlyMode
            ? {
                component: <Icon>calendar_month</Icon>,
                direction: 'right',
              }
            : undefined,
          // A workaround for missed onChange in the case of mouse click outside the input.
          onBlur: (e: any) => {
            if (dirty) {
              props.onChange(e.target.value && new Date(e.target.value));
              dirty = false;
            }
          },
          onChange: () => {
            dirty = true;
          },
          sx: {
            minHeight: '1.932rem', // A workaround to align the height with other input controls.
          },
        }}
        inputTabIndex={props.tabIndex}
        value={props.value && formatDate(new Date(props.value), 'MM/DD/YYYY')}
        onChange={(newDate: any) => {
          // Flatpickr gives empty array in a case of no date set.
          if (Array.isArray(newDate) && !newDate.length) {
            props.onChange(undefined);
          } else {
            props.onChange(newDate && new Date(newDate));
          }
          dirty = false;
        }}
        options={{
          dateFormat: 'm/d/Y',
          allowInput: !(readOnlyMode || props.disabled),
          clickOpens: !(readOnlyMode || props.disabled),
          noCalendar: readOnlyMode,
        }}
      />
    </BaseLabeledComponent>
  );
};

export default memo(
  DateWithLabel,
  (before, after) =>
    before.label === after.label &&
    isEqual(before.value, after.value) &&
    before.error === after.error &&
    before.tabIndex === after.tabIndex &&
    before.autoFocus === after.autoFocus &&
    before.onChange === after.onChange &&
    before.disabled === after.disabled,
) as typeof DateWithLabel;
