/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect } from 'react';
import { ValidationHelper } from 'util/ValidationHelper';
import BaseModel from 'models/BaseModel';
import BaseModelQueries from 'queries/BaseModelQueries';
import { setCurrentPageTitle, useSoftUIController } from 'softUI/context';
import EditEntityPage from './EditEntityPage';
import { InputFieldsSectionProps } from './Editor';
import NewEntityPage from './NewEntityPage';

export type BasicPageProps = {
  title: string;
  mode: 'edit' | 'new';
  sourcePageUrl: string;
  formatEditEntityPageUrl?: (id: number) => string;
};

export type BasePageProps<TModel extends BaseModel> = BasicPageProps & {
  queriesImpl: BaseModelQueries<TModel>;
  emptyEditedEntityFactory: () => TModel;
  validationHelper?: ValidationHelper<TModel>;

  getAvailability?: (data: TModel) => any;
  getLabels?: (data: TModel) => any;

  showSaveButton?: boolean;
  allowEmptySave?: boolean;

  confirmRemovalDialogText?: string;
  confirmSaveDialogText?: string;
  confirmCloseDialogText?: string;
  confirmResetDialogText?: string;

  children: (params: InputFieldsSectionProps<TModel>) => React.ReactNode;
};

const BasePage = <TModel extends BaseModel>(props: BasePageProps<TModel>) => {
  const [, dispatch]: any = useSoftUIController();
  useEffect(() => {
    setCurrentPageTitle(dispatch, props.title);
  }, [props.title]);

  const implementationPerMode = {
    new: NewEntityPage,
    edit: EditEntityPage,
  };

  const PageImplementation = implementationPerMode[props.mode];

  return <PageImplementation {...props} />;
};

export default BasePage;
