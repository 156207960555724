/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { GridValueFormatter } from '@mui/x-data-grid/models/colDef/gridColDef';
import { formatDate } from './Formatters';

export function decimalFormatter(fractionDigits: number): GridValueFormatter {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
  return (value: any) => {
    if (value != null && value !== '') {
      const numValue = value as number;
      if (!isNaN(numValue)) {
        return formatter.format(numValue);
      }
    }
    return '';
  };
}

export function percentFormatter(fractionDigits: number): GridValueFormatter {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
  return (value: number) => {
    const percent = value / 100;
    return formatter.format(percent);
  };
}

export const booleanFormatter: GridValueFormatter = (value: boolean) => {
  return value ? 'Yes' : 'No';
};

export function dateFormatter(format: string): GridValueFormatter {
  return (value: Date) => {
    return formatDate(value, format);
  };
}

export function usDateFormatter() {
  return dateFormatter('MM/DD/YYYY');
}
