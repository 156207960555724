/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Location from 'models/Location';
import BaseModelQueries from './BaseModelQueries';

class LocationQueries extends BaseModelQueries<Location> {
  constructor(url: string) {
    super(url, [{ property: 'name', direction: 'asc' }]);
  }
}

export default new LocationQueries('/locations');
