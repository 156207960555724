/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { RouteType } from './appRoutes';
import appRoutes from './appRoutes';

const generateRoute = (
  routes: RouteType[],
  authUserAccessLevelId: number | null,
  parentAccessLevels?: number[],
): ReactNode => {
  return routes
    .filter((route) => {
      const necessaryAccessLevels =
        route.accessLevels || parentAccessLevels || [];
      return (
        !authUserAccessLevelId ||
        isEmpty(necessaryAccessLevels) ||
        necessaryAccessLevels.includes(authUserAccessLevelId)
      );
    })
    .map((route) => {
      const result: ReactNode[] = [];
      if (route.route) {
        result.push(
          <Route
            path={route.route}
            element={route.component}
            key={route.key}
          />,
        );
      }
      if (route.child) {
        const generatedChildRoutes = generateRoute(
          route.child,
          authUserAccessLevelId,
          route.accessLevels || parentAccessLevels,
        );
        if (!isEmpty(generatedChildRoutes)) {
          result.push(generatedChildRoutes);
        }
      }
      return result;
    });
};

export const generatePageRoutes = (
  authUserAccessLevelId: number | null,
): ReactNode => {
  return generateRoute(appRoutes, authUserAccessLevelId);
};
