/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import BaseModel from './BaseModel';

export function getConsultantFullNameWithPayroll(c: Consultant) {
  return c.firstName
    ? `${c.firstName} ${c.lastName}` + (c.payrollId ? ` (${c.payrollId})` : '')
    : undefined;
}

export default interface Consultant extends BaseModel {
  firstName: string;
  lastName: string;
  payrollId?: string;
  notes?: string;
}
