/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import AdvancedFilter from './AdvancedFilter';
import PredefinedFilter from './PredefinedFilter';
import IFilterContext from './context/IFilterContext';

type CombinedFilterProps = {
  predefinedFilterOptions: any;
  filterContext: () => IFilterContext;
};

const CombinedFilter = ({
  predefinedFilterOptions,
  filterContext: filterContextSupplier,
}: CombinedFilterProps) => {
  const [isAdvancedFilterHidden, setAdvancedFilterHidden] = useState(true);
  const filterContext = filterContextSupplier();

  let resetAdvancedFilter: any;

  return (
    <SoftBox sx={{ mt: -1 }}>
      <PredefinedFilter
        predefinedFilterOptions={predefinedFilterOptions}
        onChange={() => resetAdvancedFilter()}
        filterContext={filterContextSupplier}
      />
      <SoftBox sx={{ display: 'inline' }} component='div'>
        <Badge
          badgeContent='!'
          invisible={!filterContext.hasAdvancedFilter()}
          color='warning'
          sx={{
            '& .MuiBadge-badge': {
              right: 8,
              top: 8,
            },
          }}
        >
          <SoftButton
            variant='gradient'
            color='info'
            size='small'
            sx={{ m: 1 }}
            onClick={() => setAdvancedFilterHidden(!isAdvancedFilterHidden)}
          >
            Advanced filter
          </SoftButton>
        </Badge>
      </SoftBox>
      <SoftBox hidden={isAdvancedFilterHidden} sx={{ mb: 2 }}>
        <Divider />
        <AdvancedFilter
          passResetFunction={(f: any) => (resetAdvancedFilter = f)}
          filterContext={filterContextSupplier}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default CombinedFilter;
